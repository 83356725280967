import React, { FC } from "react";
import { Container } from "../../Account_Screen/AccountScreenStyles";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import MentorAppDetails from "../../../components/MentorForm/MentorAppForm";
import { Containerm } from "../../../components/UserForm/UserFormStyles";
import { Box } from "@mui/material";

const MentorForm = () => {
  return (
    <Container>
      <Header />
      <Box sx={{ mt: "150px" }}>
        <MentorAppDetails />
      </Box>
      <Footer />
    </Container>
  );
};
export default MentorForm;
