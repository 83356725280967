import React from "react";
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "../Screens/Admin_Screen/store";
import { JWTProvider } from "../Screens/Admin_Screen/contexts/JWTContext";
import { IntlProvider } from "react-intl";
import AdminDashboard from "../Screens/Admin_Screen/AdminDashboard";
import AdminMentors from "../Screens/Admin_Screen/AdminMentors";
import AdminStudents from "../Screens/Admin_Screen/AdminStudents";
import AdminLogin from "../Screens/Admin_Screen/AdminLogin";
import AdminThemeCustomization from "../Screens/Admin_Screen/themes";
import RTLLayout from "../Screens/Admin_Screen/ui-component/RTLLayout";
import MainLayout from "../Screens/Admin_Screen/layout/MainLayout";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route
        path="/admin/dashboard"
        element={
          <Provider store={store}>
            <JWTProvider>
              <IntlProvider locale="en" defaultLocale="en">
                <AdminThemeCustomization>
                  <RTLLayout>
                    <MainLayout>
                      <AdminDashboard />
                    </MainLayout>
                  </RTLLayout>
                </AdminThemeCustomization>
              </IntlProvider>
            </JWTProvider>
          </Provider>
        }
      />
      <Route
        path="/admin/mentors"
        element={
          <Provider store={store}>
            <JWTProvider>
              <IntlProvider locale="en" defaultLocale="en">
                <AdminThemeCustomization>
                  <RTLLayout>
                    <MainLayout>
                      <AdminMentors />
                    </MainLayout>
                  </RTLLayout>
                </AdminThemeCustomization>
              </IntlProvider>
            </JWTProvider>
          </Provider>
        }
      />
      <Route
        path="/admin/students"
        element={
          <Provider store={store}>
            <JWTProvider>
              <IntlProvider locale="en" defaultLocale="en">
                <AdminThemeCustomization>
                  <RTLLayout>
                    <MainLayout>
                      <AdminStudents />
                    </MainLayout>
                  </RTLLayout>
                </AdminThemeCustomization>
              </IntlProvider>
            </JWTProvider>
          </Provider>
        }
      />
      <Route path="/admin/login" element={<AdminLogin />} />
    </Routes>
  );
};

export default AdminRoutes;
