import React from "react";

import JohnDoe from "../../Assets/Images/JohnDoeHome.png";
import ExpandMore from "../../Assets/Images/JohnDoeHome.png";
import EmojiStar from "../../Assets/Images/medium_star.png";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
// import required modules
import "./StudentMentors.css";
import styled from "styled-components";
import ResponsiveSwiper from "../ResponsiveSlider/ResponsiveSwiper";

const StudentMentors = () => {
  const StudentMentorsData = [
    {
      Name: "John Doe",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: JohnDoe,
    },
    {
      Name: "Nelson M.",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: ExpandMore,
    },
    {
      Name: "Geaorge Heavend",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: JohnDoe,
    },
    {
      Name: "Joe Biden",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: ExpandMore,
    },
    {
      Name: "Joseph",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: JohnDoe,
    },
    {
      Name: "Micheal",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: ExpandMore,
    },
    {
      Name: "Geaorge Heavend",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: JohnDoe,
    },
    {
      Name: "Joe Biden",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: ExpandMore,
    },
    {
      Name: "Joseph",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: JohnDoe,
    },
    {
      Name: "Micheal",
      subHeading: "Startup Mentor",
      rating: "4.5 (1200)",
      imageSrc: ExpandMore,
    },
  ];

  return (
    <StudentMentorsSection>
      <ResponsiveSwiper swiperProps={{
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        }
      }} slides={StudentMentorsData.map((StudentMentors, index) => (
        <div className="Mentor" key={index}>
          <div className="Mentor_img">
            <img src={StudentMentors.imageSrc} alt="Mentors Image" />
          </div>
          <div className="Mentor_content">
            <div className="Mentor_left">
              <h4 className="text-left mb-0">{StudentMentors.Name}</h4>
              <p>{StudentMentors.subHeading}</p>
            </div>
            <div className="Mentor_right">
              <img
                src={EmojiStar}
                alt="rating"
                style={{ width: "24px", height: "24px" }}
              />
              <p style={{ fontSize: "15px", marginTop: "3px" }}>
                {StudentMentors.rating}
              </p>
            </div>
          </div>
        </div>
      ))} className="left-padding" />
    </StudentMentorsSection>
  );
};

export default StudentMentors;

const StudentMentorsSection = styled.section`
  .swiper-slide {
    // width: 283px !important;
    // height: 460px !important;
    // border-radius: 8px;
  }
`;
