import React from "react";
import { Routes, Route } from "react-router-dom";
import StudentDashboardMain from "../Screens/Student_Screen/StudentDahboard/StudentDahboard.Main";
import StudentForm from "../Screens/Student_Screen/StudentForm/StudentForm";
import StudentProfile from "../Screens/Student_Screen/StudentProfile/SudentProfile";
import PaymentPlan from "../Screens/Payement_Screen/PaymentPlan/PaymentPlan";
import PaymentPage from "../Screens/Payement_Screen/Payment/Payment";
import {CheckoutForm} from "../Screens/Payement_Screen/Payment/Checkout";
import {Return} from "../Screens/Payement_Screen/Payment/Return";
import {ReturnSubscriptionPage} from "../components/PaymentCompo/ReturnSubscriptionPage";
import { SubscriptionProvider } from "../Context/SubscriptionContext"; // Import SubscriptionProvider

const StudentRoutes  =() => {
  return (
    <SubscriptionProvider> {/* Wrap your routes with SubscriptionProvider */}
      <Routes>
        <Route path="/student/dashboard" element={<StudentDashboardMain />} />
        <Route path="/student/form" element={<StudentForm />} />
        <Route path="/student/profile" element={<StudentProfile />} />
        <Route path="/student/payment/plan" element={<PaymentPlan />} />
        <Route path="/student/payment/page" element={<PaymentPage />} />
        <Route path="/student/payment/checkout" element={<CheckoutForm />} />
        <Route path="/student/payment/return" element={<Return />} />
        <Route
          path="/student/payment/return-subscription"
          element={<ReturnSubscriptionPage />}
        />
      </Routes>
    </SubscriptionProvider>
  );
};

export default StudentRoutes;
