import React from "react";
import { Grid, Box } from "@mui/material";
import CustomButton from "../../components/Button/index";
import Container from "../../common/Input/useStyles";
import HomerBannerWrapper from "./style";
import { useNavigate } from "react-router-dom";
const Index = ({ subtitle, title1, title2, detail, HomeBanner, link }) => {
  const navigate = useNavigate();
  return (
    <Box bgcolor="#D1D2F4;">
      <HomerBannerWrapper>
        <Container>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} md={6} sx={{order: { xs: 2, md: 0 }}}>
              <Box className="left-box">
                <Box color="#7476D1" sx={{ mb: "-15px" }}>
                  <p>{subtitle}</p>
                </Box>
                <Box
                  component="h1"
                  fontWeight="700"
                  textAlign="left"
                  className="heading"
                  fontSize="78px"
                  color="#222"
                >
                  {title1}{" "}
                  <Box component="span" display="block" color="#7476D1">
                    {" "}
                    {title2}
                  </Box>
                </Box>
                <p>{detail}</p>
                <Box
                  display="flex"
                  gap={1}
                  marginTop={3}
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                >
                  <CustomButton
                    text="Start Today"
                    borderRadius="15px"
                    backgroundColor="#C9F6EF"
                    onClick={() => {
                      navigate(link);
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 0 }, paddingTop: { xs: '130px !important' } }}>
              <Box>
                <img src={HomeBanner} alt="no-banner" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </HomerBannerWrapper>
    </Box>
  );
};
export default Index;
