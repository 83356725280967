import React from "react";
import Container from '../../common/Input/useStyles'
import { Box, Grid } from "@mui/material";
import MainImage from "../../Assets/Images/student.svg";
import Vector from "../../Assets/Images/check.svg";


const AspiringStudents = () => {
  const AspiringStudentsData = [
    "Accommodation",
    "Career Guidance",
    "Financial Aid",
    "Language Proficiency",
    "Social Integration",
    "Travel Guidance",
    "Admission Guidance",
    "Course selection",
    "Financial Planning",
    "Networking Support",
    "Scholarships",
    "Visa Application",
    "Application Preparation",
    "Cultural Adjustment",
    "Immigration Advice",
    "Post-Arrival Support",
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#C9F6EF",
        py: { xs: 5, md:10 },
      }}
    >
      <Container>
        <Grid container spacing={3} sx={{display:"flex",alignItems:'center',justifyContent:'center'}} >
          <Grid item md={6}>
            <Box sx={{borderRadius:"10px",boxShadow:"1px 10px 5px -3px rgba(0,0,0,0.30)",display:{xs:"none",md:"inherit"}}}>
              <img src={MainImage} alt="" style={{borderRadius:"10px"}} />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <Box component={"h2"}>How you can help aspiring <br /> students?</Box>
              <Box component={"p"} mb={2}>
                With Mentorgrad, you're not just a mentor; you're a vital part of a student's journey to achieving their international education dreams.
              </Box>
              <Grid container columnSpacing={3}>

                {AspiringStudentsData.slice(0, 6).map((list) => {
                  return (
                    <Grid item xs={12} md={4}>
                      <Box fontSize="14px" mb={2} display={"flex"} gap={"7px"} key={list}>
                        <img
                          src={Vector}
                          alt="Vector"
                          width={"18px"}
                          height={"18px"}
                        />
                        <p>{list}</p>
                      </Box>
                    </Grid>
                  );
                })}
                {AspiringStudentsData.slice(6, 13).map((list) => {
                  return (
                    <Grid item xs={12} md={4}>
                      <Box mb={2} fontSize="14px" display={"flex"} gap={"7px"} key={list}>
                        <img
                          src={Vector}
                          alt="Vector"
                          width={"18px"}
                          height={"18px"}
                        />
                        <p>{list}</p>
                      </Box>
                    </Grid>
                  );
                })}
                {AspiringStudentsData.slice(12, 18).map((list) => {
                  return (
                    <Grid item xs={12} md={4}>
                      <Box mb={2} fontSize="14px" display={"flex"} gap={"7px"} key={list}>
                        <img
                          src={Vector}
                          alt="Vector"
                          width={"18px"}
                          height={"18px"}
                        />
                        <p>{list}</p>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AspiringStudents;
