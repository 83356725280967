import React, { useEffect, useState } from "react";
import { ContainerDashboard } from "../StudentDahboardStyles";
import {
  PositionHeader,
  PositionImage,
  RightBorderDashboard,
  RightContainerDash,
} from "../../StudentDashboard/StudentDashboardStyles";
import ButtonAppoinmwent from "../../../components/Button/index";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Pagination,
} from "@mui/material";
import SearchFilter from "./SearchFilter";
import {
  GetCountryList,
  findMentors,
  getSubscriptionByUserId,
  getAllServices,
  getAllTopics,
  getAllSkills,
  GetMentorSearch,
} from "../../../api";
import "./Index.css";
import { notifyError } from "../../Toastifycom";
import picture from "../../../Assets/Images/user.jpeg";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ReviewsIcon from "@mui/icons-material/Reviews";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoneyIcon from "@mui/icons-material/Money";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Spinner from "../../Spinner";
import { Container } from "../../../Screens/Account_Screen/AccountScreenStyles";
import Footer from "../../Footer";
import { ContainerDa } from "../../StudentProfileDetails/StudentProfileStyles";
import HeaderDashboard from "../../Header/HeaderDashboard";
import { useNavigate } from "react-router-dom";
import { useSubscriptionFroSubscription } from "../../../Context/SubscriptionContext";
import { notifySuccess } from "../../../components/Toastifycom";
import FrameSeacrhIcon from "../../../Assets/Images/Frame 5.png";
import FilterIcon from "../../../Assets/Images/mi_filter.png";
import InputAdornment from "@mui/material/InputAdornment";
import EmojiStar from "../../../Assets/Images/medium_star.png";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import GearImage from "../../../Assets/Images/mi_filter.png";
import Header from "../../Header/Header";

type Mentor = {
  _id: string;
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  countryOfResidence: string;
  universityName: string;
  hourlyRate: number;
  comment: string;
  mentoringarea: any;
  skills: any;
  services: any;
  topics: any;
  reviews: Array<{
    rating: string;
    comment: string;
  }>;
  user: Array<{
    first_name: string;
    last_name: string;
    attachments: Array<{
      attachmentPath: string;
      attachmentURL: string;
      name: string;
    }>;
  }>;
};
type Skill = {
  _id: string;
  Name: string;
};
type Topic = {
  _id: string;
  Name: string;
};
type Service = {
  _id: string;
  Name: string;
};
type Location = {
  _id: string;
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  countryOfResidence: string;
  universityName: string;
  hourlyRate: number;
  comment: string;
  mentoringarea: any;
  skills: any;
  services: any;
  topics: any;
  user: Array<{
    attachments: Array<{
      attachmentPath: string;
      attachmentURL: string;
      name: string;
    }>;
  }>;
};

type MentorList = Mentor[];
type Skills = Skill[];
type Topics = Topic[];
type Services = Service[];
type Locations = Location[];

const MentorSearch = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const query_params = new URLSearchParams(window.location.search)
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isMobile2 = useMediaQuery("(max-width: 1200px)");
  const [showFilter, setshowFilter] = useState(false);
  const [filters, setFilters] = useState({
    country: "",
    gender: "",
    course: "",
    location: "",
  });
  const [countries, setCountries] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [mentorList, setMentorList] = useState<MentorList>([]);
  const [mentors, setMentors] = useState<MentorList>([]);
  // const { useSubscriptionData, setSubscription } =
  //   useSubscriptionFroSubscription();
  const [useSubscriptionCheck, setUseSubscriptionCheck] = useState(false);
  const [skills, setSkills] = useState<Skills>([]);
  const [services, setServices] = useState<Topics>([]);
  const [topics, setTopics] = useState<Services>([]);
  const [locations, setLocations] = useState<Locations>([]);
  const [skillValue, setSkillValue] = useState("");
  const [serviceValue, setServiceValue] = useState("");
  const [topicValue, setTopicValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [search, setSearch] = useState(query_params.get("location") || "");
  const [displayFilter, setDisplayFilter] = useState(false);
  const [isSeeMoreOn, setIsSeeMoreOn] = useState(false);
  const [page, setPage] = useState(1);
  const offset = 20; // Items on each page

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);

    const startIndex = (value - 1) * offset;
    const endIndex = startIndex + offset;
    const data = mentors.slice(startIndex, endIndex);
    setMentorList(data);
  };

  const isBelowMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const isBelowlg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  const isBelowSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  // Create an array to hold the icons
  const starIcons = new Array(4).fill(<StarIcon />);
  // Create a single StarBorderIcon
  const starBorderIcon = <StarBorderIcon />;

  useEffect(() => {
    getMentors();
  }, [skillValue, topicValue, serviceValue, locationValue]);

  useEffect(() => {
    setSkillValue("");
    setTopicValue("");
    setServiceValue("");
    setSearch("");
    getAllCountries();
    getMentors();
    // getcurrentSubscription();

    const getSkills = async () => {
      try {
        const response = await getAllSkills();
        setSkills(response.data);
      } catch (error) {
        console.error("Error fetching skills data:", error);
      }
    };

    const getServices = async () => {
      try {
        const response = await getAllServices();
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching skills data:", error);
      }
    };

    const getTopics = async () => {
      try {
        const response = await getAllTopics();
        setTopics(response.data);
      } catch (error) {
        console.error("Error fetching skills data:", error);
      }
    };

    const getLocations = async () => {
      try {
        let payload = {
          countryOfResidence: filters.country,
          gender: filters.gender,
          mentoringarea: selectedOptions,
        };
        const response = await findMentors(payload);
        setLocations(response);
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    getSkills();
    getServices();
    getTopics();
    getLocations();
  }, []);

  // const getcurrentSubscription = async () => {
  //   try {
  //     await getSubscriptionByUserId().then((res) => {
  //       const subscriptionData = res.data;
  //       setSubscription(subscriptionData);
  //       setUseSubscriptionCheck(subscriptionData.paymentApproved);
  //     });
  //   } catch (err) {
  //     console.error("Error fetching subscription data:", err);
  //   }
  // };

  const handleSearch = () => {
    getMentors();
  };
  const getAllCountries = async () => {
    try {
      GetCountryList().then((e) => {
        setCountries(e?.map((list: any) => list.name));
      });
    } catch (error) {
      notifyError(error);
    }
  };

  //Gets mentors based on country, gender and mentoring area option
  const getMentors = () => {
    setshowFilter(false);
    setLoading(true);
    console.log("locationValue::::", locationValue);
    let payload = {
      countryOfResidence: locationValue,
      gender: filters.gender,
      mentoringarea: selectedOptions,
      skills: skillValue,
      topics: topicValue,
      services: serviceValue,
      search: search,
      page: "1",
      limit: "2",
    };
    try {
      findMentors(payload)
        .then((e) => {
          setMentors(e); // Set all Mentors

          const startIndex = (page - 1) * offset;
          const endIndex = startIndex + offset;
          const data = e.slice(startIndex, endIndex);
          setMentorList(data);

          setLoading(false);
        })
        .catch((err) => {
          notifyError(err);
        });
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  };

  const handleChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setFilters((filters) => ({
      ...filters,
      [target.name]: target.checked,
    }));
  };

  const handleCheckboxChange = (value: string) => {
    // Check if the value is already in the selectedOptions array
    if (selectedOptions.includes(value)) {
      // If it is, remove it
      setSelectedOptions(selectedOptions.filter((item) => item !== value));
    } else {
      // If it's not, add it
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const [age, setAge] = React.useState("");

  const handleAgeChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const handleBookAppointment = (mentor: Mentor) => {
    navigate(`/bookAppointment?id=${mentor?.userId}`);
  };

  return (
    <>
      {/* <HeaderDashboard /> */}
      <Header isWhiteBackground={true} />
      <Box
        sx={{
          backgroundColor: "#D1D2F4",
          pt: 20,
          pb: 5,
          px: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "1px 100px",
            "@media (max-width: 900px)": {
              padding: "15px",
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{ textAlign: "left", marginBottom: 3, fontWeight: "bold" }}
          >
            All mentors
          </Typography>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={10} md={6} sx={{ textAlign: "left" }}>
              <TextField
                onChange={(e) => setSearch(e.target.value)}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  width: "95%",
                  "& .MuiInputBase-root.MuiOutlinedInput-root": {
                    paddingRight: 0, // Override the default padding-right
                    borderRadius: "15px",
                  },
                }}
                placeholder={
                  !isMobile
                    ? "Search by location or service"
                    : "Search by skill or job title"
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* Replace the placeholder text with the FrameSeacrhIcon */}
                      <img
                        src={FrameSeacrhIcon}
                        alt="Search Icon"
                        style={{ width: "90%", cursor: "pointer" }}
                        onClick={handleSearch}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {isMobile2 && (
              <Grid item xs={1} md={6} sx={{ textAlign: "left" }}>
                <Box
                  sx={{
                    width: "50px",
                    height: "100%",
                    borderRadius: "15px",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setDisplayFilter(!displayFilter)}
                >
                  <img src={GearImage} />
                </Box>
              </Grid>
            )}
          </Grid>
          {!isMobile2 && (
            <Grid container columnGap={0.5}>
              <Grid item xs={1.5} sx={{ textAlign: "left" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Skills</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={skillValue}
                    label="Skills"
                    onChange={(e) => setSkillValue(e.target.value)}
                    sx={{ borderRadius: "15px", backgroundColor: "white" }}
                    MenuProps={{
                      style: { zIndex: 999999 },
                    }}
                  >
                    {Array.isArray(skills) && skills.length > 0 ? (
                      skills.map((skill) => (
                        <MenuItem key={skill._id} value={skill.Name}>
                          {skill.Name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No skills available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  textAlign: "left",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Topics</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={topicValue}
                    label="Topics"
                    onChange={(e) => setTopicValue(e.target.value)}
                    sx={{
                      borderRadius: "15px",
                      backgroundColor: "white",
                    }}
                    MenuProps={{
                      style: { zIndex: 999999 },
                    }}
                  >
                    {Array.isArray(topics) && topics.length > 0 ? (
                      topics.map((topic) => (
                        <MenuItem key={topic._id} value={topic.Name}>
                          {topic.Name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No topic available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1.5} sx={{ textAlign: "left" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Services
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={serviceValue}
                    label="Services"
                    onChange={(e) => setServiceValue(e.target.value)}
                    sx={{ borderRadius: "15px", backgroundColor: "white" }}
                    MenuProps={{
                      style: { zIndex: 999999 },
                    }}
                  >
                    {Array.isArray(services) && services.length > 0 ? (
                      services.map((servis) => (
                        <MenuItem key={servis._id} value={servis.Name}>
                          {servis.Name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No services available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "left" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Locations
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={locationValue}
                    label="Locations"
                    onChange={(e) => setLocationValue(e.target.value)}
                    sx={{ borderRadius: "15px", backgroundColor: "white" }}
                    MenuProps={{
                      style: { zIndex: 999999 },
                    }}
                  >
                    {Array.isArray(locations) && locations.length > 0 ? (
                      locations.map((mentorr) => (
                        <MenuItem
                          key={mentorr._id}
                          value={mentorr.countryOfResidence}
                        >
                          {mentorr.countryOfResidence}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No Location available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={2} sx={{ textAlign: "left", marginLeft: "-83px" }}>
                <FormControl sx={{ width: "70%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Universities
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Universities"
                    onChange={handleAgeChange}
                    sx={{ borderRadius: "15px", backgroundColor: "white" }}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={2} sx={{ marginLeft: "auto" }}>
                <FormControl fullWidth>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Button
                      sx={{
                        background: "white",
                        borderRadius: "15px",
                        py: 1.8,
                        color: "#B8B8B8",
                        padding: "14px 24px 14px 23px",
                        fontSize: "80%",
                        gap: "10px",
                      }}
                    >
                      More Filters
                      <img
                        src={FilterIcon}
                        alt="Filter Icon"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </Button>
                  </Stack>
                </FormControl>
              </Grid> */}
            </Grid>
          )}

          {isMobile2 && displayFilter && (
            <Grid container columnGap={0.5} rowGap={1}>
              <Grid item xs={12} sx={{ textAlign: "left" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Skills</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={skillValue}
                    label="Skills"
                    onChange={(e) => setSkillValue(e.target.value)}
                    sx={{ borderRadius: "15px", backgroundColor: "white" }}
                    MenuProps={{
                      style: { zIndex: 999999 },
                    }}
                  >
                    {Array.isArray(skills) && skills.length > 0 ? (
                      skills.map((skill) => (
                        <MenuItem key={skill._id} value={skill.Name}>
                          {skill.Name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No skills available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "left",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Topics</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={topicValue}
                    label="Topics"
                    onChange={(e) => setTopicValue(e.target.value)}
                    sx={{
                      borderRadius: "15px",
                      backgroundColor: "white",
                    }}
                    MenuProps={{
                      style: { zIndex: 999999 },
                    }}
                  >
                    {Array.isArray(topics) && topics.length > 0 ? (
                      topics.map((topic) => (
                        <MenuItem key={topic._id} value={topic.Name}>
                          {topic.Name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No topic available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "left" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Services
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={serviceValue}
                    label="Services"
                    onChange={(e) => setServiceValue(e.target.value)}
                    sx={{ borderRadius: "15px", backgroundColor: "white" }}
                    MenuProps={{
                      style: { zIndex: 999999 },
                    }}
                  >
                    {Array.isArray(services) && services.length > 0 ? (
                      services.map((servis) => (
                        <MenuItem key={servis._id} value={servis.Name}>
                          {servis.Name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No services available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "left" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Locations
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={locationValue}
                    label="Locations"
                    onChange={(e) => setLocationValue(e.target.value)}
                    sx={{ borderRadius: "15px", backgroundColor: "white" }}
                    MenuProps={{
                      style: { zIndex: 999999 },
                    }}
                  >
                    {Array.isArray(locations) && locations.length > 0 ? (
                      locations.map((mentorr) => (
                        <MenuItem
                          key={mentorr._id}
                          value={mentorr.countryOfResidence}
                        >
                          {mentorr.countryOfResidence}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No Location available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={2} sx={{ textAlign: "left", marginLeft: "-83px" }}>
                <FormControl sx={{ width: "70%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Universities
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Universities"
                    onChange={handleAgeChange}
                    sx={{ borderRadius: "15px", backgroundColor: "white" }}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={2} sx={{ marginLeft: "auto" }}>
                <FormControl fullWidth>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Button
                      sx={{
                        background: "white",
                        borderRadius: "15px",
                        py: 1.8,
                        color: "#B8B8B8",
                        padding: "14px 24px 14px 23px",
                        fontSize: "80%",
                        gap: "10px",
                      }}
                    >
                      More Filters
                      <img
                        src={FilterIcon}
                        alt="Filter Icon"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </Button>
                  </Stack>
                </FormControl>
              </Grid> */}
            </Grid>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "1px 120px",
          "@media (max-width: 900px)": {
            padding: "15px",
          },
        }}
      >
        {mentorList.map((mentor) => { 
          let user = mentor.user[0]
          return (
            <Box
              sx={{
                width: "100%",
                p: 3,
                my: 2,
                border: "0.5px solid #D1D2F4",
                borderRadius: "15px",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  textAlign: "left",
                  paddingLeft: "0px",
                  display: "flex",
                  flexDirection: isBelowlg ? "column" : "row",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: isBelowlg ? "flex" : "block",
                    alignItems: isBelowlg ? "center" : "",
                    justifyContent: isBelowlg ? "center" : "",
                    marginRight: !isBelowlg ? "10px" : "0px",
                  }}
                >
                  <img
                    src={
                      mentor?.user[0]?.attachments?.length > 0
                        ? mentor?.user[0]?.attachments[0]?.attachmentPath
                        : "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg"
                    }
                    style={{
                      width: isBelowlg ? "100%" : "300px",
                      height: isBelowlg ? "100%" : "300px",
                      minWidth: "300px",
                      minHeight: "150px",
                      borderRadius: "15px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    columnGap: "15px",
                  }}
                >
                  <Box style={{ display: isBelowMd ? "block" : "flex", marginTop: "10px", marginBottom: isMobile ? "10px" : "0", }}>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          fontWeight: 700,
                          whiteSpace: "nowrap",
                          marginBottom: "0px",
                          textTransform: "capitalize",
                        }}
                      >{`${user?.first_name} ${user?.last_name}`}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: isMobile ? "0" : "15px",
                        marginTop: "-10px",
                      }}
                    >
                      <img
                        src={EmojiStar}
                        alt="imge"
                        style={{
                          width: "14px",
                          height: "14px",
                        }}
                      />
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          marginBottom: "0px",
                          marginLeft: "5px",
                        }}
                      >
                        {mentor?.reviews[0]?.rating ? (
                          <>
                            {mentor.reviews[0].rating}.0 (
                            <span style={{ color: "#FFA340" }}>
                              {mentor.reviews.length} review
                            </span>
                            )
                          </>
                        ) : (
                          "No Rating"
                        )}
                      </p>
                    </div>
                  </Box>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "1rem",
                      fontWeight: 500,
                      marginBottom: "10px",
                    }}
                  >
                    {mentor?.universityName && (
                      <>
                        {mentor.universityName}
                        {mentor?.countryOfResidence && `, ${mentor.countryOfResidence}`}
                      </>
                    )}
                  </Typography>
                  <Grid container sx={{ textAlign: "left", mb: 2 }} gap={1}>
                    {mentor?.mentoringarea.map(
                      (field: { title?: string } | undefined) => (
                        <Grid
                          item
                          key={field?.title}
                          style={{
                            backgroundColor: "#F2F7F2",
                            fontWeight: 400,
                            fontSize: "16px",
                            borderRadius: "15px",
                            padding: "10px",
                          }}
                        >
                          {field?.title}
                        </Grid>
                      )
                    )}
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      gap: "30px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontWeight: 400,
                        fontSize: "16px",
                        minHeight: "4em",
                        wordWrap: "anywhere",
                        whiteSpace: "normal",
                      }}
                    >
                      {mentor?.comment?.split(" ").length < 10 ? (
                        mentor?.comment
                      ) : !isSeeMoreOn ? (
                        <>
                          {mentor?.comment?.split(" ").slice(0, 10).join(" ")}
                          <span onClick={() => setIsSeeMoreOn(true)}>
                            ... see more
                          </span>
                        </>
                      ) : (
                        <>
                          {mentor?.comment}
                          <span onClick={() => setIsSeeMoreOn(false)}>
                            ... see less
                          </span>
                        </>
                      )}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "left",
                        bottom: "0px",
                      }}
                    >
                      <ButtonAppoinmwent
                        sx={{
                          backgroundColor: "#C9F6EF",
                          borderRadius: "15px",
                          color: "black",
                          fontFamily: "Gilroy",
                          fontSize: "14px",
                          py: "20px",
                          px: "40px",
                        }}
                        onClick={() => handleBookAppointment(mentor)}
                        text="Visit Profile"
                      />
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>
        )})}
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "end" },
            mb: "80px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Pagination
            count={offset}
            page={page}
            onChange={handlePageChange}
            sx={{
              "& .Mui-selected": {
                background: "#C9F6EF",
              },
              "& .MuiPaginationItem-root": {
                fontFamily: "Gilroy",
                fontSize: "16px",
              },
            }}
          />
        </Box>
      </Box>

      {/* <ContainerDa>
          <ContainerDashboard>
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography
                sx={{ mt: 1, mb: 2.5 }}
                textAlign={'left'}
                fontWeight={700}
                fontSize={isMobile ? 'small' : 'large'}
                noWrap
              >
                {mentorList.length} matches found for: Mentors{' '}
                {filters.country === '' || filters.country === null
                  ? 'OverAll'
                  : filters.country === mentorList[0]?.countryOfResidence
                  ? ` in 
            ${mentorList[0]?.countryOfResidence}`
                  : 'OverAll'}
              </Typography>
              {isMobile2 && (
                <IconButton
                  onClick={() => setshowFilter(true)}
                  sx={{ p: 0, m: 0, mb: 1 }}
                >
                  <FilterAltIcon fontSize="small" sx={{ color: '#5F61BE' }} />
                </IconButton>
              )}
            </Stack>
            <RightContainerDash>
              <Grid item sm={12} lg={12}>
                <Grid container gap={2}>
                  {!isMobile2 && (
                    <Grid item sm={3} lg={3.5}>
                      <SearchFilter
                        setFilters={setFilters}
                        countries={countries}
                        filters={filters}
                        selectedOptions={selectedOptions}
                        handleChange={handleChange}
                        handleCheckboxChange={handleCheckboxChange}
                        getMentors={getMentors}
                      />
                    </Grid>
                  )}
                  <Grid item sm={12} lg={7.5}>
                    {loading ? (
                      <Spinner />
                    ) : (
                      mentorList?.map((mentor, index) => (
                        <Box
                          sx={{ margin: isMobile ? '0px 15px' : '' }}
                          key={index}
                        >
                          <RightBorderDashboard>
                            <Stack
                              justifyContent={'space-between'}
                              flexDirection={isMobile ? 'column' : 'row'}
                              gap={isMobile ? 3 : 0}
                              p={isMobile ? 2.5 : 0}
                            >
                              <Stack
                                flexDirection={'row'}
                                gap={2}
                                width={isMobile ? '100%' : '70%'}
                              >
                                <img
                                  style={
                                    isMobile
                                      ? { width: '35%', borderRadius: '10px' }
                                      : { width: '20%', borderRadius: '10px' }
                                  }
                                  src={
                                    mentor?.user[0]?.attachments?.length > 0
                                      ? mentor?.user[0]?.attachments[0]
                                          ?.attachmentPath
                                      : 'https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg'
                                  }
                                />
                                <Stack flexDirection={'column'}>
                                  <Typography
                                    textAlign={'left'}
                                    noWrap
                                    sx={{ color: '#5F61BE' }}
                                    fontSize={'medium'}
                                    fontWeight={600}
                                  >
                                    {mentor?.userName}
                                  </Typography>
                                  <Typography
                                    textAlign={'left'}
                                    noWrap
                                    fontSize={'small'}
                                    sx={{ color: '#8E8E8E' }}
                                  >
                                    Data Scientist
                                  </Typography>
                                  <Stack
                                    flexDirection={'row'}
                                    sx={{ mt: 2 }}
                                    alignItems={'center'}
                                  >
                                    {new Array(4).fill(
                                      <StarIcon
                                        sx={{ color: '#FFD707' }}
                                        fontSize="small"
                                      />
                                    )}
                                    {new Array(1).fill(
                                      <StarBorderIcon fontSize="small" />
                                    )}
                                    <small>(17)</small>
                                  </Stack>
                                  <Typography
                                    textAlign={'left'}
                                    sx={{ color: '#757575' }}
                                    fontSize={'small'}
                                  >
                                    {mentor.countryOfResidence}
                                  </Typography>
                                </Stack>
                              </Stack>

                              <Stack flexDirection={'column'} gap={1}>
                                <Stack
                                  flexDirection={'row'}
                                  alignItems={'flex-end'}
                                  gap={1}
                                >
                                  <ReviewsIcon fontSize="small" />
                                  <Typography noWrap fontSize="small">
                                    17 Reviews
                                  </Typography>
                                </Stack>

                                <Stack
                                  flexDirection={'row'}
                                  alignItems={'flex-end'}
                                  gap={1}
                                >
                                  <LocationOnIcon fontSize="small" />
                                  <Typography noWrap fontSize="small">
                                    {mentor.countryOfResidence}
                                  </Typography>
                                </Stack>

                                <Stack
                                  flexDirection={'row'}
                                  alignItems={'flex-end'}
                                  gap={1}
                                >
                                  <MoneyIcon fontSize="small" />
                                  <Typography noWrap fontSize="small">
                                    {mentor?.hourlyRate}
                                  </Typography>
                                </Stack>

                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    navigate(
                                      `/bookAppointment?id=${mentor?.userId}`
                                    )
                                  }
                                  sx={
                                    isMobile
                                      ? {
                                          background: '#5F61BE',
                                          width: 'fit-content',
                                          mt: '10px',
                                          ml: '15vw',
                                          px: '10px',
                                        }
                                      : {
                                          background: '#5F61BE',
                                          width: 'fit-content',
                                          mt: '10px',
                                        }
                                  }
                                >
                                  BOOK APPOINTMENT
                                </Button>
                              </Stack>
                            </Stack>
                          </RightBorderDashboard>
                          <br />
                        </Box>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </RightContainerDash>
            {showFilter && (
              <Dialog
                open={showFilter}
                maxWidth={'xl'}
                sx={{ overflowX: 'scroll' }}
              >
                <DialogContent>
                  <SearchFilter
                    setFilters={setFilters}
                    countries={countries}
                    filters={filters}
                    selectedOptions={selectedOptions}
                    handleChange={handleChange}
                    handleCheckboxChange={handleCheckboxChange}
                    getMentors={getMentors}
                  />
                </DialogContent>
              </Dialog>
            )}
          </ContainerDashboard>
        </ContainerDa>
        <Footer /> */}
      <Footer />
    </>
  );
};

export default MentorSearch;
