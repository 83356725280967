import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BackCard,
  BackIconinfo,
  CardIcon,
  CardPosition,
  CardSubTitle,
  CardTitle,
  InsideCardWidth,
  PositionCards,
  PositionHeader,
  PositionTextCol,
} from "./StudentDashboardStyles";
import {getBookings,getStudentBookings}from "../../api"
import { notifyError, notifySuccess } from "../../components/Toastifycom";
import Users from "../../Assets/Images/usersgroup.svg";
import Appointment from "../../Assets/Images/appointment.svg";
import Wallet from "../../Assets/Images/wallet-fill.svg";
import { jwtDecode } from "../../helper-functions";

const user = jwtDecode(localStorage.getItem("@storage_Key"));
const data = [
  {
    id: 1,
    num: "0",
    title: "Total Sessions",
    background: "#F8BDEB4D",
    backicon: "#F8BDEB4D",
    icon: Users,
    route: `/${user?.userType === "Mentor" ? "mentor" : "student"}/dashboard?tab=1`,
  },
  {
    id: 1,
    num: "0",
    title: "Completed",
    background: "#BEADFA4D",
    backicon: "#BEADFA4D",
    icon: Wallet,
    route: `/${user?.userType === "Mentor" ? "mentor" : "student"}/dashboard?tab=1`,
  },
  {
    id: 1,
    num: "0",
    title: "Upcoming",
    background: "#3876BF4D",
    backicon: "#3876BF4D",
    icon: Appointment,
    route: `/${user?.userType === "Mentor" ? "mentor" : "student"}/dashboard?tab=1`,
  },
];
const Cardsinfo = () => {
  const navigate = useNavigate();
  const [updateSidebar, setUpdateSidebar] = useState(false);
  const [countSessions, setCountSessions] = useState();

  useEffect(() => {
    getAllBookings("ALL");
    getAllBookingsBooked("Booked");
  }, []);

  const getAllBookings = async (type: string) => {
    await getStudentBookings(type)
      .then((res) => {
        const bookingsCount = res.length;

        const updatedData = [...data];
        updatedData[0].num = bookingsCount.toString();

        setCountSessions(bookingsCount.toString());
      })
      .catch((err) => {
        notifyError(err?.message);
      });
  };

  const getAllBookingsBooked = async (type: string) => {
    await getStudentBookings(type)
      .then((res) => {
        const bookingsCount = res.length;

        const updatedData = [...data];
        updatedData[2].num = bookingsCount.toString();

        setCountSessions(bookingsCount.toString());
      })
      .catch((err) => {
        notifyError(err?.message);
      });
  };


  const handleCardClick = (route: string) => {
    navigate(route);
    setUpdateSidebar((prev) => !prev); // Toggle the state to trigger re-render
  };
  return (
    <PositionCards>
      {data.map((data) => {
        return (
          <BackCard
            style={{ backgroundColor: data.background, borderRadius: "15px", cursor: "pointer" }}
            onClick={() => handleCardClick(data.route)}

          >
            <InsideCardWidth>
              <CardPosition>
                <BackIconinfo style={{ backgroundColor: data.backicon }}>
                  <CardIcon src={data.icon} />
                </BackIconinfo>
                <PositionTextCol style={{ marginLeft: "4%" }}>
                  <CardTitle>{data.title}</CardTitle>
                  <CardSubTitle>{data.num}</CardSubTitle>
                </PositionTextCol>
              </CardPosition>
            </InsideCardWidth>
          </BackCard>
        );
      })}
    </PositionCards>
  );
};
export default Cardsinfo;
