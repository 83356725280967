import { Grid, Typography, Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
const MentorDetail = ({ name,comments }) => {
  const navigate = useNavigate();

  const handlesendMessage = () => {
    navigate(`/student/dashboard?tab=3`);
  };
  return (
    <Box
      sx={{
        borderRadius: "10px",
        border: "1px solid #D1D2F4",
        p: "20px",
        mt: "20px",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontFamily: "Gilroy",
          fontWeight: "bold",
        }}
      >
        About {name}
      </Typography>
      <Typography variant="body1" sx={{ fontFamily: "DM Sans" }}>
        {comments}
        {/* I'm helping developers and tech entrepreneurs to get deeper into
        building software ⛏ To give you a couple of examples: - accompanied
        self-taught developers to get a good job in the industry by hammering
        out software projects, branded content, and preparing for interviews the
        right way - helped devs to come up with project ideas and bring them to
        life with the right accompanying resources - helped entrepreneurs who
        wanted to learn the details of software to get the code and setup right
        for their product ideas There are a couple of blog posts about that in
        my blog if you'd like to dig deeper into their journeys. I'm helping
        supporting you with all sorts of things like... - setting up projects-
        make better decisions in code- steer projects in the right direction-
        polish your teamwork flows and communication- setting up some sort of a
        personal brand- level up your career- make better products It might be
        not quite obvious from the text above, but I'm an emoji heavy-user so
        please bear with me 🙏 (more interpersonal stuff here:
        richstone.io/about) If you need to bounce any thoughts on any of the
        topics, just hit me up :)) How this usually works step by step: 1. You
        explain your situation and motivation in some detail (in writing)2. Get
        to know call and potentially commitment if there’s a match3. High-level
        plan and collaboration proposal4. Decision5. Payment6. Detailed plan
        proposal7. Regular catch-ups and intermittent support to boost your
        progress Here are also some up-to-date FAQs about my availabilities and
        current coaching structure. These may change depending on my current
        project, so make sure to read through them thoroughly: All clear and
        set? Then let's have a chat :)) --- Still not sure why I'm the right one
        for you to help? Check out this summary of my software engineering
        adventures so far: After completing my computer science studies some
        years, I was drawn into the wondrous world of web APIs and web
        applications which I'm currently continuing to explore, helping to build
        the best webinar software out there (WebinarGeek). As a writer ✍️ /
        visualizer 🎨 / teacher 🧑‍🏫 at richstone.io I'm also spreading the
        message of the most human-friendly web programming technologies: Ruby
        and Rails. And how you get rolling with them in TDD, pair programming,
        CI/CD and API mode 🚀 In my super-duper practical computer science
        course in Berlin, I was mainly focused on web apps, computer graphics,
        machine learning, and data engineering with the help of some almighty
        JS, Python and Java technologies. I've started working in software teams
        during my first semester of studies and since then I experienced a big
        variety of projects, team dynamics, technologies, and tech interviews.
        Hope that gives you some confidence to set up a chat with me sometime
        ;)) */}
      </Typography>

      <Box
        sx={{
          borderRadius: "10px",
          background: "#F2F5F9",
          p: "20px",
          mt: "40px",
          mb: "20px",
        }}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={1}>
              <SmsOutlinedIcon />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gilroy",
                  fontWeight: "bold",
                }}
              >
                Open to inquiries
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "DM Sans",
                }}
              >
                You can message Rich to ask questions before booking their
                services
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={handlesendMessage}
                sx={{
                  color: "#000",
                  background: "#C9F6EF",
                  borderRadius: "15px",
                  py: "12px",
                  textTransform: "capitalize",
                  px: "20px",
                  fontFamily: "Gilroy",
                  "&:hover": {
                    background: "#C9F6EF",
                  },
                }}
              >
                Get in touch
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default MentorDetail;
