
import styled from 'styled-components';
const CreateAccount = styled.div`
    .create-acccount{
        text-align: center;
        h2{
            font-size:32px;
            color:#fff;
        }
        span{
            font-size:20px;
            color:#fff; 
        }
        label{
            color:#fff;    
        }
      @media (max-width:900px){
        text-align: left;
      }  
    }
    .google-button{
        border:1px solid #fff;
        border-radius: 15px;
        padding:8px;
        font-size:20px;
    }
    .link{
        cursor: pointer;
        text-decoration: underline;
    }
`;
export default CreateAccount;