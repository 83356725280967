
import styled from 'styled-components';
const HomerBannerWrapper = styled.section`
    .left-box{
        font-family: "Gilroy";
        padding:235px 10px;
        h1{
            line-height:90px;
            margin-bottom: 17px;
        }
        p{
            text-align: left;
            font-size:20px;
            font-family: 'DM Sans', sans-serif;
            @media (max-width:900px){
                font-size:14px;
            }
        }
        @media (max-width:900px){
            padding: 15px 10px;
        }

    }
`;
export default HomerBannerWrapper;