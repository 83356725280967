import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import HomeBanner from "../../../components/HomeBanner/index";
import OurPartner from "../../../components/OurParthner";
import MentorCanHelp from "../../../components/MentorCanHelp/MentorCanHelp";
import FAQQuestion from "../../../components/FaqSection";
import Footer from "../../../components/Footer";
import CreateAccount from "../../../components/createAccount";
import InternationalStudent from "../../../components/MeetOurInternationlStudent/index";
import UniPartnership from "../../../components/UniPartnership/UniPartnership";
import BecomeMentor from "../../../components/BecomeAMentor/BecomeAMentor";
import homeBannerImages from "../../../Assets/Images/mentee.png";
import image from "../../../Assets/Images/experianced.png";
import image1 from "../../../Assets/Images/guideline.png";
import image2 from "../../../Assets/Images/tuttion-fee.png";
import SchoolIcon from "@mui/icons-material/School";
import PaymentsIcon from "@mui/icons-material/Payments";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import HomeIcon from "@mui/icons-material/Home";
import ChecklistIcon from "@mui/icons-material/Checklist";
import styled from 'styled-components';

const StyledTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
`;

const Index = () => {
  const BecomeMentorData = [
    {
      title: (
        <StyledTitle as="h3">Personalized guidance from experienced international student mentors</StyledTitle>
      ),
      content:
        "Use the search option to search for mentors. You may filter the results based on your country or other preferences.",
      imageSrc: image,
    },
    {
      title: (
        <StyledTitle as="h3">Receive offers from universities for programs that match with your preferences</StyledTitle>
      ),
      content:
        "Use the search option to search for mentors. You may filter the results based on your country or other preferences.",
      imageSrc: image1,
    },
    {
      title: (
        <StyledTitle as="h3">Lower your tuition fees through scholarships and discounts</StyledTitle>
      ),
      content:
        "Use the search option to search for mentors. You may filter the results based on your country or other preferences.",
      imageSrc: image2,
    },
  ];

  const MentorCanHelpData = [
    {
      heading: "University Enrolment",
      content:
        "Mentors assist you in choosing the right university and programs",
      iconSrc: <SchoolIcon />,
    },
    {
      heading: "Scholarships and Tuition Fee Reduction ",
      content:
        "Mentors help find scholarships and strategies to lower tuition fees.",
      iconSrc: <BusinessCenterIcon />,
    },
    {
      heading: "Language Proficiency",
      content:
        "MentorGrad ensures you receive support to meet language proficiency requirements",
      iconSrc: <PaymentsIcon />,
    },
    {
      heading: "Visa Assistance",
      content:
        "Mentors and MentorGrad support available for navigating the visa application process.",
      iconSrc: <ChecklistIcon />,
    },
    {
      heading: "Travel and  Cultural Integration",
      content:
        "Mentors provide insights for a smooth transition, travel tips, and cultural integration advice.",
      iconSrc: <BusinessCenterIcon />,
    },
    {
      heading: "Student Housing",
      content: "Receive advice and support for student housing",
      iconSrc: <HomeIcon />,
    },
  ];

  const   faqQuestion = [
    {
      title: "What is MentorGrad?",
      description:
        "MentorGrad is a paid subscription-based platform designed for aspiring international students. It connects you with current international student mentors and provides easy access to global university programs, helping you navigate your journey to studying abroad.",
    },
    {
      title: "How do I sign up for MentorGrad?",
      description:
        "Signing up is simple. Click on 'Sign Up', fill in your basic details, and choose your subscription plan. You can create your profile and start connecting with mentors and universities.",
    },
    {
      title: "⁠How does MentorGrad help me connect with universities? ",
      description:
        "MentorGrad partners with universities worldwide. As a member, you get exclusive access to program showcases, virtual university tours, and direct communication channels with university advisors, aiding in your decision-making process.",
    },
    {
      title: "⁠Can I choose my mentor?",
      description:
        "Absolutely! You can browse mentor profiles based on their country of study, university, program, and experience. This helps you connect with someone who truly aligns with your academic and personal interests.",
    },
    {
      title: "How do I interact with mentors?",
      description:
        "Once you select a mentor, you can schedule one-on-one sessions, join group webinars, or communicate through our messaging platform. This flexibility allows you to interact in a way that suits you best.",
    },
    {
      title: "What resources are available on MentorGrad?",
      description:
        "We offer a range of resources, including webinar recordings, university brochures, application guides, scholarship information, and more, all designed to help you make informed decisions.",
    },
    {
      title: "Are there any language support services?",
      description:
        "Yes, we understand the importance of language in your educational journey. MentorGrad offers language support services including English proficiency test preparation and language learning resources.",
    },
    {
      title: "⁠Are there any language support services?",
      description:
        "Yes, we understand the importance of language in your educational journey. MentorGrad offers language support services including English proficiency test preparation and language learning resources",
    },
    {
      title: "⁠How does MentorGrad assist with the university application process? ",
      description:
        "Our platform provides step-by-step guidance on application procedures, document preparation, and submission. Mentors with relevant experience can also offer personalized tips and feedback on your applications",
    },
    {
      title: "⁠What makes MentorGrad unique?",
      description:
        "MentorGrad's strength lies in its community-driven approach. By connecting aspiring students with experienced international student mentors and global universities, we create a supportive ecosystem that guides you through every step of your international education journey.",
    },
    {
      title: "Why is MentorGrad a paid subscription based platform?",
      description:
        "MentorGrad does not charge commissions from Universities for student enrolments. By creating a paid platform, we stay away from any conflict of interest and we empower you to take the best decision for your future studies and career.",
    },
  ];

  return (
    <>
      <Header />
      <HomeBanner
        subtitle="Your Gateway to Global Education"
        title1="Welcome to"
        title2="MentorGrad!"
        detail="Empowering future leaders through guidance and wisdom. Our mentors offer personalized coaching to unlock your potential, navigate challenges, and achieve your aspirations. Join us on a transformative journey towards success and fulfillment."
        HomeBanner={homeBannerImages}
        link="/register"
      />
      <BecomeMentor
        title1="Why Choose"
        title2="MentorGrad?"
        BecomeMentorData={BecomeMentorData}
      />
      <MentorCanHelp MentorCanHelpData={MentorCanHelpData} />
      <UniPartnership />
      <InternationalStudent />
      <FAQQuestion FaqQuestion={faqQuestion}  />
      <CreateAccount
        title="Let's build your success story together!"
        buttonText="Start your journey today"
        link="/register"
      />
      <Footer />
    </>
  );
};
export default Index;
