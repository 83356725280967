import React, { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link, useNavigate } from "react-router-dom";
import BackdropLoader from "./BackdropLoader";
import LogoutIcon from "@mui/icons-material/Logout";
import { jwtDecode } from "../helper-functions";
import { GetUserData } from "../api";

const UserMenu = () => {
  const user = jwtDecode(localStorage.getItem("@storage_Key"));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userImage, setUserImage] = useState("");

  const setOverFlowWithDelay = () => {
    setTimeout(() => {
      document.body.style.overflow = 'visible';
      document.body.style.paddingRight = "0px";
      console.log("hello")
    }, 10);
  };

  useEffect(() => {
    setOverFlow();

    const fetchData = async () => {
      try {
        GetUserData().then((response) =>
          setUserImage(response.profileDetails.profilePic)
        );
      } catch (error) {
        console.error("Error fetching user data:", (error as Error).message);
      }
    };
    setOverFlow();

    fetchData();
    setOverFlow();
    return () => {
      // Cleanup function
      setOverFlow(); // Make sure to call setOverflow on cleanup
    };
    setOverFlow();
  }, []);
  const handleMenuOpen = (event: any) => {
    setOverFlow();
    setAnchorEl(event.currentTarget);
    setOverFlow();
    setOverFlowWithDelay();
  };

  const setOverFlow = () => {
    document.body.style.overflow = 'visible';
    document.body.style.paddingRight = "0px"
  };

  const handleMenuClose = () => {
    setOverFlow();
    setAnchorEl(null);
    setOverFlow();
  };

  const handleUserProfileClick = () => {
    navigate("/profile");
  };
  const handleLogoutClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      localStorage.removeItem("@storage_Key");

      setIsLoading(false);
      navigate("/login");
    }, 2000);
  };

  return (
    <div>
      <IconButton
        style={{ backgroundColor: "transparent" }}
        color="inherit"
        aria-label="user menu"
        onClick={handleMenuOpen}
        disableRipple // Disable the ripple effect on click
        disableFocusRipple // Disable the ripple effect on focus
      >
        {userImage ? (
          <img
            src={userImage}
            alt="User Avatar"
            style={{ borderRadius: "15px", width: "40px", height: "40px" }}
          />
        ) : (
          <AccountCircleIcon fontSize="large" style={{ color: "#5F61BE" }} />
        )}{" "}
        <span style={{marginLeft: "10px"}}>{user.username}</span>
        <ArrowDropDownIcon fontSize="small" style={{ marginTop: "5px" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          ".MuiPaper-root": {
            backgroundColor: "#fffff",
            marginTop: "20px" // Set your custom background color here
          },
        }}
      >
        <MenuItem
          sx={{
            minWidth: "fit-content",
            paddingRight: 10,
            py: 1,
          }}
        >
          {userImage ? (
            <img
              src={userImage}
              alt="User Avatar"
              style={{ borderRadius: "15px", width: "40px", height: "40px" }}
            />
          ) : (
            <AccountCircleIcon fontSize="large" style={{ color: "#5F61BE" }} />
          )}
          <div style={{ marginLeft: "5px" }}>
            <p style={{ marginBlock: 0 }}>{user.username}</p>
            <p style={{ marginBlock: 0, fontSize: "12px" }}>{user.userType}</p>
          </div>
        </MenuItem>
        <Link to={`/${user?.userType == "Mentor" ? "mentor" : "student"}/dashboard?tab=0`} style={{ textDecoration: "none" }}>
          <MenuItem
            sx={{
              minWidth: "fit-content",
              paddingRight: 10,
              borderTop: "1px solid #B9B9B9",
              color: "#000",
              textDecoration: "none",
            }}
          >
            <ListItemText primary="Dashboard" />
          </MenuItem>
        </Link>
        <MenuItem
          sx={{
            minWidth: "fit-content",
            paddingRight: 10,
            borderTop: "1px solid #B9B9B9",
          }}
        >
          <Link
            to={`/${user?.userType == "Mentor" ? "mentor" : "student"}/dashboard?tab=7`}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <ListItemText primary="Profile Settings" />
          </Link>
        </MenuItem>
        <MenuItem
          onClick={handleLogoutClick}
          sx={{
            minWidth: "fit-content",
            paddingRight: 10,
            borderTop: "1px solid #B9B9B9",
          }}
        >
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
      <BackdropLoader open={isLoading} />
    </div>
  );
};

export default UserMenu;
