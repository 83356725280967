import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import JohnDoe from "../../../Assets/Images/JohnDoeHome.png";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { findMentors } from "../../../api";
import { notifyError } from "../../Toastifycom";
const data = [
  {
    id: 1,
    img: JohnDoe,
    name: "John Doe",
    about:
      "Software Engineer @ Optimizely Software Engineer | Lead Instructor | Career Mentor | Helped 100+ learners to achieve their goals",
  },
  {
    id: 2,
    img: JohnDoe,
    name: "John Doe",
    about:
      "Software Engineer @ Optimizely Software Engineer | Lead Instructor | Career Mentor | Helped 100+ learners to achieve their goals",
  },
  {
    id: 3,
    img: JohnDoe,
    name: "John Doe",
    about:
      "Software Engineer @ Optimizely Software Engineer | Lead Instructor | Career Mentor | Helped 100+ learners to achieve their goals",
  },
];

type Mentor = {
  _id: string;
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  countryOfResidence: string;
  universityName: string;
  hourlyRate: number;
  comment: string;
  mentoringarea: any;
  skills: any;
  services: any;
  topics: any;
  reviews: Array<{
    rating: string;
    comment: string;
  }>;
  user: Array<{
    attachments: Array<{
      attachmentPath: string;
      attachmentURL: string;
      name: string;
    }>;
  }>;
};

type MentorList = Mentor[];

const SimilarMentor = ({ location = "" }: { location?: string }) => {
  const navigate = useNavigate();
  const [mentorList, setMentorList] = useState<MentorList>([]);

  useEffect(() => {
    getMentors();
  }, []);

  const getMentors = () => {
    let payload = {
      countryOfResidence: location,
      gender: "",
      mentoringarea: "",
      skills: "",
      topics: "",
      services: "",
      search: "",
      page: "1",
      limit: "2",
    };
    try {
      findMentors(payload)
        .then((e) => {
          setMentorList(e);
          console.log(e);
        })
        .catch((err) => {
          notifyError(err);
        });
    } catch (error) {
      notifyError(error);
    }
  };
  return (
    <Box
      sx={{
        borderRadius: "10px",
        border: "1px solid #DBDBDB",
        p: "20px",
        background: "#C9F6EF",
        my: "40px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Gilroy",
            fontWeight: "bold",
          }}
        >
          Similar Mentors
        </Typography>
        <Button
          variant="outlined"
          sx={{
            display: { xs: "none", md: "flex" },
            color: "#000",
            background: "#C9F6EF",
            borderRadius: "15px",
            border: "1px solid #000",
            py: "12px",
            textTransform: "capitalize",
            px: "20px",
            fontFamily: "Gilroy",
            "&:hover": {
              background: "#C9F6EF",
              border: "1px solid #000",
            },
          }}
          onClick={() => {
            navigate("/findMentor");
          }}
        >
          View all Mentors
        </Button>
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" }, mt: "40px" }}>
        <Grid container spacing={3}>
          {mentorList.map((item) => (
            <Grid item xs={12} md={4}>
              <Box sx={{ borderRadius: "8px", p: "10px", background: "#fff" }}>
                <Box sx={{ height: "250px" }}>
                  <img
                    src={
                      (item?.user?.[0]?.attachments?.[0]?.attachmentPath ||
                        "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg")
                    }
                    alt=""
                    style={{
                      borderRadius: "15px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Gilroy",
                      fontWeight: "bold",
                    }}
                  >
                    {item.userName}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <StarRoundedIcon sx={{ color: "#FFA340" }} />
                    <Typography variant="body1" sx={{ fontFamily: "DM Sans" }}>
                      {item?.reviews[0]?.rating ? (
                        <>
                          {item.reviews[0].rating}.0 (
                          <span style={{ color: "#FFA340" }}>
                            {item.reviews.length} review
                          </span>
                          )
                        </>
                      ) : (
                        "No Rating"
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ p: "10px" }}>
                  <Typography variant="body1" sx={{ fontFamily: "DM Sans" }}>
                    {item.comment}
                  </Typography>

                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      navigate("/student/dashboard?tab=3");
                    }}
                    sx={{
                      mt: "20px",
                      color: "#fff",
                      background: "#7476D1",
                      borderRadius: "15px",
                      py: "12px",
                      textTransform: "capitalize",
                      px: "40px",
                      fontFamily: "Gilroy",
                      "&:hover": {
                        background: "#7476D1",
                      },
                    }}
                  >
                    Send Message
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ display: { md: "none" } }}>
        <Splide
          options={{
            type: "loop",
            arrows: false,
            gap: "10px",
          }}
        >
          {mentorList.map((item, index) => (
            <SplideSlide key={index}>
              <Box
                sx={{
                  borderRadius: "8px",
                  p: "10px",
                  background: "#fff",
                  mt: "20px",
                  mb: "40px",
                }}
              >
                <Box sx={{ height: "250px" }}>
                  <img
                    src={
                      (item?.user?.[0]?.attachments?.[0]?.attachmentPath ||
                        "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg")
                    }                    
                    alt=""
                    style={{
                      borderRadius: "15px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Gilroy",
                      fontWeight: "bold",
                    }}
                  >
                    {item.userName}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <StarRoundedIcon sx={{ color: "#FFA340" }} />
                    <Typography variant="body1" sx={{ fontFamily: "DM Sans" }}>
                      {item?.reviews[0]?.rating ? (
                        <>
                          {item.reviews[0].rating}.0 (
                          <span style={{ color: "#FFA340" }}>
                            {item.reviews.length} review
                          </span>
                          )
                        </>
                      ) : (
                        "No Rating"
                      )}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ p: "10px" }}>
                  <Typography variant="body1" sx={{ fontFamily: "DM Sans" }}>
                    {item.comment}
                  </Typography>

                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      navigate("/student/dashboard?tab=3");
                    }}
                    sx={{
                      mt: "20px",
                      color: "#fff",
                      background: "#7476D1",
                      borderRadius: "15px",
                      py: "12px",
                      textTransform: "capitalize",
                      px: "40px",
                      fontFamily: "Gilroy",
                      "&:hover": {
                        background: "#7476D1",
                      },
                    }}
                  >
                    Send Message
                  </Button>
                </Box>
              </Box>
            </SplideSlide>
          ))}
        </Splide>

        <Button
          variant="outlined"
          fullWidth
          sx={{
            display: { md: "none" },
            color: "#000",
            background: "#C9F6EF",
            borderRadius: "15px",
            border: "1px solid #000",
            py: "12px",
            textTransform: "capitalize",
            px: "20px",
            fontFamily: "Gilroy",
            "&:hover": {
              background: "#C9F6EF",
              border: "1px solid #000",
            },
          }}
          onClick={() => {
            navigate("/findMentor");
          }}
        >
          View all Mentors
        </Button>
      </Box>
    </Box>
  );
};

export default SimilarMentor;
