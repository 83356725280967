import styled from "styled-components";
const MentorCanHelpWrapper = styled.section`
  .help-box {
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 16px;
    margin-bottom: 24px;
    min-height: 200px;
    cursor: pointer;
    h3,
    svg,
    p {
      color: #fff;
    }
    svg {
      font-size: 44px;
    }
    h3 {
      font-size: 24px;
      font-family: "Gilroy";
    }
    p {
      font-size: 16px;
      font-family: "Gilroy";
    }
    &:hover {
      background-color: #c9f6ef;
      h3,
      svg,
      p {
        color: #000;
      }
    }
  }
`;
export default MentorCanHelpWrapper;
