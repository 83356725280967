import React, { useState } from "react";
import Container from "../../common/Input/useStyles";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import FaqQuestion from "../../components/FaqSection";
import OurPartner from "../../components/OurParthner";
import InternationalStudent from "../../components/MeetOurInternationlStudent/index";
import "./Home.css";
import { AccordionWrapper } from "./HomeStyles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  InputAdornment,
  Slide,
  TextField,
  Typography,
} from "@mui/material";

import useMediaQuery from "../../hooks/MediaQuery";
import { InputHolder } from "../../components/UserForm/UserFormStyles";
import { Link, useNavigate } from "react-router-dom";
import BirminghamUniversity from "../../Assets/Images/birminghamuniversity.png";
import CharlesSturtUniversity from "../../Assets/Images/charles-sturt-university.svg";
import Group157 from "../../Assets/Images/Group 157.svg";
import Group158 from "../../Assets/Images/Group 158.svg";
import PrimaryLogo from "../../Assets/Images/PrimaryLogotype-1024x219 1.png";
import Eclipse25 from "../../Assets/Images/Ellipse 25.png";
import Send1 from "../../Assets/Images/send 1.png";
import Link1 from "../../Assets/Images/link 1.png";
import Browsing1 from "../../Assets/Images/browsing(1) 1.png";

import SectionOneIllustration from "../../Assets/Images/illustrations/home_page_section_1_1.png";

import TransparentBlueBackground from "../../Assets/Images/transparent-blue-bg.png";

import SectionTwoIllustration from "../../Assets/Images/illustrations/homepage_section_2.png";

import ExpandMore from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

import Image6 from "../../Assets/Images/image 6.png";
import Image7 from "../../Assets/Images/image 7.png";
import Image8 from "../../Assets/Images/image 8.png";
import { ActiveMembers } from "../../components/ActiveMembers/ActiveMembers";

import Testimonials from "../../components/Testimonials/Testimonials";
import StudentMentors from "../../components/StudentMentors/StudentMentors";
import UniversitiesLogo from "../../components/UniversitiesLogo/UniversitiesLogo";
import BoxContainer from "../Admin_Screen/layout/Customization/BoxContainer";
const Home = () => {
  const isNotMobile = useMediaQuery("(min-width: 950px)");

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const faqQuestion = [
    {
      title: "What is MentorGrad?",
      description:
        " MentorGrad is a paid subscription-based platform designed for aspiring international students. It connects you with current international student mentors and provides easy access to global university programs, helping you navigate your journey to studying abroad",
    },
    {
      title: "How do I sign up for MentorGrad?",
      description:
        "Signing up is simple. Click on 'Sign Up', fill in your basic details, and choose your subscription plan. You can create your profile and start connecting with mentors and universities",
    },
    {
      title: "⁠How does MentorGrad help me connect with universities? ",
      description:
        " MentorGrad partners with universities worldwide. As a member, you get exclusive access to program showcases, virtual university tours, and direct communication channels with university advisors, aiding in your decision-making process",
    },
    {
      title: "⁠⁠⁠What kind of mentors are available on MentorGrad?",
      description:
        "Our mentors are international students from diverse backgrounds and universities. They offer guidance on application process, scholarships, direct university admissions, student life, academic advice, and personalized guidance based on their own experiences.",
    },
    {
      title: " ⁠How can I become a mentor on MentorGrad?      ",
      description:
        "To become a mentor, you need to be a current international student with a good understanding of your university and the challenges faced by international students. Simply apply on our website, complete the verification process, and once approved, you can start mentoring!        ",
    },
    {
      title: "What are the benefits of being a mentor on MentorGrad?",
      description:
        "As a mentor, you'll not only get the satisfaction of helping others but also earn an income through your mentoring activities. Additionally, you can receive referral rewards if your mentees enrol in your university.",
    },
    {
      title: "What kind of support will I provide as a mentor?",
      description:
        "Mentors help with university selection, application processes, direct admission in to your university, preparation for life as an international student, cultural adaptation, and any specific challenges or queries that aspiring students might have.",
    },
    {
      title: "⁠⁠How does MentorGrad benefit universities?      ",
      description:
        " Universities benefit from increased visibility among a global pool of qualified students. We provide detailed profiles of students, including their budget, program preferences, and location interests, helping universities tailor their outreach and recruitment strategies.        ",
    },
    {
      title: "⁠How are student leads generated on MentorGrad?      ",
      description:
        "Student leads are generated through our targeted matching process through which we match potential students with universities and study programs. Each student profile is vetted to ensure they meet the application-ready criteria",
    },
    {
      title: "⁠Can universities access student profiles directly?",
      description:
        "Yes, universities can access student profiles that match their program offerings. These profiles include comprehensive information to help universities understand student needs, preferences, and readiness.",
    },
  ];
  return (
    <>
      <Header /> {/* NavBar  */}
      <Box
        sx={{
          backgroundColor: "#D1D2F4",
          pt: 12,
          "@media (max-width:950px)": {
            pb: 4, // Padding for screens from medium (md) and up
            pt: 5,
          },
        }}
      >
        <Container
          style={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <Box>
            <Box>
              <Grid container>
                <Grid item sm={12} md={6} sx={{order: { xs: 2, md: 0 }}}>
                  <Box
                    paddingTop={{ xs: "20px", md: "100px" }}
                    sx={{
                      textAlign: "left",
                      paddingBottom: "30px",
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: "Gilroy",
                        color: "#7476D1",
                        fontSize: { xs: "14px", md: "20px" },
                      }}
                    >
                      <p style={{ fontFamily: "Gilroy" }}>
                        Embrace the power of connections!
                      </p>
                    </Box>

                    <Typography
                      variant="h1"
                      sx={{
                        fontFamily: "Gilroy",
                        fontWeight: 700,
                        fontSize: { xs: "32px", md: "56px" },
                        maxWidth: "890px",
                      }}
                    >
                      Connect With International{" "}
                      <span style={{ color: "#7476D1" }}>Student Mentors</span>{" "}
                      and Universities
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "DM Sans",
                        lineHeight: "26px",
                        fontSize: { xs: "14px", md: "20px" },
                        py: "30px",
                      }}
                    >
                      We don't match you with study programs! We match you with
                      mentors and universities. We ensure that you enrol in a
                      university and study program that is best suited to you.
                    </Typography>

                    {/* <div className="searchBar">
                      <input
                        style={{
                          fontSize: "16px",
                          width: "90%",
                          border: "none",
                          outline: "none",
                        }}
                        placeholder="Where do you want to study?"
                        onChange={handleChange}
                      />
                      <p
                        style={{
                          background: "#C9F6EF",
                          margin: "0px",
                          padding: "11px",
                          borderRadius: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/findMentor?location=${searchTerm}`)
                        }
                      >
                        <SearchIcon />
                      </p>
                    </div> */}
                    <ActiveMembers />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 0 } }}>
                  <img src={SectionOneIllustration} alt="Students learning" className="Woman-image" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* Our mentors section */}
      <Box
        sx={{
          background:
            "linear-gradient(180deg, rgba(186,187,228,1) 0%, rgba(201,246,239,1) 100%)",
          py: { xs: 5, md: 10 },
        }}
      >
        <Container>
          <Box>
            <Box sx={{ width: "95%", m: "0 auto" }}>
              <h2
                style={{
                  fontWeight: 700,
                  fontSize: "48px",
                  textAlign: "center",
                }}
              >
                Our mentors are from the world's leading universities
              </h2>
              <UniversitiesLogo />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          py: { xs: 5, md: 10 },
        }}
      >
        <Container>
          <Box>
            <Box sx={{ width: "100%", m: "0 auto" }}>
              <Grid className="HowItWorks">
                <Grid item xl={6} width={"50%"} className="howTtImg">
                  <img
                    src={SectionTwoIllustration}
                    alt="How it works"
                    style={{ width: "100%", alignSelf: "center" }}
                  />
                </Grid>
                <Grid
                  item
                  xl={6}
                  width={"50%"}
                  className="howTtContent"
                  sx={{ textAlign: "left" }}
                >
                  <div style={{ alignSelf: "center" }}>
                    <h2 style={{ fontWeight: 700 }}>How does it work?</h2>
                    <p
                      style={{
                        width: "80%",
                        fontSize: "20px",
                        marginBottom: "20px",
                      }}
                      className="howItSubHeading"
                    >
                      Our mentors who are current international students assist
                      in helping aspiring students understand the complexities
                      of the admission process, including the paperwork,
                      language proficiency tests, and scholarship opportunities
                      available.
                    </p>
                    <Box
                      sx={{
                        backgroundColor: "#C9F6EF",
                        px: 2,
                        py: 3,
                        mb: 1.6,
                        borderRadius: "15px",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url('${Eclipse25}')`,
                          width: "42px",
                          height: "42px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "8px",
                        }}
                      >
                        <img
                          style={{
                            width: "60%",
                            height: "60%",
                            objectFit: "cover",
                          }}
                          src={Browsing1}
                          alt="user"
                        />
                      </div>
                      <h6 style={{ fontWeight: 700 }}>Browse Mentors</h6>
                      <p>
                        Use the search option to search for mentors. You may
                        filter the results based on your country or other
                        preferences.
                      </p>
                    </Box>
                    <Grid columnSpacing={1} container>
                      <Grid item xs={6} md={6}>
                        <Box
                          sx={{
                            backgroundColor: "#7476D1",
                            px: 2,
                            py: 3,
                            borderRadius: "15px",
                            color: "white",
                            minHeight: { xs: "260px", md: "200px" },
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: `url('${Eclipse25}')`,
                              width: "42px",
                              height: "42px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "8px",
                            }}
                          >
                            <img
                              style={{
                                width: "60%",
                                height: "60%",
                                objectFit: "cover",
                              }}
                              src={Send1}
                              alt="user"
                            />
                          </div>
                          <h5>Send Session Request</h5>
                          <p>
                            Have you narrowed your search for potential mentors?
                            It’s time to connect!
                          </p>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Box
                          sx={{
                            backgroundColor: "#7476D1",
                            px: 2,
                            py: 3,
                            borderRadius: "15px",
                            color: "white",
                            minHeight: { xs: "260px", md: "200px" },
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: `url('${Eclipse25}')`,
                              width: "42px",
                              height: "42px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "8px",
                            }}
                          >
                            <img
                              style={{
                                width: "60%",
                                height: "60%",
                                objectFit: "cover",
                              }}
                              src={Link1}
                              alt="user"
                            />
                          </div>
                          <h5>Connect 1:1</h5>
                          <p>
                            Set up calls and chat with your mentor! Make the
                            best of your connection!
                          </p>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* Meet our international student Mentors  */}
      <Box>
        <InternationalStudent />
      </Box>
      {/* Our Partners  */}
      <OurPartner />
      {/* Testimonials  */}
      <Box
        sx={{
          py: { xs: 5, md: 10 },
          backgroundColor: "#7476D1",
        }}
      >
        <Box>
          <Box sx={{ width: "100%", m: "10 auto" }}>
            <Testimonials />
          </Box>
        </Box>
      </Box>
      <FaqQuestion FaqQuestion={faqQuestion} />
      <Footer />
    </>
  );
};

export default Home;
