import {
  Autocomplete,
  Grid,
  OutlinedInput,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import {
  BackgroundChecked,
  Buttonsposition,
  CheckBoxPos,
  CheckedPosition,
  Container,
  ContainerD,
  Heading,
  InputHolder,
  InputHolder1,
  Label,
  Labelb,
  TextChecked,
} from "../../components/UserForm/UserFormStyles";
import "react-dropdown/style.css";
import "../../Assets/css/mentorappfrom.css"
import {
  English,
  Organization,
  optionsMulti,
} from "../../Data/Data";
import useMediaQuery from "../../hooks/MediaQuery";
import RadioComp from "../RadioButton";
import MultiCheckBox from "../MultiCheckBox";
import MultiRadio from "../MultiRadio";
import IconButton from "../IconButton";
import { observer } from "mobx-react-lite";
import RightLinkButton from "../RightIconButton";
import SearchDropdown from "../SearchDropdown";
import { Context } from "../../Context/ContextStates";
import {
  MentorForm,
  RegisterMentor,
  getAllServices,
  getAllSkills,
  getAllTopics,
} from "../../api";
import { useNavigate } from "react-router-dom";
import Toast, { notifyError, notifySuccess } from "../Toastifycom";
import TextInput from "../StudentProfileDetails/InputProfile";
import Loadercom from "../Loadercom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorText } from "../../Screens/Account_Screen/AccountScreenStyles";
import { MultiSelect } from "react-multi-select-component";

interface University {
  id: number;
  label: string;
  value: string;
}
type Skill = {
  _id: string;
  Name: string;
};
type Topic = {
  _id: string;
  Name: string;
};
type Service = {
  _id: string;
  Name: string;
};

type Skills = Skill[];
type Topics = Topic[];
type Services = Service[];

const MentorAppDetails: FC = () => {
  const CharacterCounter: React.FC<{ text: string }> = ({ text }) => {
    const characterCount = text.trim().length;
    return <small className="text-muted">{characterCount} characters</small>;
  };

  const navigate = useNavigate();
  const { countryList, setCountryList } = useContext(Context);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const handleCountryChange = (selectedCountry: any) => {
    setSelectedCountry(selectedCountry.name); // Store the selected country in state
  };
  const [multiradio, setMultiRadio] = useState("");

  const handleChange = (event: any) => {
    setMultiRadio(event.target.value);
  };
  const [student, setStudent] = useState("");

  const handleChangeStudent = (event: any) => {
    setStudent(event.target.value);
  };
  const [work, setWork] = useState("");
  const [skills, setSkills] = useState<Skills>([]);

  const handleChangeWork = (event: any) => {
    setWork(event.target.value);
  };
  const [university, setUniversity] = useState<University | null>(null);

  const [organization, setOrganization] = useState("");
  const [load, setLoad] = useState(false);
  const isMobile = useMediaQuery("(min-width: 950px)");

  const [svalue, setSvalue] = useState([]);
  const [state, setState] = React.useState<{ selections: string[] }>({
    selections: [],
  });
  const [age, setAge] = React.useState("");
  const [topics, setTopics] = useState<Topics>([]);
  const [services, setServices] = useState<Services>([]);

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const handleAgeChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  function handleCheckboxChange(key: string) {
    let sel = state.selections;
    sel.push(key)
    sel = [...(new Set(sel))]
    setState({
      selections: sel,
    });
  }

  const handleSubmit = async () => {
    setLoad(true);
    RegisterMentor(
      formik.values.fname,
      formik.values.lname,
      formik.values.email
    )
      .then((e) => {
        const mentorId = e["message"];
        if (e.status) {
          const formResponse = MentorForm(
            university,
            selectedCountry,
            student,
            work,
            organization,
            multiradio,
            state,
            formik.values.svalue,
            formik.values.desc,
            mentorId,
            selectedServices,
            selectedSkills,
            selectedTopics,
            formik.values.email
          ).then((e) => {
            if (e.success === true) {
              setLoad(false);
              notifySuccess(
                "Form submitted successfully, Please check your email for login details!"
              );
              setTimeout(() => {
                navigate("/login");
              }, 2000);
            } else setLoad(false);

            console.log("error");
            // notifyError(e.message)
          });
        } else {
          setLoad(false);
          notifyError(e.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        alert(err);
        console.log(err, " Maybe network error")
      });
  };

  useEffect(() => {
    const getSkills = async () => {
      try {
        const response = await getAllSkills();
        setSkills(response.data);
      } catch (error) {
        console.error("Error fetching skills data:", error);
      }
    };

    const getServices = async () => {
      try {
        const response = await getAllServices();
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching skills data:", error);
      }
    };

    const getTopics = async () => {
      try {
        const response = await getAllTopics();
        setTopics(response.data);
      } catch (error) {
        console.error("Error fetching skills data:", error);
      }
    };

    getSkills();
    getServices();
    getTopics();
  }, []);

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      university: "",
      selectedCountry: "",
      svalue: [],
      desc: "",
      selectedSkills: [], // Add selectedSkills field to initialValues
      selectedTopics: [], // Add selectedTopics field to initialValues
      selectedServices: [] // Add selectedServices field to initialValues
    },
    validationSchema: Yup.object({
      // Define validation rules for selectedSkills, selectedTopics, and selectedServices
      selectedSkills: Yup.array().min(1, "At least one skill is required"),
      selectedTopics: Yup.array().min(1, "At least one topic is required"),
      selectedServices: Yup.array().min(1, "At least one service is required"),
      // Define validation rules for other fields
      svalue: Yup.array().min(1, "At least one language is required"),
      selectedCountry: Yup.string().required("Country of Current Residence is required"),
      fname: Yup.string().required("First Name is required"),
      lname: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
      university: Yup.string().required("University is required"),
      desc: Yup.string()
        .min(300, "Description must be at least 300 characters")
        .required("Description is required"),
    }),
    onSubmit: handleSubmit,
  });


  const options = skills.map((skill) => ({
    id: skill._id,
    label: skill.Name,
    value: skill.Name, // Add this line
  }));

  const topicOptions = topics.map((skill) => ({
    id: skill._id,
    label: skill.Name,
    value: skill.Name, // Add this line
  }));

  const serviceOptions = services.map((skill) => ({
    id: skill._id,
    label: skill.Name,
    value: skill.Name, // Add this line
  }));

  return (
    <Container>
      <ContainerD>
        <Heading>Mentor Application Form</Heading>
        <InputHolder>
          <div
            className="mb-3"
            style={
              isMobile
                ? {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                }
                : {
                  gap: "1rem",
                }
            }
          >
            <div style={isMobile ? { width: "50%" } : { marginBottom: '1rem' }}>
              <Labelb>First Name</Labelb>
              <TextInput
                value={formik.values.fname}
                onChange={formik.handleChange}
                placeholder="John"
                id={"fname"}
                width={"100%"}
                type={"text"}
              />
              {formik.touched.fname && formik.errors.fname ? (
                <ErrorText>{formik.errors.fname}</ErrorText>
              ) : (
                ""
              )}
            </div>
            <div style={isMobile ? { width: "50%" } : {}}>
              <Labelb>Last Name</Labelb>
              <TextInput
                value={formik.values.lname}
                onChange={formik.handleChange}
                placeholder="Doe"
                id={"lname"}
                width={"100%"}
                type={"text"}
              />
              {formik.touched.lname && formik.errors.lname ? (
                <ErrorText>{formik.errors.lname}</ErrorText>
              ) : (
                ""
              )}
            </div>
          </div>
        </InputHolder>
        <div className="mb-3">
          <InputHolder>
            <Label>Email</Label>
            <TextInput
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Doe@gmail.com"
              id={"email"}
              width={"100%"}
              type={"text"}
            />
          </InputHolder>
          {formik.touched.email && formik.errors.email ? (
            <ErrorText>{formik.errors.email}</ErrorText>
          ) : (
            ""
          )}
        </div>
        <InputHolder className="mb-3">
          <Label>University Name</Label>
          <TextInput
            value={university}
            onChange={formik.handleChange}
            id={"university"}
            width={"100%"}
            type={"text"}
          />
          {formik.touched.university && formik.errors.university ? (
            <ErrorText>{formik.errors.university}</ErrorText>
          ) : (
            ""
          )}
        </InputHolder>
        <div className="mb-3">
          <InputHolder>
            <div className="mb-3">
              <Label>Country of Residence</Label>
              <Autocomplete
                disablePortal
                id="Country"
                options={countryList || []}
                value={{ name: selectedCountry }}
                sx={{
                  width: "100%",
                  height: "56px !important",
                  borderRadius: "10px",
                  backgroundColor: "#fffff",
                  "& fieldset": {
                    borderRadius: "10px",
                    borderWidth: "2px",
                    borderColor: "#D6D6D6",
                    height: "60px",
                  },
                }}
                onBlur={() => formik.setFieldTouched("selectedCountry")}
                onChange={(_, newValue: any) => {
                  const countryName = newValue.name; // Extract the country name from the newValue object
                  handleCountryChange(newValue);
                  formik.setFieldValue("selectedCountry", countryName); // Set the country name as the selectedCountry value
                }}
                getOptionLabel={(country: any) => (country ? country.name : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    size="small"
                    placeholder="Select"
                    sx={{ 
                      height: '56px',
                      "& fieldset": {
                        height: "60px",
                      },
                    }}
                  />
                )}
              />
              {formik.touched.selectedCountry && formik.errors.selectedCountry ? (
                <ErrorText>{formik.errors.selectedCountry}</ErrorText>
              ) : (
                ""
              )}
            </div>
            <InputHolder className="mb-3">
              <Grid container gap={1}>
                <InputHolder className="mb-3" >
                  <Label>Skills</Label>
                  <div
                    style={{ width: "100%" }}
                    className="multi-drop-container"
                  >
                    <MultiSelect
                      hasSelectAll={false}
                      options={options}
                      value={selectedSkills}
                      className="dark"
                      overrideStrings={{ selectSomeItems: "Skills" }}
                      onChange={(selectedOptions: any) => {
                        formik.setFieldValue('selectedSkills', selectedOptions);
                        if (selectedOptions.length < 15)
                          setSelectedSkills(selectedOptions);
                      }}
                      labelledBy=""
                    />

                  </div>
                  <CheckedPosition>
                    {selectedSkills.map((countryName: any, index) => (
                      <BackgroundChecked key={index}>
                        <TextChecked>{countryName.value}</TextChecked>
                      </BackgroundChecked>
                    ))}
                  </CheckedPosition>
                </InputHolder>

                <InputHolder className="mb-3">
                  <Label>Topics</Label>
                  <div
                    style={{ width: "100%" }}
                    className="multi-drop-container"
                  >
                    <MultiSelect
                      hasSelectAll={false}
                      options={topicOptions}
                      value={selectedTopics}
                      className="dark"
                      overrideStrings={{ selectSomeItems: "Topics" }} // <- to override strings
                      onChange={(selectedOptions: any) => {
                        formik.setFieldValue('selectedTopics', selectedOptions); // Update formik field directly
                        setSelectedTopics(selectedOptions);
                      }}
                      labelledBy=""
                    />

                  </div>
                  <CheckedPosition>
                    {selectedTopics.map((countryName: any, index) => (
                      <BackgroundChecked key={index}>
                        <TextChecked>{countryName.value}</TextChecked>
                      </BackgroundChecked>
                    ))}
                  </CheckedPosition>
                </InputHolder>

                <InputHolder>
                  <Label>Services</Label>
                  <div
                    style={{ width: "100%" }}
                    className="multi-drop-container"
                  >
                    <MultiSelect
                      hasSelectAll={false}
                      options={serviceOptions}
                      value={selectedServices}
                      className="dark"
                      overrideStrings={{ selectSomeItems: "Services" }} // <- to override strings
                      onChange={(selectedOptions: any) => {
                        formik.setFieldValue('selectedServices', selectedOptions); // Update formik field directly
                        setSelectedServices(selectedOptions);
                      }}
                      labelledBy=""
                    />

                  </div>
                  <CheckedPosition>
                    {selectedServices.map((countryName: any, index) => (
                      <BackgroundChecked key={index}>
                        <TextChecked>{countryName.value}</TextChecked>
                      </BackgroundChecked>
                    ))}
                  </CheckedPosition>
                </InputHolder>
              </Grid>
            </InputHolder>
          </InputHolder>
        </div>
        <InputHolder className="mb-3">
          <RadioComp
            heading="Are you a current student?"
            id={"1"}
            value1={student}
            onChange={handleChangeStudent}
          />
        </InputHolder>
        {student === "No" && (
          <InputHolder className="mb-3">
            <RadioComp
              heading="If you are not a current student, are you currently working?"
              id={"1"}
              value1={work}
              onChange={handleChangeWork}
            />
          </InputHolder>
        )}
        {work === "Yes" && (
          <InputHolder className="mb-3">
            <Label>Organization Name</Label>
            <SearchDropdown
              value={organization}
              width={"100%"}
              height={"56px"}
              id={"1"}
              onChange={(e) => setOrganization(e)}
              data={Organization}
            />
          </InputHolder>
        )}
        <InputHolder className="mb-3">
          <MultiRadio
            value1={multiradio}
            onChange={handleChange}
            heading={"What level of program are you enrolled in?"}
          />
        </InputHolder>

        <InputHolder className="mb-3">
          <Label>In which areas can you mentor students?</Label>
          <CheckBoxPos>
            {optionsMulti.map((option) => (
              <MultiCheckBox
                key={option}
                text={option}
                handleOnChange={() => handleCheckboxChange(option)}
                selected={state.selections.includes(option)}
              />
            ))}
          </CheckBoxPos>
        </InputHolder>
        <InputHolder1 className="mb-3">
          <Label>Language Proficiency</Label>
          <div style={{ width: "100%" }} className="multi-drop-container">
            <MultiSelect
              hasSelectAll={false}
              options={English}
              value={formik.values.svalue}
              className="dark"
              overrideStrings={{ selectSomeItems: "Language" }} // <- to override strings
              onChange={(selectedOptions: any) => {
                formik.setFieldValue("svalue", selectedOptions); // Update formik field directly
              }}
              labelledBy=""
            />
          </div>
          <CheckedPosition>
            {formik.values.svalue.map((countryName: any, index) => (
              <BackgroundChecked key={index}>
                <TextChecked>{countryName.value}</TextChecked>
              </BackgroundChecked>
            ))}
          </CheckedPosition>
          {formik.touched.svalue && formik.errors.svalue ? (
            <ErrorText>{formik.errors.svalue}</ErrorText>
          ) : (
            ""
          )}
        </InputHolder1>

        <Label>
          What would make you a fantastic mentor for students seeking to
          study abroad, especially in your country and/or institution?
        </Label>
        <div
          className="mb-3"
          style={{
            borderWidth: "4px",
            borderColor: "#D6D6D6",
          }}
        >
          <OutlinedInput
            style={{ textAlign: "start" }}
            fullWidth
            multiline
            autoComplete="off"
            maxRows={5}
            minRows={5}
            value={formik.values.desc}
            onChange={formik.handleChange("desc")}
            placeholder={"Write minimum 300 words"}
          />
          <CharacterCounter text={formik.values.desc} />
          {formik.touched.desc && formik.errors.desc ? (
            <ErrorText>{formik.errors.desc}</ErrorText>
          ) : (
            ""
          )}
        </div>
        {/* <MultilineInput value={MyMentor.desc} onChange={(e) => MyMentor.setDesc(e.target.value)} placeholder={'Write minimum 300 words'} /> */}

        <Buttonsposition>
          <RightLinkButton title="Cancel" onClick={() => ""} />
          {load === true ? (
            <Loadercom />
          ) : (
            <IconButton
              style={{ marginLeft: "2%" }}
              title="Submit"
              type={"submit"}
              onClick={formik.submitForm}
            />
          )}
        </Buttonsposition>
      </ContainerD>
      <Toast />
    </Container>
  );
};

export default observer(MentorAppDetails);