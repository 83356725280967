import { useEffect, useState } from "react";
import Container from "../../common/Input/useStyles";
import { NavLink, useNavigate } from "react-router-dom";
import Brand from "../../Assets/Images/MG_Logo.png";
import "./HeaderDashboard.css";
import {
  BackgroundChecked,
  BackgroundChecked1,
  TextChecked,
  TextChecked1,
} from "../UserForm/UserFormStyles";
import UserMenu from "../Usermenu";
import { jwtDecode } from "../../helper-functions";
import { userTypes } from "../../Data/Data";

const HeaderDashboard = () => {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const user: string = jwtDecode(
    localStorage.getItem("@storage_Key")
  )?.userType;

  useEffect(() => {
    const token = localStorage.getItem("@storage_Key");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="dash-nav-container">
      <Container>
        <div className="navContainer">
          <NavLink to="/">
            <div className="logo">
              <img src={Brand} style={{ width: "100%", objectFit: "cover" }} />
            </div>
          </NavLink>
          <div>
            <div className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={handleShowNavbar}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
          </div>
          <div className={`nav-elements-dash  ${showNavbar && "active"}`}>
            <ul className="menu">
              {user === userTypes.student && (
                <>
                  <li>
                    <a
                      href={isLoggedIn ? "/findMentor" : "/findMentor23"}
                      style={{
                        fontFamily: "Inter",
                        color: "#47464A",
                        fontWeight: 600,
                      }}
                    >
                      Find a Mentor
                    </a>
                  </li>
                  <li>
                    <NavLink
                      to="/About"
                      style={{
                        fontFamily: "Inter",
                        color: "#47464A",
                        fontWeight: 600,
                      }}
                    >
                      For Universities
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/Mentor"
                      style={{
                        fontFamily: "Inter",
                        color: "#47464A",
                        fontWeight: 600,
                      }}
                    >
                      Book a Session
                    </NavLink>
                  </li>
                </>
              )}
              {isLoggedIn ? (
                <li>
                  <NavLink
                    to="/mentor/dashboard?tab=0"
                    style={{
                      fontFamily: "Inter",
                      color: "#47464A",
                      fontWeight: 600,
                    }}
                  >
                    Dashboard
                  </NavLink>
                </li>
              ) : (
                <li>
                  <NavLink
                    to="/login"
                    style={{
                      fontFamily: "Inter",
                      color: "#47464A",
                      fontWeight: 600,
                    }}
                  >
                    Login
                  </NavLink>
                </li>
              )}
              {isLoggedIn ? (
                <li style={{ alignSelf: "center" }}>
                  <UserMenu />
                </li>
              ) : (
                <li>
                  <BackgroundChecked1 onClick={() => navigate("/register")}>
                    <TextChecked1>Get Started</TextChecked1>
                  </BackgroundChecked1>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeaderDashboard;
