import React, { FC, useEffect, useState } from 'react';
import {
  ColumnStudentForm,
  ContainerForm,
  FileName,
  FileNamepo,
  ImageContainerpo,
  LabelProfile,
  PositionProfile,
  PositionProfileForm,
  RightBorder,
  RightContainer,
  TopText,
} from './StudentProfileStyles';
import ButtonComp from '../Button';
import ChoosFile from '../ChooseFile';
import { InputHolder } from '../UserForm/UserFormStyles';
import { Link } from 'react-router-dom';
import { TopRightText1 } from '../../Screens/Account_Screen/AccountScreenStyles';
import { GetUserData, UploadDocumentsapi, IMGURL } from '../../api';
import { Flip, ToastContainer, toast } from 'react-toastify';
import SkeletonProfile from '../SkeletonLoader/SkeletonProfile';
import { handleFileChange } from '../UserForm/UploadDocuments';
import { notifyError, notifySuccess } from '../Toastifycom';
import useMediaQuery from '../../hooks/MediaQuery';

const DocumentDetail: FC = () => {
  const isMobile = useMediaQuery('(min-width: 950px)');

  const [value, setValue] = useState('');
  const [files, setFiles] = useState([
    {
      name: '',
      file: '',
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [documents, setDocuments] = useState({
    destination: '',
    encoding: '',
    fieldname: '',
    filename: '',
    mimetype: '',
    originalname: null,
    size: 0,
  });
  const [passport, setPassport] = useState({
    destination: '',
    encoding: '',
    fieldname: '',
    filename: '',
    mimetype: '',
    originalname: null,
    size: 0,
  });
  const [awards, setAwards] = useState({
    destination: '',
    encoding: '',
    fieldname: '',
    filename: '',
    mimetype: '',
    originalname: null,
    size: 0,
  });
  const [english, setEnglish] = useState({
    destination: '',
    encoding: '',
    fieldname: '',
    filename: '',
    mimetype: '',
    originalname: null,
    size: 0,
  });
  const [reference, setReference] = useState({
    destination: '',
    encoding: '',
    fieldname: '',
    filename: '',
    mimetype: '',
    originalname: null,
    size: 0,
  });
  const [motivation, setMotivation] = useState({
    destination: '',
    encoding: '',
    fieldname: '',
    filename: '',
    mimetype: '',
    originalname: null,
    size: 0,
  });
  useEffect(() => {
    setLoading(true);

    GetUserData()
      .then((e) => {
        console.log(e.uploadDocuments);
        setDocuments(e.uploadDocuments.academic_degrees)
        setPassport(e.uploadDocuments.passport)
        setAwards(e.uploadDocuments.awardsandcertificates)
        setEnglish(e.uploadDocuments.englishtest_results)
        setReference(e.uploadDocuments.references)
        setMotivation(e.uploadDocuments.motivation_letter)

        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(err.message, {
          transition: Flip,
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 1,
          theme: 'light',
        });
        setLoading(false);
      });
  }, [refresh]);
  return (
    <RightBorder>
      <TopText>Document Detail</TopText>
      {loading === true ? (
        <SkeletonProfile />
      ) : (
        <ContainerForm>
          <InputHolder style={{ marginBottom: '1rem', marginTop: '5px' }}>
            <LabelProfile>Academic Degrees and Certificates</LabelProfile>
            <FileNamepo>
              {documents?.originalname && !documents.hasOwnProperty("buffer") && (
                <Link
                  to={IMGURL.replace("/api/v1", "/image") + `${documents["filename"]}`}
                  style={{ marginLeft: "0px", alignSelf: "center" }}
                  target="_blank"
                >
                  <TopRightText1 style={{ marginLeft: "0px", alignSelf: "center" }}>
                    View File
                  </TopRightText1>
                </Link>
              )}
            </FileNamepo>
            <ChoosFile
              padding={'2%'}
              onChange={(e) =>
                files.push({
                  name: 'academic_degrees',
                  file: handleFileChange(e),
                })
              }
              id={'1'}
              width={'100%'}
              inputmode={'file'}
            />
          </InputHolder>

          <InputHolder style={{ marginBottom: '1rem', marginTop: '5px' }}>
            <LabelProfile>English Proficiency Test Results</LabelProfile>
            <FileNamepo>
              {english?.originalname && !english.hasOwnProperty("buffer") && (
                <Link
                  to={IMGURL.replace("/api/v1", "/image") + `${english["filename"]}`}
                  style={{ marginLeft: "0px", alignSelf: "center" }}
                  target="_blank"
                >
                  <TopRightText1 style={{ marginLeft: "0px", alignSelf: "center" }}>
                    View File
                  </TopRightText1>
                </Link>
              )}
            </FileNamepo>
            <ChoosFile
              padding={'2%'}
              onChange={(e) =>
                files.push({
                  name: 'englishtest_results',
                  file: handleFileChange(e),
                })
              }
              id={'1'}
              width={'100%'}
              inputmode={'file'}
            />
          </InputHolder>
          <InputHolder style={{ marginBottom: '1rem', marginTop: '5px' }}>
            <LabelProfile>Motivation Letter</LabelProfile>
            <FileNamepo>
              {motivation?.originalname && !motivation.hasOwnProperty("buffer") && (
                <Link
                  to={IMGURL.replace("/api/v1", "/image") + `${motivation["filename"]}`}
                  style={{ marginLeft: "0px", alignSelf: "center" }}
                  target="_blank"
                >
                  <TopRightText1 style={{ marginLeft: "0px", alignSelf: "center" }}>
                    View File
                  </TopRightText1>
                </Link>
              )}
            </FileNamepo>
            <ChoosFile
              padding={'2%'}
              onChange={(e) =>
                files.push({
                  name: 'motivation_letter',
                  file: handleFileChange(e),
                })
              }
              id={'1'}
              width={'100%'}
              inputmode={'file'}
            />
          </InputHolder>
          <InputHolder style={{ marginBottom: '1rem', marginTop: '5px' }}>
            <LabelProfile>References</LabelProfile>
            <FileNamepo>
              {reference?.originalname && !reference.hasOwnProperty("buffer") && (
                <Link
                  to={IMGURL.replace("/api/v1", "/image") + `${reference["filename"]}`}
                  style={{ marginLeft: "0px", alignSelf: "center" }}
                  target="_blank"
                >
                  <TopRightText1 style={{ marginLeft: "0px", alignSelf: "center" }}>
                    View File
                  </TopRightText1>
                </Link>
              )}              
            </FileNamepo>
            <ChoosFile
              padding={'2%'}
              onChange={(e) =>
                files.push({ name: 'references', file: handleFileChange(e) })
              }
              id={'1'}
              width={'100%'}
              inputmode={'file'}
            />
          </InputHolder>
          <InputHolder style={{ marginBottom: '1rem', marginTop: '5px' }}>
            <LabelProfile>Awards and Certificates</LabelProfile>
            <FileNamepo>
              {awards?.originalname && !awards.hasOwnProperty("buffer") && (
                <Link
                  to={IMGURL.replace("/api/v1", "/image") + `${awards["filename"]}`}
                  style={{ marginLeft: "0px", alignSelf: "center" }}
                  target="_blank"
                >
                  <TopRightText1 style={{ marginLeft: "0px", alignSelf: "center" }}>
                    View File
                  </TopRightText1>
                </Link>
              )}                
            </FileNamepo>
            <ChoosFile
              padding={'2%'}
              onChange={(e) =>
                files.push({
                  name: 'awardsandcertificates',
                  file: handleFileChange(e),
                })
              }
              id={'1'}
              width={'100%'}
              inputmode={'file'}
            />
          </InputHolder>
          <InputHolder style={{ marginBottom: '1rem', marginTop: '5px' }}>
            <LabelProfile>Passport</LabelProfile>
            <FileNamepo>
              {passport?.originalname && !passport.hasOwnProperty("buffer") && (
                <Link
                  to={IMGURL.replace("/api/v1", "/image") + `${passport["filename"]}`}
                  style={{ marginLeft: "0px", alignSelf: "center" }}
                  target="_blank"
                >
                  <TopRightText1 style={{ marginLeft: "0px", alignSelf: "center" }}>
                    View File
                  </TopRightText1>
                </Link>
              )}                 
            </FileNamepo>
            <ChoosFile
              padding={'2%'}
              onChange={(e) =>
                files.push({ name: 'passport', file: handleFileChange(e) })
              }
              id={'1'}
              width={'100%'}
              inputmode={'file'}
            />
          </InputHolder>

          <ButtonComp
            style={{ marginTop: '4%', padding: '8px', marginBottom: '5%' }}
            fontSize={'12px'}
            width={isMobile ? '20%' : '60%'}
            title="Save Changes"
            onClick={() => {
              console.log(files.length);
              const form = new FormData();
              for (let i = 1; i < files.length; i++) {
                form.append('name', files[i].name);
                form.append('files', files[i].file);
                console.log(form, files);
              }
              console.log(form, files[1].file[0]);

              setLoading(true);
              UploadDocumentsapi(form).then((e) => {
                if (e.success === true) {
                  notifySuccess(e.message);

                  setRefresh(!refresh);

                  setLoading(false);
                } else {
                  notifyError(e.message);
                  setLoading(false);
                }
              });
            }}
          />
        </ContainerForm>
      )}
    </RightBorder>
  );
};
export default DocumentDetail;
