import React, { useEffect, useState } from "react";
import Container from "../../common/Input/useStyles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import AccordionWrapper from "./style";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Index = (props) => {
  const { FaqQuestion } = props;
  const [expanded, setExpanded] = useState(null);
  console.log(expanded, "expanded");
  const handleAccordation = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  useEffect(() => {
    console.log(console.log("FAQS", FaqQuestion));
  }, [FaqQuestion]);

  return (
    <Box>
      <Box
        sx={{
          py: 5,
          backgroundColor: "#FFF",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <Container>
          <Box>
            <Box>
              <Grid container>
                <Grid item xs={12} lg={5} sx={{ textAlign: "left" }}>
                  <h1 style={{ fontWeight: "bold" }}>
                    Frequently Asked
                    <br />
                    Questions
                  </h1>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <AccordionWrapper>
                    {FaqQuestion?.map((faq, i) => {
                      return (
                        <Accordion
                          expanded={expanded === `panel${i}`}
                          onChange={handleAccordation(`panel${i}`)}
                        >
                          <AccordionSummary
                            expandIcon={
                              expanded === `panel${i}` ? (
                                <RemoveIcon />
                              ) : (
                                <AddIcon />
                              )
                            }
                          >
                            {faq.title}
                          </AccordionSummary>
                          <AccordionDetails>{faq.description}</AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </AccordionWrapper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
export default Index;
