import React, { FC } from "react";
import { Container } from "../../Account_Screen/AccountScreenStyles";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import StudentProfileAll from "../../../components/StudentProfileDetails/StudentProfileAll";

const StudentProfile = () => {
  return <StudentProfileAll />;
};
export default StudentProfile;
