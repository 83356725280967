import * as React from "react";
import styled from "styled-components";
import "./ChooseFile.css";

interface Props {
  label?: string;
  placeholder?: string;
  icon?: any;
  onChange?: (e: any) => void;
  value?: any;
  width: any;
  height?: "56px";
  inputmode: any;
  id: any;
  accept?: any;
  padding?: any;
}

const _Input = styled.input`
  border: 1.5px solid #d6d6d6;
  font-size: 13px;
  border-radius: 15px;
  paddingtop: "8px";
  paddingbottom: "8px";
  height: "${(props) => props.slot}";
  width: ${(props) => props.slot};
  &:hover {
    border-color: #000;
  }
  &:focus {
    background-size: 100% 2px, 100% 1px;
    outline: 0 none;
    transition-duration: 0.3s;
    color: #525252;
  }
`;

const ChoosFile: React.FC<Props> = (props) => {
  return (
    <_Input
      slot={props.width}
      value={props.value}
      onChange={props.onChange}
      type={props.inputmode}
      width={props.width}
      id={props.id}
      style={{ padding: props.padding }}
      accept={props.accept}
    />
  );
};
export default ChoosFile;
