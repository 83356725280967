import Styled from 'styled-components';

const ButtonWrapper = Styled.div`
  .MuiButton-root{
    padding:12px 24px;
    margin-top:3px;
    font-weight:500;
    font-size:16px;
    background-color:  ${(props) => (props.backgroundColor ?  props.backgroundColor : '#C9F6EF' )};
    width:  ${(props) => (props.width ?  props.width : '100%' )};
    color:#000;
    border-radius:50px;
    border-radius: ${(props) => (props.borderRadius ?  props.borderRadius : '50px' )};
    text-decoration: ${(props) => (props.textUnderline ?  props.textUnderline : '' )};
    text-transform: capitalize;
    &.small-button{
      padding:2px 15px;
      margin-bottom:6px;
      font-size:12px;
    }
  }
`;

export { ButtonWrapper };
