import React from "react";
import StudentMentors from "../../components/StudentMentors/StudentMentors";
import { Box, Button, Grid } from "@mui/material";
import Container from "../../common/Input/useStyles";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        pb: 5,
      }}
    >
      <Box>
        <Box sx={{ width: "100%", m: "0 auto" }}>
          <Container>
            <Grid container>
              <Grid item md={9} xs={12} sx={{ textAlign: "left" }}>
                <h2 style={{ fontWeight: 700 }}>
                  Meet our international student Mentors
                </h2>
                <p>
                  Discover exceptional mentors and services curated just for
                  you. No hidden fees or commitments - just accelerated career
                  development.
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "DM Sans",
                    backgroundColor: "#C9F6EF",
                    color: "black",
                    borderRadius: "15px",
                    px: "40px",
                    py: "10px",
                    fontWeight: "500",
                    textTransform: "capitalize",
                    fontSize: "20px",
                    "&:hover": {
                      backgroundColor: "#C9F6EF",
                    },
                  }}
                  onClick={() => {
                    navigate("/findMentor");
                  }}
                >
                  View All
                </Button>
              </Grid>
            </Grid>
          </Container>
          <StudentMentors />
          <Box sx={{ display: { md: "none" }, px: "20px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                fontFamily: "DM Sans",
                backgroundColor: "#C9F6EF",
                color: "black",
                borderRadius: "15px",
                px: "30px",
                py: "15px",
                fontWeight: "bold",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#C9F6EF",
                },
              }}
              onClick={() => {
                navigate("/findMentor");
              }}
            >
              View All
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Index;
