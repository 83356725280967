
import styled from 'styled-components';
const AccordionWrapper = styled.div`
  .MuiPaper-root {
    box-shadow:none;
    font-family: 'DM Sans', sans-serif;
    .MuiAccordionSummary-content {
      font-size:18px;
  
    }
    .MuiAccordionDetails-root {
        text-align: left;
      }
  }
`;
export default AccordionWrapper;