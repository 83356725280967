import React, { useEffect, useState } from "react";
import { Routes, Route , useNavigation} from "react-router-dom";
import MentorForm from "./Screens/Mentor_Screen/MentorForm/MentorForm";
import About from "./Screens/About_Screen/About";
import Home from "./Screens/Home_Screen/Home";
import Mentee from "./Screens/Mentor_Screen/Mentee";
import MentorGrad from './Screens/Mentor_Screen/MentorGuard/index'
import { Context } from "./Context/ContextStates";
import Toast, { notifyError } from "./components/Toastifycom";
import StudentRoutes from "./Routes/StudentRoutes";
import AdminRoutes from "./Routes/AdminRoutes";
import Register from "./Screens/Account_Screen/Register/Register";
import Login from "./Screens/Account_Screen/Login/Login";
import ForgetEmail from "./Screens/Account_Screen/ForgetPassword/ForgetEmail";
import ForgetPassword from "./Screens/Account_Screen/ForgetPassword/ForgetPassword";
import ValidateLinkedIn from "./Screens/Account_Screen/Login/ValidateLinked";
import Main from "./Screens/Mentor_Screen/MentorChat/Main";
import  MentorRoutes  from "./Routes/MentorRoutes";
import MentorDashboardMain from "./Screens/Mentor_Screen/MentorDahboard/MentorDahboard.Main";
import AuthRoutes from "./Routes/AuthRoutes";
import {
  GetAllContries,
  GetCountryList,
  GetNationality,
  
} from "./api";
import AuthGuard from "./Screens/Account_Screen/auth-guard";
import { getErrorMsg, jwtDecode } from "./helper-functions";
import { userTypes } from "./Data/Data";

import {
  createTheme,
  
  ThemeProvider,
} from "@mui/material/styles";
import {  useStateContext } from "./Context/state";
import {
  
  useSubscription,
 
} from "@apollo/client";
import {

  NEW_MESSAGE,
} from "./graphql/subscriptions";
import {
  GET_PRIVATE_MSGS,
  GET_ALL_USERS,
  GET_GROUP_MSGS,
  GET_GLOBAL_GROUP,
  GET_GROUPS,
  GET_GLOBAL_MSGS,
} from "./graphql/queries";
import { useMediaQuery } from "@mui/material";
import MentorSearch from "./components/Student-Dashboard/MentorSearch/Index";
import MentorSearchPage from "./components/Student-Dashboard/MentorSearch/MentorSearch"
import MentorAppointmentBooking from "./components/Student-Dashboard/Mentor_Booking/MentorAppBook";
import AdminLogin from "./Screens/Admin_Screen/AdminLogin";
import University from "./Screens/Universty/University";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#5F61BE",
      light: "#5F61BE",
      dark: "#5F61BE",
    },
  },
});

function App() {
  
  const [priceRangeValue, setPriceRangeValue] = useState([0, 1000]);
  const [profilestep, setProfileStep] = useState("0");
  const [countryData, setCountryData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [nationality, setNationality] = useState([]);
 // Get the user's role from your authentication system or local storage
  const user: string = jwtDecode(
    localStorage.getItem("@storage_Key")
  )?.userType;

  const token = localStorage.getItem("@storage_Key");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const storedStep = localStorage.getItem("currentStep");
  const initialStep = storedStep !== null ? storedStep : "0";

  // Set the initial state
  const [value, setValue] = useState(initialStep);

  const { selectedChat } = useStateContext();

  useEffect(() => {
    localStorage.setItem("currentStep", value);
    GetAllContries().then((e) => {
      setCountryData(e);
    });
    GetCountryList().then((e) => {
      setCountryList(e);
    });
    GetNationality().then((e) => {
      setNationality(e);
    });
  }, [value]);

  const { error: subscriptionError } = useSubscription(NEW_MESSAGE, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      try {
        console.log("NEW_MESSAGE");
        if (selectedChat == null && isMobile) {
          const newMessage = subscriptionData.data.newMessage;
          let getMsgQuery: any, // Replace 'any' with actual types
            getMsgVariables: any, // Replace 'any' with actual types
            getMsgQueryName: string | null = null, // Replace 'string' with actual types
            getLastMsgQuery: any, // Replace 'any' with actual types
            getLastMsgQueryName: string | null = null, // Replace 'string' with actual types
            lastMsgTargetId: string | null = null; // Replace 'string' with actual types

          if (newMessage.type === "private") {
            const otherUserId = newMessage.participants.find(
              (p: string) => p !== user
            );

            getMsgQuery = GET_PRIVATE_MSGS;
            getMsgVariables = { userId: otherUserId };
            getMsgQueryName = "getPrivateMessages";
            getLastMsgQuery = GET_ALL_USERS;
            getLastMsgQueryName = "getAllUsers";
            lastMsgTargetId = otherUserId;
          } else if (newMessage.type === "group") {
            const groupConversationId = newMessage.message.conversationId;

            getMsgQuery = GET_GROUP_MSGS;
            getMsgVariables = { conversationId: groupConversationId };
            getMsgQueryName = "getGroupMessages";
            getLastMsgQuery = GET_GROUPS;
            getLastMsgQueryName = "getGroups";
            lastMsgTargetId = groupConversationId;
          } else if (newMessage.type === "public") {
            getMsgQuery = GET_GLOBAL_MSGS;
            getMsgVariables = null;
            getMsgQueryName = "getGlobalMessages";
            getLastMsgQuery = GET_GLOBAL_GROUP;
            getLastMsgQueryName = "getGlobalGroup";
          }

          const conversationCache = client.readQuery({
            query: getMsgQuery,
            variables: getMsgVariables,
          });

          if (conversationCache) {
            const updatedConvoCache = [
              ...(conversationCache[getMsgQueryName || ""] || []), // Provide an empty string as a default value for the key
              newMessage.message,
            ];

            const dataToUpdate = {
              [getMsgQueryName || ""]: updatedConvoCache,
            };

            if (getMsgQueryName) {
              dataToUpdate[getMsgQueryName] = updatedConvoCache;
            }

            client.writeQuery({
              query: getMsgQuery,
              variables: getMsgVariables,
              data: dataToUpdate,
            });
          }

          const lastMsgCache = client.readQuery({
            query: getLastMsgQuery,
          });

          if (lastMsgCache) {
            let updatedLastMsgCache: Record<string, any> = {};

            if (newMessage.type === "public") {
              updatedLastMsgCache = {
                ...(lastMsgCache[getLastMsgQueryName || ""] || {}),
                latestMessage: newMessage.message,
              };
            } else {
              updatedLastMsgCache = (
                lastMsgCache[getLastMsgQueryName || ""] || []
              ).map((l: any) =>
                l.id === lastMsgTargetId
                  ? { ...l, latestMessage: newMessage.message }
                  : l
              );
            }

            const dataToUpdate: Record<string, any> = {
              [getLastMsgQueryName || ""]: updatedLastMsgCache,
            };

            if (getLastMsgQueryName) {
              dataToUpdate[getLastMsgQueryName] = updatedLastMsgCache;
            }

            client.writeQuery({
              query: getLastMsgQuery,
              data: dataToUpdate,
            });
          }
        }
      } catch (err) {
        // Handle the error here
        if (err) {
          notifyError(getErrorMsg(err));
        }
      }
    },
  });

 


  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Context.Provider
          value={{
            priceRangeValue,
            setPriceRangeValue,
            value,
            setValue,
            profilestep,
            setProfileStep,
            countryData,
            setCountryData,
            countryList,
            setCountryList,
            nationality,
            setNationality,
          }}
        >
          <Routes>
            <Route
              path="/*"
              element={
                <AuthGuard>
                  {user === userTypes.student &&  <StudentRoutes/>}
                  {user === userTypes.mentor && <MentorRoutes/>}
                  {user === userTypes.admin && <AdminRoutes/>}
                  
                </AuthGuard>
              }
            />
            <Route path="/main" element={<Main />} />
            {/* <AuthRoutes/> */}
            
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-email" element={<ForgetEmail />} />
            <Route path="/reset-Password/:id" element={<ForgetPassword />} />
            <Route path="/validate-user" element={<ValidateLinkedIn />}  />
            <Route path="/findMentor23" element={<MentorSearchPage />} />
            <Route path="/findMentor" element={<MentorSearch />} />
            {/* <Route path="/mentor/dashboard" element={<MentorDashboardMain />} /> */}
     
            <Route path="/" element={<Home />} />
            <Route path="/mentor" element={<MentorGrad />} />
            <Route path="/mentee" element={<Mentee />} />
            <Route path="/university" element={<University />} />
            <Route path="/about" element={<About />} />
            <Route path="/bookAppointment" element={<MentorAppointmentBooking />} />
            <Route path="/mentor/signup" element={<MentorForm />} />
            <Route path="/admin-login" element={<AdminLogin />} />
          </Routes>
        </Context.Provider>
        <Toast />
        {/* {token ? <MessagesConfig /> : ""} */}
      </ThemeProvider>
    </div>
  );
}

export default App;
