import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import HomeBanner from "../../../components/HomeBanner";
import Oppurtunities from "../../../components/oppurtunities";
import FAQQuestion from "../../../components/FaqSection";
import Footer from "../../../components/Footer";
import CreateAccount from "../../../components/createAccount";
import AspiringStudent from "../../../components/AspiringStudents/AspiringStudents";
import BecomeMentor from "../../../components/BecomeAMentor/BecomeAMentor";
import section_2_1 from "../../../Assets/Images/illustrations/mentor_page_section_2_1.png";
import HomeBannerImg from "../../../Assets/Images/mentor-illustration.svg";
import section_2_2 from "../../../Assets/Images/illustrations/mentor_page_section_2_2.png";
import section_2_3 from "../../../Assets/Images/illustrations/mentor_page_section_2_3.png";
import styled from 'styled-components';

const StyledTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  min-height: 60px;
`;

const StyledContent = styled.p`
  font-size: 18px;
  font-weight: 300;
`;

const Index = () => {
  const BecomeMentorData = [
    {
      title: (
        <StyledTitle as="h3">Help aspiring international students</StyledTitle>
      ),
      content:
      (
        <StyledContent as="p">By providing personalized advice, mentors can help demystify the application process, from choosing the right program and institution to understanding the nuances of scholarship applications and visa processes.</StyledContent>
      ),
      imageSrc: section_2_1,
    },
    {
      title: (
        <StyledTitle as="h3">Share your experience and expertise</StyledTitle>
      ),
      content:
      (
        <StyledContent as="p">Mentors have the unique opportunity to make a tangible difference sharing their own journey, insights, and strategies that led to their successful enrolment in international institutions.</StyledContent>
      ),
      imageSrc: section_2_2,
    },
    {
      title: (
        <StyledTitle as="h3">Earn an income</StyledTitle>
      ),
      content:
      (  
        <StyledContent as="p">Mentors monetize their knowledge and experience by offering services such as personalized consultation sessions, application review services, document review, language proficiency help, and application submission</StyledContent>
      ),
      imageSrc: section_2_3,
    },
  ];
  
  const faqQuestion = [
    {
      title: "What is MentorGrad?",
      description:
        "MentorGrad is a subscription-based mentoring platform designed for aspiring international students. Our platform connects these students with mentors who are current international students, offering guidance, support, and insights into the study abroad experience",
    },
    {
      title: "⁠How can I become a mentor on MentorGrad?",
      description:
        "To become a mentor, you need to be a current international student with a good understanding of your university and the challenges faced by international students. Simply apply on our website, complete the verification process, and once approved, you can start mentoring!",
    },
    {
      title: " ⁠ ⁠How do I charge for my mentor activities?",
      description:
        "You can set your own rates for one-on-one sessions, monthly mentoring plans, group sessions, or specific services like application reviews. MentorGrad facilitates the payment process to ensure its smooth and secure for both parties.",
    },
    {
      title: "⁠Can universities access student profiles directly?",
      description:
        "Yes, universities can access student profiles that match their program offerings. These profiles include comprehensive information to help universities understand student needs, preferences, and readiness.",
    },
    {
      title: "⁠What kind of support will I provide as a mentor?",
      description:
        "Mentors help with university selection, application processes, direct admission in to your university, preparation for life as an international student, cultural adaptation, and any specific challenges or queries that aspiring students might have",
    },
    {
      title: "How does MentorGrad ensure quality mentorship?",
      description:
        "We have a rating and review system where mentees can rate their experience. Regular training and resources are provided to mentors to enhance their mentoring skills. Plus, we monitor mentor performance to maintain high standards",
    },
    {
      title:
        "⁠⁠Are there any criteria for mentees to join the platform?",
      description:
        "Mentees should be aspiring international students looking for guidance. They need to register and subscribe to access mentorship services. We ensure a diverse and inclusive environment for all students",
    },
    {
      title: "⁠⁠How does the referral reward system work?",
      description:
        "If your mentee decides to enrol in your university, you can earn a referral reward. This reward is processed once the enrolment is confirmed, offering an additional incentive for mentors to provide valuable insights and advice.",
    },
    {
      title: "⁠⁠⁠Can I offer group mentoring sessions?",
      description:
        "Yes, you can organize group sessions, which is a great way to reach more students at once. These can be themed around specific topics like visa processes, accommodation, or cultural adaptation",
    },
    {
      title: "How can I manage my mentor activities on the platform?",
      description:
        "MentorGrad provides a user-friendly dashboard where you can manage your schedule, set availability, track earnings, receive feedback, and connect with new mentees. Our platform is designed to make your mentoring experience as efficient and rewarding as possible.",
    },
  ];

  return (
    <>
      <Header />
      <HomeBanner
        subtitle=""
        title1="Welcome to"
        title2="MentorGrad!"
        detail="Connect with experienced international student mentors tailored to your study destination, program preferences, and essential needs for scholarships, visas, and housing. Start your seamless educational journey today!"
        HomeBanner={HomeBannerImg}
        link="/mentor/signup"
      />
      <BecomeMentor
        title1="Why Become a "
        title2="Mentor?"
        BecomeMentorData={BecomeMentorData}
      />
      <AspiringStudent />
      <Oppurtunities />
      <FAQQuestion FaqQuestion={faqQuestion} />
      <CreateAccount
        title="Share your wisdom, guide future talent."
        buttonText="Join MentorGrad"
        link="/mentor/signup"
      />
      <Footer />
    </>
  );
};
export default Index;
