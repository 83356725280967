import React from "react";
import { Routes, Route } from "react-router-dom";
import MentorDashboardMain from "../Screens/Mentor_Screen/MentorDahboard/MentorDahboard.Main";
import MentorProfile from "../Screens/Mentor_Screen/MentorProfile/MentorProfile";
import Main from "../Screens/Mentor_Screen/MentorChat/Main";


const MentorRoutes = () => {
  return (
    <Routes>
      <Route path="/mentor/dashboard" element={<MentorDashboardMain />} />
      <Route path="/mentor/profile" element={<MentorProfile />} />
      <Route path="/main" element={<Main />} />
      {/* Add more routes for mentor features */}
    </Routes>
  );
};

export default MentorRoutes;
