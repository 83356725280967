import styled from "styled-components";
const Container = styled.section`
  padding: 15px 120px;
  text-align: left;

  @media (max-width: 900px) {
    padding: 15px;
  }

  @font-face {
    font-family: "Gilroy Bold";
    src: url("./Assets/font/Gilroy-SemiBold.ttf") format("woff2");
    /* Add other font styles (font-weight, font-style) if applicable */
  }
  @font-face {
    font-family: "Gilroy";
    src: url("./Assets/font/Gilroy-Regular.ttf") format("woff2");
    /* Add other font styles (font-weight, font-style) if applicable */
  }
`;
export default Container;
