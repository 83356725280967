import React from "react";
import Container from "../../common/Input/useStyles";
import MentorCanHelpWrapper from "./style";
import { Box, Grid } from "@mui/material";

const MentorCanHelp = ({ MentorCanHelpData }) => {
  return (
    <Box sx={{ py: { xs: 5, md: 10 }, backgroundColor: "#7476D1" }}>
      <Grid>
        <MentorCanHelpWrapper>
          <Container>
            <Box
              component={"h2"}
              textAlign="center"
              color="#fff"
              marginBottom="70px"
            >
              How MentorGrad Can Help You
            </Box>
            <Grid container columnSpacing={3}>
              {MentorCanHelpData.map((MentorCanHelp, index) => {
                const { heading, content, iconSrc } = MentorCanHelp;
                return (
                  <Grid item md={4} key={index}>
                    <Box
                      className="help-box"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box display={"flex"} gap={2} alignItems={"center"}>
                        <Box>{iconSrc}</Box>
                        <h3>{heading}</h3>
                      </Box>
                      <Box mt={"10px"}>
                        <p>{content}</p>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </MentorCanHelpWrapper>
      </Grid>
    </Box>
  );
};

export default MentorCanHelp;
