import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import "swiper/css";
import "swiper/css/pagination";
import "./ResponsiveSwiper.css";

const ResponsiveSwiper = ({ slides, className = "", swiperProps = {} }) => {
  return (
    <Swiper
      className={`my-responsive-swiper ${className}`}
      modules={[Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      loop
      autoplay={{
        delay: 2500,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      }}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      }}
      {...swiperProps}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ResponsiveSwiper;
