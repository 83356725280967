import React, { useEffect, useState } from "react";
import Container from "../../../common/Input/useStyles";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import JohnDoe from "../../../Assets/Images/JohnDoeHome.png";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import HeadsetMicRoundedIcon from "@mui/icons-material/HeadsetMicRounded";
import { useSearchParams } from "react-router-dom";
import { notifyError } from "../../Toastifycom";

import { getReviews } from "../../../api";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const BookingBanner = ({
  name,
  location,
  handlePayment,
  languages,
  comments,
  id,
  profileImg,
  rate,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(1);
  const [isSeeMoreOn, setIsSeeMoreOn] = useState(false);
  const [reviews, setReviews] = useState();
  let [searchParams] = useSearchParams();
  const [subscription, setSubscription] = useState(false);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async () => {
    try {
      getReviews({
        mentorId: searchParams.get("id"),
      }).then((e) => {
        setReviews(e);
      });
    } catch (error) {}
  };

  const benefits = [
    {
      id: 1,
      detail: "60 min/call",
      icon: <LocalPhoneRoundedIcon sx={{ color: "#A2D1CA" }} />,
    },
    {
      id: 2,
      detail: "Unlimited Q&A via chat",
      icon: <ChatRoundedIcon sx={{ color: "#A2D1CA" }} />,
    },
    {
      id: 3,
      detail: "Expect responses in 24 hours",
      icon: <AccessTimeFilledRoundedIcon sx={{ color: "#A2D1CA" }} />,
    },
    {
      id: 4,
      detail: "Hands-on support",
      icon: <HeadsetMicRoundedIcon sx={{ color: "#A2D1CA" }} />,
    },
  ];

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handlesendMessage = () => {
    navigate(`/student/dashboard?tab=3`);
  };


  return (
    <Box sx={{ background: "#D1D2F4", py: "30px" }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={() => {
                    navigate("/findMentor");
                  }}
                >
                  <ArrowBackIcon fontSize="large" color="black" />
                </IconButton>
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "Gilroy", fontSize: "24px" }}
                >
                  View all Mentors
                </Typography>{" "}
              </Box>

              <Box sx={{ mt: "20px" }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={5}>
                    <Box
                      sx={{
                        borderRadius: "15px",
                        border: "1px solid #04112C",
                        height: "100%",
                      }}
                    >
                      <img
                        src={
                          profileImg?.attachments?.[0]?.attachmentPath ||
                          "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg"
                        }
                        alt=""
                        style={{
                          borderRadius: "15px",
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            fontFamily: "Gilroy",
                            fontWeight: "bold",
                            fontSize: "2.65rem",
                          }}
                        >
                          {name}
                        </Typography>
                        <VerifiedIcon sx={{ color: "#27AA5E" }} />
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <StarRoundedIcon sx={{ color: "#FFA340" }} />
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "DM Sans" }}
                        >
                          {reviews && reviews.length > 0 ? (
                            <>
                              {reviews[0]?.rating}.0 ({" "}
                              <span style={{ color: "#FFA340" }}>
                                {reviews.length} reviews
                              </span>{" "}
                              )
                            </>
                          ) : (
                            "No reviews"
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Gilroy", fontSize: "1.1rem" }}
                        >
                          Language:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "DM Sans", fontSize: "1.1rem" }}
                        >
                          {languages && languages.length > 0
                            ? languages.map((language, index) => (
                                <React.Fragment key={language._id}>
                                  {index > 0 && ","} {language.title}
                                </React.Fragment>
                              ))
                            : "No languages available"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "33px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Gilroy", fontSize: "1.1rem" }}
                        >
                          Location:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "DM Sans", fontSize: "1.1rem" }}
                        >
                          {location}
                        </Typography>
                      </Box>
                      <Box sx={{ width: { md: "50%" }, mt: "15px" }}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={handlesendMessage}
                          sx={{
                            color: "#000",
                            background: "#fff",
                            borderRadius: "15px",
                            py: "12px",
                            textTransform: "capitalize",
                            px: "40px",
                            fontFamily: "Gilroy",
                            "&:hover": {
                              background: "#fff",
                            },
                          }}
                        >
                          Send Message
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                borderRadius: "10px",
                background: "#fff",
                mt: { xs: "40px", md: "0px" },
              }}
            >
              <Box
                sx={{ borderBottom: 1, borderColor: "#C9F6EF", width: "100%" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Mentorship Plans"
                    {...a11yProps(0)}
                    sx={{
                      color: "#ACB6C3",
                    }}
                  />
                  <Tab
                    label="Session"
                    {...a11yProps(1)}
                    sx={{
                      color: "#ACB6C3",
                    }}
                  />
                </Tabs>
              </Box>
              <Box sx={{ px: "20px" }}>
                <CustomTabPanel value={value} index={0}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontFamily: "Gilroy",
                      }}
                    >
                      ${rate}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Gilroy",
                        mb: "-10px",
                      }}
                    >
                      /Hour
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "1.1rem",
                        mb: "15px",
                      }}
                    >
                      Discover personalized guidance and expertise through our
                      verified Mentors.
                    </Typography>
                  </Box>
                  <Box>
                    {benefits.map((benfit) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          mb: "10px",
                        }}
                        key={benfit.id}
                      >
                        {benfit.icon}
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: "DM Sans",
                            fontSize: "1.1rem",
                          }}
                        >
                          {benfit.detail}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Box sx={{ py: "20px" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        color: "#000",
                        background: "#C9F6EF",
                        borderRadius: "15px",
                        py: "12px",
                        textTransform: "capitalize",
                        px: "40px",
                        fontFamily: "Gilroy",
                        "&:hover": {
                          background: "#C9F6EF",
                        },
                        fontSize: "18px",
                      }}
                      onClick={handlePayment}
                    >
                      Book Now
                    </Button>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontFamily: "Gilroy",
                      }}
                    >
                      ${rate}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Gilroy",
                        mb: "-10px",
                      }}
                    >
                      /Hour
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "1.1rem",
                        mb: "15px",
                      }}
                    >
                      Discover personalized guidance and expertise through our
                      verified Mentors.
                    </Typography>
                  </Box>
                  <Box>
                    {benefits.map((benfit) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          mb: "10px",
                        }}
                        key={benfit.id}
                      >
                        {benfit.icon}
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: "DM Sans",
                            fontSize: "1.1rem",
                          }}
                        >
                          {benfit.detail}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Box sx={{ py: "20px" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        color: "#000",
                        background: "#C9F6EF",
                        borderRadius: "15px",
                        py: "12px",
                        textTransform: "capitalize",
                        px: "40px",
                        fontFamily: "Gilroy",
                        "&:hover": {
                          background: "#C9F6EF",
                        },
                        fontSize: "18px",
                      }}
                      onClick={handlePayment}
                    >
                      Book Now
                    </Button>
                  </Box>
                </CustomTabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BookingBanner;
