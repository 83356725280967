import { Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetCountryList, findMentors, getMentorForBooking } from "../../../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notifyError } from "../../Toastifycom";

// const skills = [
//   "Data Science",
//   "Data Analyst",
//   "Digital Marketing",
//   "Web Development",
//   "Google Ads",
// ];
// const Topics = [
//   "Change Careers",
//   "Pass Interviews",
//   "Grow your Career",
//   "Learn a new skill",
//   "Change",
// ];
// const Services = [
//   "Change Careers",
//   "Pass Interviews",
//   "Grow your Career",
//   "Learn a new skill",
//   "Change",
//   "Build a product",
// ];

type Mentor = {
  application: {
    _id: string;
    userName: string;
    firstName: string;
    lastName: string;
    countryOfResidence: string;
    hourlyRate: number;
    skills: any;
    services: any;
    topics: any;
    attachments: Array<{
      attachmentPath: string;
      attachmentURL: string;
      name: string;
    }>;
    mentoringarea: Array<{
      title: string;
      _id: string;
    }>;
  };
  next30Days: Array<{
    date: string;
    mentorAvailability: any;
    weekDay: string;
  }>;
};

const MentorAttributes = () => {
  const [mentor, setMentor] = useState<Mentor | null>(null);
  const [skills, setSkills] = useState([]);
  const [Topics, setTopics] = useState([]);
  const [Services, setServives] = useState([]);


  let [searchParams] = useSearchParams();

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      getMentorForBooking({
        mentorId: searchParams.get("id"),
      }).then((e) => {
        setMentor(e);
        setSkills(e.application.skills)
        setTopics(e.application.topics)
        setServives(e.application.services)
      });
    } catch (error) {
      notifyError(error);
    }
  };
  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          fontFamily: "Gilroy",
          fontWeight: "bold",
          mb: "10px",
        }}
      >
        Skills:
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {skills && skills.length > 0 ? (
          skills.map((skill, index) => (
            <Typography
              key={index}
              sx={{
                borderRadius: "15px",
                background: "#fff",
                width: "fit-content",
                px: "15px",
                py: "10px",
                fontFamily: "DM Sans",
              }}
            >
              {skill}
            </Typography>
          ))
        ) : (
          <Typography>No info available</Typography>
        )}
      </Box>

      <Typography
        variant="h5"
        sx={{
          fontFamily: "Gilroy",
          fontWeight: "bold",
          mt: "40px",
          mb: "10px",
        }}
      >
        Topics:
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {Topics && Topics.length > 0 ? (
          Topics.map((topic, index) => (
            <Typography
              key={index}
              sx={{
                borderRadius: "15px",
                background: "#fff",
                width: "fit-content",
                px: "15px",
                py: "10px",
              }}
            >
              {topic}
            </Typography>
          ))
        ) : (
          <Typography>No info available</Typography>
        )}
      </Box>
      <Typography
        variant="h5"
        sx={{
          fontFamily: "Gilroy",
          fontWeight: "bold",
          mt: "40px",
          mb: "10px",
        }}
      >
        Services:
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {Services && Services.length > 0 ? (
        Services.map((service, index) => (
          <Typography
            key={index}
            sx={{
              borderRadius: "15px",
              background: "#fff",
              width: "fit-content",
              px: "15px",
              py: "10px",
            }}
          >
            {service}
          </Typography>
        ))
      ) : (
        <Typography>No info available</Typography>
      )}
      </Box>
    </Box>
  );
};

export default MentorAttributes;
