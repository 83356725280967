import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Theme,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ReviewsIcon from "@mui/icons-material/Reviews";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoneyIcon from "@mui/icons-material/Money";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from "react-router-dom";
import {
  RightContainerDash,
  RightBorderDashboard,
  ContainerDashboard,
} from "../../../components/StudentDashboard/StudentDashboardStyles";
import Spinner from "../../../components/Spinner";
import { Container } from "../../Account_Screen/AccountScreenStyles";
import { ContainerDa } from "../../../components/StudentProfileDetails/StudentProfileStyles";
import {
  findMentors,
  getMentorBySuggessionCountry,
  getMentorBySuggessionLanguage,
  getMentorBySuggessionUniversity,
} from "../../../api";
import { notifyError } from "../../../components/Toastifycom";
import EmojiStar from "../../../Assets/Images/medium_star.png";

type Mentor = {
  _id: string;
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  countryOfResidence: string;
  hourlyRate: number;
  attachments: Array<{
    attachmentPath: string;
    attachmentURL: string;
    name: string;
  }>;
};

type MentorList = Mentor[];

const MentorSearch = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isMobile2 = useMediaQuery("(max-width: 1200px)");
  const [showFilter, setshowFilter] = useState(false);
  const [filters, setFilters] = useState({
    country: "",
    gender: "",
    course: "",
    location: "",
  });
  const [countries, setCountries] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [mentorList, setMentorList] = useState<MentorList>([]);
  const [mentorListCountry, setMentorListCountry] = useState<MentorList>([]);
  const [mentorListLanguage, setMentorListLanguage] = useState<MentorList>([]);
  const [mentorListUniversity, setMentorListUniversity] = useState<MentorList>(
    []
  );

  const isBelowLarge = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  useEffect(() => {
    // getCountryMentors();
    // getLanguageMentors();
    // getUniversityMentors();

    getMentors();
  }, []);

  const getMentors = () => {
    setshowFilter(false);
    setLoading(true);
    let payload = {
      countryOfResidence: filters.country,
      gender: filters.gender,
      mentoringarea: selectedOptions,
    };
    try {
      findMentors(payload)
        .then((e) => {
          console.log("HERE IS THE RESPONSE");
          setMentorListCountry(e);
          setMentorListLanguage(e);
          setMentorListUniversity(e);
          console.log(e);
          setLoading(false);
        })
        .catch((err) => {
          notifyError(err);
        });
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  };

  const getCountryMentors = async () => {
    await getMentorBySuggessionCountry()
      .then((res) => {
        setMentorListCountry(res);
      })
      .catch((err) => {});
  };

  const getLanguageMentors = async () => {
    await getMentorBySuggessionLanguage()
      .then((res) => {
        setMentorListLanguage(res);
      })
      .catch((err) => {});
  };

  const getUniversityMentors = async () => {
    await getMentorBySuggessionUniversity()
      .then((res) => {
        setMentorListUniversity(res);
      })
      .catch((err) => {});
  };

  return (
    <>
      <Container>
        <ContainerDashboard>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{ mt: 1, mb: 2.5 }}
              textAlign={"left"}
              fontWeight={700}
              fontSize={isMobile ? "small" : "large"}
              noWrap
            >
              Smart Matches
            </Typography>
            {isMobile2 && (
              <IconButton
                onClick={() => setshowFilter(true)}
                sx={{ p: 0, m: 0, mb: 1 }}
              >
                <FilterAltIcon fontSize="small" sx={{ color: "#5F61BE" }} />
              </IconButton>
            )}
          </Stack>
          <RightContainerDash>
            <Grid item sm={12} lg={12}>
              <Grid container>
                <Grid item xs={12}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <Grid
                        container
                        gap={3}
                        sx={{
                          padding: "10px",
                          borderRadius: "15px",
                          border: "1px solid #D6D6D6",
                          my: 2,
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              // mentor?.user[0]?.attachments?.length > 0
                              //   ? mentor?.user[0]?.attachments[0]
                              //       ?.attachmentPath
                              //   :
                              "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg"
                            }
                            style={{
                              width: "100%",
                              borderRadius: "15px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Grid container>
                            <Grid item xs={12} md={6}>
                              <Grid container gap={0.5}>
                                <Grid
                                  item
                                  xs={12}
                                  lg={5.75}
                                  sx={{
                                    textAlign: isBelowLarge ? "center" : "left",
                                  }}
                                >
                                  <h5>
                                    {" "}
                                    <h6>Waleed Bin Tariq</h6>
                                  </h5>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  lg={5.75}
                                  sx={{
                                    textAlign: isBelowLarge ? "center" : "left",
                                  }}
                                >
                                  Pakistan
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={EmojiStar}
                                alt="imge"
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  fontWeight: "500",
                                }}
                              >
                                4.9 (1200 reviews)
                              </span>
                            </Grid>
                          </Grid>
                          {/* {`${mentor?.universityName}`} */}
                          <h6
                            style={{ textAlign: "left", marginBlock: "15px" }}
                          >
                            University of Punjab
                          </h6>
                          <Grid container sx={{ textAlign: "left" }} gap={1}>
                            {/* {mentor?.mentoringarea.map( */}
                            {/* (field: { title?: string } | undefined) => ( */}

                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Newer Title</span>
                            </Grid>

                            {/* ) */}
                            {/* )} */}
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "left",
                                marginBlock: "15px",
                              }}
                            >
                              • PhD in Experimental Physics. • Hands-on
                              experience in data extraction and analysis. •
                              Experience in IT and on Machine Learning,
                              Artificial Intelligence, Statistical Modeling,
                              Data Analysis, Predictive Analysis, Data
                              Manipulation.
                            </p>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <Button
                                sx={{
                                  backgroundColor: "#C9F6EF",
                                  borderRadius: "15px",
                                  color: "black",
                                  fontFamily: "Gilroy",
                                  py: "10px",
                                  px: "20px",
                                  textTransform: "capitalize",
                                }}
                                // onClick={() => handleBookAppointment(mentor)}
                              >
                                Get Appointment
                              </Button>
                            </Box>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        columnSpacing={2}
                        sx={{
                          padding: "10px",
                          borderRadius: "15px",
                          border: "1px solid #D6D6D6",
                          my: 2,
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              // mentor?.user[0]?.attachments?.length > 0
                              //   ? mentor?.user[0]?.attachments[0]
                              //       ?.attachmentPath
                              //   :
                              "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg"
                            }
                            style={{
                              width: "100%",
                              borderRadius: "15px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Grid container>
                            <Grid item xs={12} md={5}>
                              <Grid container gap={0.5}>
                                <Grid
                                  item
                                  xs={12}
                                  lg={5.75}
                                  sx={{
                                    textAlign: isBelowLarge ? "center" : "left",
                                  }}
                                >
                                  <h5>
                                    {" "}
                                    <h6>Waleed Bin Tariq</h6>
                                  </h5>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  lg={3}
                                  sx={{
                                    textAlign: isBelowLarge ? "center" : "left",
                                  }}
                                >
                                  Pakistan
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={EmojiStar}
                                alt="imge"
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  fontWeight: "500",
                                }}
                              >
                                4.9 (1200 reviews)
                              </span>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box
                                sx={{
                                  backgroundColor: "#5F61BE",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "white",
                                  borderRadius: "15px",
                                  marginBlock: isBelowLarge ? "15px" : 0,
                                  padding: "8px 2px",
                                  fontSize: "12px",
                                }}
                              >
                                Matched by University
                              </Box>
                            </Grid>
                          </Grid>
                          {/* {`${mentor?.universityName}`} */}
                          <h6
                            style={{ textAlign: "left", marginBlock: "15px" }}
                          >
                            University of Punjab
                          </h6>
                          <Grid container sx={{ textAlign: "left" }} gap={1}>
                            {/* {mentor?.mentoringarea.map( */}
                            {/* (field: { title?: string } | undefined) => ( */}
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Newer Title</span>
                            </Grid>

                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            {/* ) */}
                            {/* )} */}
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "left",
                                marginBlock: "15px",
                              }}
                            >
                              • PhD in Experimental Physics. • Hands-on
                              experience in data extraction and analysis. •
                              Experience in IT and on Machine Learning,
                              Artificial Intelligence, Statistical Modeling,
                              Data Analysis, Predictive Analysis, Data
                              Manipulation.
                            </p>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <Button
                                sx={{
                                  backgroundColor: "#C9F6EF",
                                  borderRadius: "15px",
                                  color: "black",
                                  fontFamily: "Gilroy",
                                  py: "10px",
                                  px: "20px",
                                  textTransform: "capitalize",
                                }}
                                // onClick={() => handleBookAppointment(mentor)}
                              >
                                Get Appointment
                              </Button>
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        columnSpacing={2}
                        sx={{
                          padding: "10px",
                          borderRadius: "15px",
                          border: "1px solid #D6D6D6",
                          my: 2,
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              // mentor?.user[0]?.attachments?.length > 0
                              //   ? mentor?.user[0]?.attachments[0]
                              //       ?.attachmentPath
                              //   :
                              "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg"
                            }
                            style={{
                              width: "100%",
                              borderRadius: "15px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Grid container>
                            <Grid item xs={12} md={5}>
                              <Grid container gap={0.5}>
                                <Grid
                                  item
                                  xs={12}
                                  lg={5.75}
                                  sx={{
                                    textAlign: isBelowLarge ? "center" : "left",
                                  }}
                                >
                                  <h5>
                                    {" "}
                                    <h6>Waleed Bin Tariq</h6>
                                  </h5>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  lg={3}
                                  sx={{
                                    textAlign: isBelowLarge ? "center" : "left",
                                  }}
                                >
                                  Pakistan
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={EmojiStar}
                                alt="imge"
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  fontWeight: "500",
                                }}
                              >
                                4.9 (1200 reviews)
                              </span>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box
                                sx={{
                                  backgroundColor: "#5F61BE",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "white",
                                  borderRadius: "15px",
                                  marginBlock: isBelowLarge ? "15px" : 0,
                                  padding: "8px 2px",
                                  fontSize: "12px",
                                }}
                              >
                                Matched by Language
                              </Box>
                            </Grid>
                          </Grid>
                          {/* {`${mentor?.universityName}`} */}
                          <h6
                            style={{ textAlign: "left", marginBlock: "15px" }}
                          >
                            University of Punjab
                          </h6>
                          <Grid container sx={{ textAlign: "left" }} gap={1}>
                            {/* {mentor?.mentoringarea.map( */}
                            {/* (field: { title?: string } | undefined) => ( */}
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Newer Title</span>
                            </Grid>

                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            {/* ) */}
                            {/* )} */}
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "left",
                                marginBlock: "15px",
                              }}
                            >
                              • PhD in Experimental Physics. • Hands-on
                              experience in data extraction and analysis. •
                              Experience in IT and on Machine Learning,
                              Artificial Intelligence, Statistical Modeling,
                              Data Analysis, Predictive Analysis, Data
                              Manipulation.
                            </p>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <Button
                                sx={{
                                  backgroundColor: "#C9F6EF",
                                  borderRadius: "15px",
                                  color: "black",
                                  fontFamily: "Gilroy",
                                  py: "10px",
                                  px: "20px",
                                  textTransform: "capitalize",
                                }}
                                // onClick={() => handleBookAppointment(mentor)}
                              >
                                Get Appointment
                              </Button>
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        columnSpacing={2}
                        sx={{
                          padding: "10px",
                          borderRadius: "15px",
                          border: "1px solid #D6D6D6",
                          my: 2,
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              // mentor?.user[0]?.attachments?.length > 0
                              //   ? mentor?.user[0]?.attachments[0]
                              //       ?.attachmentPath
                              //   :
                              "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg"
                            }
                            style={{
                              width: "100%",
                              borderRadius: "15px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Grid container>
                            <Grid item xs={12} md={5}>
                              <Grid container gap={0.5}>
                                <Grid
                                  item
                                  xs={12}
                                  lg={5.75}
                                  sx={{
                                    textAlign: isBelowLarge ? "center" : "left",
                                  }}
                                >
                                  <h5>
                                    {" "}
                                    <h6>Waleed Bin Tariq</h6>
                                  </h5>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  lg={3}
                                  sx={{
                                    textAlign: isBelowLarge ? "center" : "left",
                                  }}
                                >
                                  Pakistan
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={EmojiStar}
                                alt="imge"
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  fontWeight: "500",
                                }}
                              >
                                4.9 (1200 reviews)
                              </span>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box
                                sx={{
                                  backgroundColor: "#5F61BE",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "white",
                                  borderRadius: "15px",
                                  marginBlock: isBelowLarge ? "15px" : 0,
                                  padding: "8px 2px",
                                  fontSize: "12px",
                                }}
                              >
                                Matched by Country
                              </Box>
                            </Grid>
                          </Grid>
                          {/* {`${mentor?.universityName}`} */}
                          <h6
                            style={{ textAlign: "left", marginBlock: "15px" }}
                          >
                            University of Punjab
                          </h6>
                          <Grid container sx={{ textAlign: "left" }} gap={1}>
                            {/* {mentor?.mentoringarea.map( */}
                            {/* (field: { title?: string } | undefined) => ( */}
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Newer Title</span>
                            </Grid>

                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            <Grid
                              item
                              // key={field?.title}
                              style={{
                                backgroundColor: "#F2F7F2",
                                fontWeight: 400,
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              {/* {field?.title} */}
                              <span>Title</span>
                            </Grid>
                            {/* ) */}
                            {/* )} */}
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "left",
                                marginBlock: "15px",
                              }}
                            >
                              • PhD in Experimental Physics. • Hands-on
                              experience in data extraction and analysis. •
                              Experience in IT and on Machine Learning,
                              Artificial Intelligence, Statistical Modeling,
                              Data Analysis, Predictive Analysis, Data
                              Manipulation.
                            </p>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <Button
                                sx={{
                                  backgroundColor: "#C9F6EF",
                                  borderRadius: "15px",
                                  color: "black",
                                  fontFamily: "Gilroy",
                                  py: "10px",
                                  px: "20px",
                                  textTransform: "capitalize",
                                }}
                                // onClick={() => handleBookAppointment(mentor)}
                              >
                                Get Appointment
                              </Button>
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                    // mentorListCountry?.map((mentor, index) => (
                    //   <></>
                    //   // <Box
                    //   //   sx={{
                    //   //     margin: isMobile ? "0px 15px" : "",
                    //   //   }}
                    //   //   key={index}
                    //   // >
                    //   //   <RightBorderDashboard
                    //   //     style={{
                    //   //       border: "1px solid #D6D6D6",
                    //   //       backgroundColor: "#FFF",
                    //   //     }}
                    //   //   >
                    //   //     <Stack
                    //   //       justifyContent={"space-between"}
                    //   //       flexDirection={isMobile ? "column" : "row"}
                    //   //       gap={isMobile ? 3 : 0}
                    //   //       p={isMobile ? 2.5 : 0}
                    //   //     >
                    //   //       <Stack
                    //   //         flexDirection={"row"}
                    //   //         gap={2}
                    //   //         width={isMobile ? "100%" : "70%"}
                    //   //       >
                    //   //         <img
                    //   //           style={
                    //   //             isMobile
                    //   //               ? { width: "35%", borderRadius: "15px" }
                    //   //               : { width: "20%", borderRadius: "15px" }
                    //   //           }
                    //   //           src={
                    //   //             mentor?.attachments?.length > 0
                    //   //               ? mentor?.attachments[0]?.attachmentPath
                    //   //               : "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg"
                    //   //           }
                    //   //         />
                    //   //         <Stack flexDirection={"column"}>
                    //   //           <Typography
                    //   //             textAlign={"left"}
                    //   //             noWrap
                    //   //             sx={{ color: "#5F61BE" }}
                    //   //             fontSize={"medium"}
                    //   //             fontWeight={600}
                    //   //           >
                    //   //             {mentor?.userName}
                    //   //           </Typography>
                    //   //           <Typography
                    //   //             textAlign={"left"}
                    //   //             noWrap
                    //   //             fontSize={"small"}
                    //   //             sx={{ color: "#8E8E8E" }}
                    //   //           >
                    //   //             Data Scientist
                    //   //           </Typography>
                    //   //           <Stack
                    //   //             flexDirection={"row"}
                    //   //             sx={{ mt: 2 }}
                    //   //             alignItems={"center"}
                    //   //           >
                    //   //             {new Array(4).fill(
                    //   //               <StarIcon
                    //   //                 sx={{ color: "#FFD707" }}
                    //   //                 fontSize="small"
                    //   //               />
                    //   //             )}
                    //   //             {new Array(1).fill(
                    //   //               <StarBorderIcon fontSize="small" />
                    //   //             )}
                    //   //             <small>(17)</small>
                    //   //           </Stack>
                    //   //           <Typography
                    //   //             textAlign={"left"}
                    //   //             sx={{ color: "#757575" }}
                    //   //             fontSize={"small"}
                    //   //           >
                    //   //             {mentor?.countryOfResidence}
                    //   //           </Typography>
                    //   //         </Stack>
                    //   //       </Stack>

                    //   //       <Stack flexDirection={"column"} gap={1}>
                    //   //         <Stack
                    //   //           flexDirection={"row"}
                    //   //           alignItems={"flex-end"}
                    //   //           gap={1}
                    //   //         >
                    //   //           <ReviewsIcon fontSize="small" />
                    //   //           <Typography noWrap fontSize="small">
                    //   //             17 Reviews
                    //   //           </Typography>
                    //   //         </Stack>

                    //   //         <Stack
                    //   //           flexDirection={"row"}
                    //   //           alignItems={"flex-end"}
                    //   //           gap={1}
                    //   //         >
                    //   //           <LocationOnIcon fontSize="small" />
                    //   //           <Typography noWrap fontSize="small">
                    //   //             {mentor?.countryOfResidence}
                    //   //           </Typography>
                    //   //         </Stack>

                    //   //         <Stack
                    //   //           flexDirection={"row"}
                    //   //           alignItems={"flex-end"}
                    //   //           gap={1}
                    //   //         >
                    //   //           <MoneyIcon fontSize="small" />
                    //   //           <Typography noWrap fontSize="small">
                    //   //             {mentor?.hourlyRate}
                    //   //           </Typography>
                    //   //         </Stack>

                    //   //         <Button
                    //   //           size="small"
                    //   //           variant="contained"
                    //   //           onClick={() =>
                    //   //             navigate(
                    //   //               `/bookAppointment?id=${mentor?._id}`
                    //   //             )
                    //   //           }
                    //   //           sx={
                    //   //             isMobile
                    //   //               ? {
                    //   //                   background: "#5F61BE",
                    //   //                   width: "fit-content",
                    //   //                   mt: "10px",
                    //   //                   ml: "15vw",
                    //   //                   px: "10px",
                    //   //                 }
                    //   //               : {
                    //   //                   background: "#5F61BE",
                    //   //                   width: "fit-content",
                    //   //                   mt: "10px",
                    //   //                 }
                    //   //           }
                    //   //         >
                    //   //           BOOK APPOINTMENT
                    //   //         </Button>
                    //   //       </Stack>
                    //   //     </Stack>
                    //   //   </RightBorderDashboard>
                    //   //   <br />
                    //   // </Box>
                    // ))
                  )}
                </Grid>
              </Grid>
              {/* <Grid container gap={2} sx={{ mt: 4 }}>
                  <Grid item sm={12} lg={12}>
                    {loading ? (
                      <Spinner />
                    ) : (
                      mentorListUniversity?.map((mentor, index) => (
                        <Box
                          sx={{ margin: isMobile ? "0px 15px" : "" }}
                          key={index}
                        >
                          <RightBorderDashboard
                            style={{
                              border: "1px solid #D6D6D6",
                              backgroundColor: "#FFF",
                            }}
                          >
                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={isMobile ? "column" : "row"}
                              gap={isMobile ? 3 : 0}
                              p={isMobile ? 2.5 : 0}
                            >
                              <Stack
                                flexDirection={"row"}
                                gap={2}
                                width={isMobile ? "100%" : "70%"}
                              >
                                <img
                                  style={
                                    isMobile
                                      ? { width: "35%", borderRadius: "15px" }
                                      : { width: "20%", borderRadius: "15px" }
                                  }
                                  src={
                                    mentor?.attachments?.length > 0
                                      ? mentor?.attachments[0]?.attachmentPath
                                      : "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg"
                                  }
                                />
                                <Stack flexDirection={"column"}>
                                  <Typography
                                    textAlign={"left"}
                                    noWrap
                                    sx={{ color: "#5F61BE" }}
                                    fontSize={"medium"}
                                    fontWeight={600}
                                  >
                                    {mentor?.userName}
                                  </Typography>
                                  <Typography
                                    textAlign={"left"}
                                    noWrap
                                    fontSize={"small"}
                                    sx={{ color: "#8E8E8E" }}
                                  >
                                    Data Scientist
                                  </Typography>
                                  <Stack
                                    flexDirection={"row"}
                                    sx={{ mt: 2 }}
                                    alignItems={"center"}
                                  >
                                    {new Array(4).fill(
                                      <StarIcon
                                        sx={{ color: "#FFD707" }}
                                        fontSize="small"
                                      />
                                    )}
                                    {new Array(1).fill(
                                      <StarBorderIcon fontSize="small" />
                                    )}
                                    <small>(17)</small>
                                  </Stack>
                                  <Typography
                                    textAlign={"left"}
                                    sx={{ color: "#757575" }}
                                    fontSize={"small"}
                                  >
                                    {mentor?.countryOfResidence}
                                  </Typography>
                                </Stack>
                              </Stack>

                              <Stack flexDirection={"column"} gap={1}>
                                <Stack
                                  flexDirection={"row"}
                                  alignItems={"flex-end"}
                                  gap={1}
                                >
                                  <ReviewsIcon fontSize="small" />
                                  <Typography noWrap fontSize="small">
                                    17 Reviews
                                  </Typography>
                                </Stack>

                                <Stack
                                  flexDirection={"row"}
                                  alignItems={"flex-end"}
                                  gap={1}
                                >
                                  <LocationOnIcon fontSize="small" />
                                  <Typography noWrap fontSize="small">
                                    {mentor?.countryOfResidence}
                                  </Typography>
                                </Stack>

                                <Stack
                                  flexDirection={"row"}
                                  alignItems={"flex-end"}
                                  gap={1}
                                >
                                  <MoneyIcon fontSize="small" />
                                  <Typography noWrap fontSize="small">
                                    {mentor?.hourlyRate}
                                  </Typography>
                                </Stack>

                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    navigate(
                                      `/bookAppointment?id=${mentor?._id}`
                                    )
                                  }
                                  sx={
                                    isMobile
                                      ? {
                                          background: "#5F61BE",
                                          width: "fit-content",
                                          mt: "10px",
                                          ml: "15vw",
                                          px: "10px",
                                        }
                                      : {
                                          background: "#5F61BE",
                                          width: "fit-content",
                                          mt: "10px",
                                        }
                                  }
                                >
                                  BOOK APPOINTMENT
                                </Button>
                              </Stack>
                            </Stack>
                          </RightBorderDashboard>
                          <br />
                        </Box>
                      ))
                    )}
                  </Grid>
                </Grid>
                <Grid container gap={2} sx={{ mt: 4 }}>
                  <Grid item sm={12} lg={12}>
                    {loading ? (
                      <Spinner />
                    ) : (
                      mentorListLanguage?.map((mentor, index) => (
                        <Box
                          sx={{ margin: isMobile ? "0px 15px" : "" }}
                          key={index}
                        >
                          <RightBorderDashboard
                            style={{
                              border: "1px solid #D6D6D6",
                              backgroundColor: "#FFF",
                            }}
                          >
                            <Stack
                              justifyContent={"space-between"}
                              flexDirection={isMobile ? "column" : "row"}
                              gap={isMobile ? 3 : 0}
                              p={isMobile ? 2.5 : 0}
                            >
                              <Stack
                                flexDirection={"row"}
                                gap={2}
                                width={isMobile ? "100%" : "70%"}
                              >
                                <img
                                  style={
                                    isMobile
                                      ? { width: "35%", borderRadius: "15px" }
                                      : { width: "20%", borderRadius: "15px" }
                                  }
                                  src={
                                    mentor?.attachments?.length > 0
                                      ? mentor?.attachments[0]?.attachmentPath
                                      : "https://mentorgrad.s3.us-west-2.amazonaws.com/dummy2.jpg"
                                  }
                                />
                                <Stack flexDirection={"column"}>
                                  <Typography
                                    textAlign={"left"}
                                    noWrap
                                    sx={{ color: "#5F61BE" }}
                                    fontSize={"medium"}
                                    fontWeight={600}
                                  >
                                    {mentor?.userName}
                                  </Typography>
                                  <Typography
                                    textAlign={"left"}
                                    noWrap
                                    fontSize={"small"}
                                    sx={{ color: "#8E8E8E" }}
                                  >
                                    Data Scientist
                                  </Typography>
                                  <Stack
                                    flexDirection={"row"}
                                    sx={{ mt: 2 }}
                                    alignItems={"center"}
                                  >
                                    {new Array(4).fill(
                                      <StarIcon
                                        sx={{ color: "#FFD707" }}
                                        fontSize="small"
                                      />
                                    )}
                                    {new Array(1).fill(
                                      <StarBorderIcon fontSize="small" />
                                    )}
                                    <small>(17)</small>
                                  </Stack>
                                  <Typography
                                    textAlign={"left"}
                                    sx={{ color: "#757575" }}
                                    fontSize={"small"}
                                  >
                                    {mentor?.countryOfResidence}
                                  </Typography>
                                </Stack>
                              </Stack>

                              <Stack flexDirection={"column"} gap={1}>
                                <Stack
                                  flexDirection={"row"}
                                  alignItems={"flex-end"}
                                  gap={1}
                                >
                                  <ReviewsIcon fontSize="small" />
                                  <Typography noWrap fontSize="small">
                                    17 Reviews
                                  </Typography>
                                </Stack>

                                <Stack
                                  flexDirection={"row"}
                                  alignItems={"flex-end"}
                                  gap={1}
                                >
                                  <LocationOnIcon fontSize="small" />
                                  <Typography noWrap fontSize="small">
                                    {mentor?.countryOfResidence}
                                  </Typography>
                                </Stack>

                                <Stack
                                  flexDirection={"row"}
                                  alignItems={"flex-end"}
                                  gap={1}
                                >
                                  <MoneyIcon fontSize="small" />
                                  <Typography noWrap fontSize="small">
                                    {mentor?.hourlyRate}
                                  </Typography>
                                </Stack>

                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    navigate(
                                      `/bookAppointment?id=${mentor?._id}`
                                    )
                                  }
                                  sx={
                                    isMobile
                                      ? {
                                          background: "#5F61BE",
                                          width: "fit-content",
                                          mt: "10px",
                                          ml: "15vw",
                                          px: "10px",
                                        }
                                      : {
                                          background: "#5F61BE",
                                          width: "fit-content",
                                          mt: "10px",
                                        }
                                  }
                                >
                                  BOOK APPOINTMENT
                                </Button>
                              </Stack>
                            </Stack>
                          </RightBorderDashboard>
                          <br />
                        </Box>
                      ))
                    )}
                  </Grid>
                </Grid> */}
            </Grid>
          </RightContainerDash>
          {showFilter && (
            <Dialog
              open={showFilter}
              maxWidth={"xl"}
              sx={{ overflowX: "scroll" }}
            >
              {/* <DialogContent>
                  <SearchFilter
                    setFilters={setFilters}
                    countries={countries}
                    filters={filters}
                    selectedOptions={selectedOptions}
                    handleChange={handleChange}
                    handleCheckboxChange={handleCheckboxChange}
                    getMentors={getMentors}
                  />
                </DialogContent> */}
            </Dialog>
          )}
        </ContainerDashboard>
      </Container>
    </>
  );
};

export default MentorSearch;
