import React from "react";
import { Box, Grid, Link, Typography, Button } from "@mui/material";
// import Button from "../../components/Button/index";
import Container from "../../common/Input/useStyles";
import TransparentBlueBackground from "../../Assets/Images/contact-us.jpeg";
import Google from "../../Assets/Images/google.svg";
import TextField from "../../components/Inputs";
import CreateAccount from "./style";
import { useNavigate } from "react-router-dom";
const Index = ({ title, buttonText, link }) => {
  const navigate = useNavigate();
  return (
    <CreateAccount>
      <Box
        sx={{
          backgroundImage: `url(${TransparentBlueBackground})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          py: { xs: 5, md:10 },
        }}
      >
        <Container>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h3"
              sx={{ color: "#fff", fontFamily: "Gilroy" }}
            >
              {title}
            </Typography>

            <Button
              variant="contained"
              sx={{
                mt: "40px",
                backgroundColor: "#C9F6EF",
                color: "black",
                borderRadius: "15px",
                px: "30px",
                py: "12px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#C9F6EF",
                },
              }}
              onClick={() => {
                navigate(link);
              }}
            >
              {buttonText}
            </Button>
          </Box>

          {/* <Box component="h1" sx={{ color: "#fff", fontSize: "40px" }}>MentorGrad</Box>
                    <Grid container justifyContent="center"
                        alignItems="center">
                        <Grid item md={4}>
                            <Box className="create-acccount">
                                <h2>Create an account</h2>
                                <span>Let’s get started with your 30 days free trial</span>
                                <Box mt={2}>
                                <TextField label="Name"  />
                                <TextField label="Email" />
                                <TextField type="password" label="Password" />
                                <Button borderRadius="15px" width="100%" backgroundColor="#FFFFFF" text="Create Account" />
                                <Box mt={2} className="google-button" display="flex" gap={2} justifyContent="center" alignItems="center">
                                    <img src={Google} alt="no-icon" />
                                    <Box component="span">Sign up with Google</Box>
                                </Box>
                                <Box mt={3} color="#fff" fontSize="16px" >
                                     Already have an account? <Box component="span"fontSize="20px" className='link'  > Sign in</Box> 
                                </Box>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid> */}
        </Container>
      </Box>
    </CreateAccount>
  );
};
export default Index;
