import React from "react";
import MentorMentoorWrapper from './style'
import Container from '../../common/Input/useStyles'
import image from "../../Assets/Images/Rectangle 4245.png";
import image1 from "../../Assets/Images/Rectangle 4245 (1).png";
import image2 from "../../Assets/Images/Rectangle 4245 (2).png";
import styled from "styled-components";
import { Box, Grid } from "@mui/material";


const BecomeMentor = ({title1,title2,BecomeMentorData}) => {
  return (
    <MentorMentoorWrapper>
      <Container>
        <Box
          component={"h2"}
          textAlign="center"
          mb={"40px"}
        >
          {title1}
          <Box component={"span"} style={{ color: "#7476D1" }}>
            {" "}
            {title2}
          </Box>
        </Box>
        <Grid container columnSpacing={3}>
          {BecomeMentorData.map((BecomeMentor, index) => {
            const { title, content, imageSrc } = BecomeMentor;
            return (
              <Grid
                item xs={12} sm={6} md={4}
              >
                <Box className="inner-box" sx={{
                  border: "0.5px solid #ececf9",
                  borderRadius: "15px",
                  minHeight:{xs:"480px", md:"581px"},
                  marginBottom:"24px",
                  "&:hover":{
                    boxShadow:"0px 10px 27px -12px rgba(116, 118, 209,0.30);"
                  }
                }}>
                  <Box     >
                    <img
                      src={imageSrc}
                      alt="no-mentor"
                      className="mentor-image"
                    />
                  </Box>
                  <Box  p={2}>
                    <Box
                      component="h3"
                    >
                     {title}
                    </Box>
                    <Box
                      component={"p"}
                    >
                      {content}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </MentorMentoorWrapper>
  );
};

export default BecomeMentor;
