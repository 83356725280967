import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../../components/Button/index";
import logo from "../../Assets/Images/branding/logo.png";
import "./Header.css";
import {
  BackgroundChecked,
  BackgroundChecked1,
  TextChecked,
  TextChecked1,
} from "../UserForm/UserFormStyles";
import Container from "../../common/Input/useStyles";
import UserMenu from "../Usermenu";
import { RiArrowDropDownLine } from "react-icons/ri";

import userIcon from "../../Assets/Images/icons/user.png";
import newUserIcon from "../../Assets/Images/icons/new-user.png";
import addUserIcon from "../../Assets/Images/icons/add-user.png";
import BxsDashboard from "../../Assets/Images/bxs_dashboard.png";
import { Theme, useMediaQuery } from "@mui/material";
import { jwtDecode } from "../../helper-functions";

interface MyComponentProps {
  isWhiteBackground?: boolean;
}

interface Submenu {
  [key: string]: boolean;
}

const Header: React.FC<MyComponentProps> = ({ isWhiteBackground = false }) => {
  const navigate = useNavigate();
  const user = jwtDecode(localStorage.getItem("@storage_Key"));
  const [showNavbar, setShowNavbar] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showFindMentorDropdown, setShowFindMentorDropdown] = useState(false);
  const [showUniversitiesDropdown, setShowUniversitiesDropdown] =
    useState(false);
  const [showStudentsDropdown, setshowStudentsDropdown] = useState(false);
  const [submenu, setSubMenu] = useState<Submenu>({
    mentor: false,
    universities: false,
    students: false
  })

  useEffect(() => {
    document.body.style.overflow = "visible";
    const token = localStorage.getItem("@storage_Key");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const isBelowXl = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
    setIsNavOpen(!isNavOpen);
  };

  const handleHoverFindMentor = () => {
    setShowFindMentorDropdown(true);
  };

  const handleLeaveFindMentor = () => {
    setShowFindMentorDropdown(false);
  };

  const handleHoverUniversities = () => {
    setShowUniversitiesDropdown(true);
  };

  const handleLeaveUniversities = () => {
    setShowUniversitiesDropdown(false);
  };

  const handleHoverStudents = () => {
    setshowStudentsDropdown(true);
  };

  const handleLeaveStudents = () => {
    setshowStudentsDropdown(false);
  };
  const openSubMenu = (menu: string) => {
    let _submenu = Object.keys(submenu).reduce<Submenu>((acc, key) => {
      acc[key] = key == menu ? submenu[menu] : false;
      return acc;
    }, {})
    if (menu)
      _submenu[menu] = !_submenu[menu]
    setSubMenu(_submenu)
  }

  const UniversitySubMenu = () => {
    return <div className="submenu">
      <div>
        <NavLink to="/university">Why ?</NavLink>
      </div>
    </div>
  }

  const StudentSubMenu = () => {
    return <div className="submenu">
      <div>
        <NavLink to="/mentee">Why Join?</NavLink>
      </div>
      <div>
        <NavLink to="/findMentor">Mentor Search</NavLink>
      </div>
      <div>
        <NavLink to="/mentor">Start Your Journey</NavLink>
      </div>
    </div>
  }

  const FindAMentorSubMenu = () => {
    return <div className="submenu">
      <h6 style={{ marginTop: "10px" }}>Popular Mentors</h6>
      <div>
        <NavLink
          to={
            "/findMentor?location=us"
          }
        >
          US
        </NavLink>
      </div>
      <div>
        <NavLink
          to={
            "/findMentor?location=uk"
          }
        >
          UK
        </NavLink>
      </div>
      <div>
        <NavLink
          to={
            "/findMentor?location=canada"
          }
        >
          Canada
        </NavLink>
      </div>
      <div>
        <NavLink
          to={
            "/findMentor?location=australia"
          }
        >
          Australia
        </NavLink>
      </div>

      <div>
        <NavLink
          to={
            "/findMentor"
          }
        >
          <img
            src={userIcon}
            style={{ marginRight: "10px", width: "22px" }}
          />
          All Mentors
        </NavLink>
      </div>
      <div>
        <NavLink to="/mentor">
          <img
            src={newUserIcon}
            style={{ marginRight: "10px", width: "22px" }}
          />
          New Mentors
        </NavLink>
      </div>
      <div>
        <NavLink to="/mentor/signup">
          <img
            src={addUserIcon}
            style={{ marginRight: "10px", width: "22px" }}
          />
          Become a Mentor
        </NavLink>
      </div>
    </div>
  }

  return (
    <div
      className="nav-container"
      style={{ backgroundColor: isWhiteBackground ? "white" : "" }}
    >
      <Container>
        <div className="navContainer">
          <div>
            <NavLink to="/">
              <div className="logo">
                <img
                  src={logo}
                  style={{ width: "100%", objectFit: "cover" }}
                  alt="nol-logo"
                />
              </div>
            </NavLink>
          </div>
          <div>
            <div className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={handleShowNavbar}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
          </div>
          <div className={`nav-elements  ${showNavbar && "active"}`}>
            <ul>
              <div className="menu">
                <li>
                  <div
                    onMouseEnter={handleHoverFindMentor}
                    onMouseLeave={handleLeaveFindMentor}
                    className="menu-item"
                    onClick={() => openSubMenu("mentor")}
                  >
                    Find a Mentor <RiArrowDropDownLine />
                  </div>
                  {submenu.mentor && <FindAMentorSubMenu />}
                  {showFindMentorDropdown && (
                    <div>
                      <ul
                        className="header-dropdown-content"
                        onMouseEnter={handleHoverFindMentor}
                        onMouseLeave={handleLeaveFindMentor}
                      >
                        <li
                          className="dropdown"
                          style={{ display: "flex", padding: "0" }}
                        >
                          <div style={{ minWidth: "100px", padding: "20px" }}>
                            <h4>Popular Countries</h4>
                            <div className="nav-link-item">
                              <NavLink
                                to={
                                  "/findMentor?location=us"
                                }
                              >
                                US
                              </NavLink>
                            </div>
                            <div className="nav-link-item">
                              <NavLink
                                to={
                                  "/findMentor?location=uk"
                                }
                              >
                                UK
                              </NavLink>
                            </div>
                            <div className="nav-link-item">
                              <NavLink
                                to={
                                  "/findMentor?location=canada"
                                }
                              >
                                {" "}
                                Canada
                              </NavLink>
                            </div>
                            <div className="nav-link-item">
                              <NavLink
                                to={
                                  "/findMentor?location=australia"
                                }
                              >
                                Australia
                              </NavLink>
                            </div>
                          </div>
                          <div className="vertical-devider"></div>
                          <div style={{ padding: "10px" }}>
                            <div className="nav-link-item">
                              <NavLink
                                to={
                                  "/findMentor"
                                }
                              >
                                <img
                                  src={userIcon}
                                  style={{ marginRight: "10px", width: "22px" }}
                                />
                                All Mentors
                              </NavLink>
                            </div>
                            <div className="nav-link-item">
                              <NavLink to="/mentor">
                                <img
                                  src={newUserIcon}
                                  style={{ marginRight: "10px", width: "22px" }}
                                />
                                New Mentors
                              </NavLink>
                            </div>
                            <div className="nav-link-item">
                              <NavLink to="/mentor/signup">
                                <img
                                  src={addUserIcon}
                                  style={{ marginRight: "10px", width: "22px" }}
                                />
                                Become a Mentor
                              </NavLink>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li>
                  <div
                    onMouseEnter={handleHoverUniversities}
                    onMouseLeave={handleLeaveUniversities}
                    onClick={() => openSubMenu("universities")}
                    className="menu-item"
                  >
                    Universities <RiArrowDropDownLine />
                  </div>
                  {submenu.universities && <UniversitySubMenu />}
                  {showUniversitiesDropdown && (
                    <ul
                      className="header-dropdown-content"
                      onMouseEnter={handleHoverUniversities}
                      onMouseLeave={handleLeaveUniversities}
                    >
                      <li className="dropdown">
                        <div className="nav-link-item">
                          <NavLink to="/university">Why Partner?</NavLink>
                        </div>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <div
                    onMouseEnter={handleHoverStudents}
                    onMouseLeave={handleLeaveStudents}
                    className="menu-item"
                    onClick={() => openSubMenu("students")}
                  >
                    Students <RiArrowDropDownLine />
                  </div>
                  {submenu.students && <StudentSubMenu />}
                  {showStudentsDropdown && (
                    <ul
                      className="header-dropdown-content"
                      onMouseEnter={handleHoverStudents}
                      onMouseLeave={handleLeaveStudents}
                    >
                      <li className="dropdown">
                        <div className="nav-link-item">
                          <NavLink to="/mentee">Why Join?</NavLink>
                        </div>
                        <div className="nav-link-item">
                          <NavLink to="/findMentor">Mentor Search</NavLink>
                        </div>
                        <div className="nav-link-item">
                          <NavLink to="/register">Start Your Journey</NavLink>
                        </div>
                      </li>
                    </ul>
                  )}
                </li>
              </div>

              <div>
                <li>
                  {isLoggedIn ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!isBelowXl && (
                        <Link to={`/${user?.userType == "Mentor" ? "mentor" : "student"}/dashboard?tab=0`}>
                          <img
                            src={BxsDashboard}
                            alt="dashboard"
                            style={{ marginRight: "2px" }}
                          />
                          <span
                            style={{
                              marginRight: "10px",
                              fontWeight: "500",
                              marginTop: "2px",
                              fontSize: "1rem",
                              color: "#202020",
                            }}
                          >
                            Dashboard
                          </span>
                        </Link>
                      )}
                      <UserMenu />
                    </div>
                  ) : (
                    <Button
                      width={"150px"}
                      margin={"0"}
                      onClick={() => navigate("/register")}
                      text="Get Started"
                    />
                  )}
                </li>
              </div>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
