
import styled from 'styled-components';
const InputWrapper = styled.div`
    .MuiInputBase-root {
        color:#fff;
        padding:8px 0 5px;
        margin-bottom: 24px;
        &::before{
            border-bottom: 1px solid rgb(255 255 255 / 42%);
        }
        .css-yqx9en-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before{
            border-bottom: 1px solid rgb(255 255 255 / 42%);
        }
        &::after{
            border-bottom: 2px solid #fff;
        }
    }
    .MuiFormLabel-root {
        color:#fff;
        &:focus{
            color:#fff; 
        }
    }
`;
export default InputWrapper;