import { Box, Grid } from "@mui/material";
import Container from "../../common/Input/useStyles";
import React, { useState } from "react";
import mapImg from "../../Assets/Images/image 16.png";
import { Link } from "react-router-dom";

const UniPartnership = () => {
  const [loadMore, setLoadMore] = useState(false);
  return (
    <Box bgcolor={"#C9F6EF"} py={5} mb={5}>
      <Container>
        <Grid container>
          <Grid item md={6}>
            <Box sx={{ mt: { md: "40px" } }}>
              <Box
                component={"h2"}
                sx={{
                  fontFamily: "Gilroy",
                  fontSize: "48px",
                  fontWeight: 700,
                  lineHeight: "58px",
                  textAlign: "start",
                  color: "#7476D1",
                }}
              >
                University Partnerships
              </Box>
              <Box
                component={"p"}
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "34px",
                  textAlign: "start",
                }}
              >
                By joining MentorGrad, you gain access to a diverse range of
                universities actively seeking international students. These
                institutions showcase their offerings right here, making it
                easier for you to find the perfect fit for your academic
                aspirations. What's more, each university has dedicated advisors
                available on the MentorGrad platform
                {loadMore
                  ? "These experts are ready to guide you, answering your questions and helping you navigate the complexities of international education. Your profile on MentorGrad plays a crucial role. It's more than just a resume; it's a tool that universities use to understand your academic background, interests, and goals. Based on this information, universities can make direct offers for enrolment to you. This personalized approach ensures that you find a university program that not only meets your educational needs but also aligns with your career ambitions. "
                  : "... "}
                <Link
                  to=""
                  style={{ color: "inherit" }}
                  onClick={() => setLoadMore(!loadMore)}
                >
                  {loadMore ? "view less" : "view more"}
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <img src={mapImg} alt="map Img" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default UniPartnership;