import React from "react";
import Container from "../../common/Input/useStyles";
import { Box, Button, Grid } from "@mui/material";
import TransparentBlueBackground from "../../Assets/Images/oppBack.png";
import Image6 from "../../Assets/Images/image 6.png";
import Image7 from "../../Assets/Images/image 7.png";
import Image8 from "../../Assets/Images/image 8.png";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundImage: `url(${TransparentBlueBackground})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        py: 10,
      }}
    >
      <Container>
        <Box>
          <Box
            sx={{
              width: "100%",
              m: "0 auto",
              textAlign: "left",
              color: "white",
            }}
          >
            <div style={{ width: "min(800px, 100%)" }}>
              <h2 style={{ marginBlock: "20px" }}>Earning Opportunities</h2>
              <p style={{ marginBottom: "60px" }}>
                MentorGrad offers you an opportunity to generate an income
                through your association on the platform. We empower you to set
                your own rate based on your profile. Following are the ways by
                which you can monetise your time and effort on this platform.
              </p>
            </div>
            <Grid
              container
              sx={{ marginBottom: "30px" }}
              columnGap={6}
              rowGap={4}
            >
              <Grid item xs={12} lg={3}>
                <img
                  src={Image6}
                  alt="no-mentor"
                  style={{ marginBottom: "15px" }}
                />
                <h3>Mentoring Plans</h3>
                <p>
                  You charge your mentees for your time through monthly
                  mentoring plans or one-off mentoring sessions.
                </p>
              </Grid>
              <Grid item xs={12} lg={3}>
                <img
                  src={Image7}
                  alt="no-mentor"
                  style={{ marginBottom: "15px" }}
                />
                <h3>Referral Rewards</h3>
                <p>
                  If you enrol a student at your university, you may receive a
                  referral reward from your university.
                </p>
              </Grid>
              <Grid item xs={12} lg={3}>
                <img
                  src={Image8}
                  alt="no-mentor"
                  style={{ marginBottom: "15px" }}
                />
                <h3>Academic support</h3>
                <p>
                  You may earn from helping students in their academic and
                  research work.
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={1.8} className="BMentor_btn">
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#C9F6EF",
                    color: "black",
                    borderRadius: "15px",
                    px: "30px",
                    py: "12px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#C9F6EF",
                    },
                  }}
                  onClick={() => {
                    navigate("mentor/signup");
                  }}
                >
                  Become a Mentor
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default Index;
