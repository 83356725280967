import { TextField } from "@mui/material";
import React, { useState } from "react";

interface TextInputProps {
  value: any;
  onChange?: (e: any) => void;
  placeholder?: string;
  editable?: boolean;
  width?: any;
  
  type?: any;
  id?: any;
  maxLength?: number; // Add maxLength as a prop
  size?: any;
  sx?: any;
  label?: any;
  backgroundColor?: any;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const borderColor = isHovered ? "#000" : "#D6D6D6";
  return (
    <TextField
      size={props.size ? props.size : "large" || "large"}
      disabled={props.editable}
      fullWidth
      placeholder={props.placeholder}
      value={props.value}
      type={props.type}
      label={props.label}
      inputProps={{
        maxLength: props.maxLength,
      }}
      onChange={props.onChange}
      id={props.id}
      variant="outlined"
      sx={{
       
        "& fieldset": {
          borderRadius: "12px",
          borderWidth: "2px",
          borderColor: "#D6D6D6",
          textAlign: "left",
          
          fontSize: 14,
        },
        ...props.sx, // Keep any additional styles from props.sx
      }}
    />
  );
};

export default TextInput;
