import profile from '../Assets/Images/Mask1.png';
import One from '../Assets/Images/one1.svg';
import Two from '../Assets/Images/two2.svg';
import Three from '../Assets/Images/three3.svg';
export const Country = [
  {label: 'Afghanistan', value: 'AF'}, 
  {label: 'Åland Islands', value: 'AX'}, 
  {label: 'Albania', value: 'AL'}, 
  {label: 'Algeria', value: 'DZ'}, 
  {label: 'American Samoa', value: 'AS'}, 
  {label: 'AndorrA', value: 'AD'}, 
  {label: 'Angola', value: 'AO'}, 
  {label: 'Anguilla', value: 'AI'}, 
  {label: 'Antarctica', value: 'AQ'}, 
  {label: 'Antigua and Barbuda', value: 'AG'}, 
  {label: 'Argentina', value: 'AR'}, 
  {label: 'Armenia', value: 'AM'}, 
  {label: 'Aruba', value: 'AW'}, 
  {label: 'Australia', value: 'AU'}, 
  {label: 'Austria', value: 'AT'}, 
  {label: 'Azerbaijan', value: 'AZ'}, 
  {label: 'Bahamas', value: 'BS'}, 
  {label: 'Bahrain', value: 'BH'}, 
  {label: 'Bangladesh', value: 'BD'}, 
  {label: 'Barbados', value: 'BB'}, 
  {label: 'Belarus', value: 'BY'}, 
  {label: 'Belgium', value: 'BE'}, 
  {label: 'Belize', value: 'BZ'}, 
  {label: 'Benin', value: 'BJ'}, 
  {label: 'Bermuda', value: 'BM'}, 
  {label: 'Bhutan', value: 'BT'}, 
  {label: 'Bolivia', value: 'BO'}, 
  {label: 'Bosnia and Herzegovina', value: 'BA'}, 
  {label: 'Botswana', value: 'BW'}, 
  {label: 'Bouvet Island', value: 'BV'}, 
  {label: 'Brazil', value: 'BR'}, 
  {label: 'British Indian Ocean Territory', value: 'IO'}, 
  {label: 'Brunei Darussalam', value: 'BN'}, 
  {label: 'Bulgaria', value: 'BG'}, 
  {label: 'Burkina Faso', value: 'BF'}, 
  {label: 'Burundi', value: 'BI'}, 
  {label: 'Cambodia', value: 'KH'}, 
  {label: 'Cameroon', value: 'CM'}, 
  {label: 'Canada', value: 'CA'}, 
  {label: 'Cape Verde', value: 'CV'}, 
  {label: 'Cayman Islands', value: 'KY'}, 
  {label: 'Central African Republic', value: 'CF'}, 
  {label: 'Chad', value: 'TD'}, 
  {label: 'Chile', value: 'CL'}, 
  {label: 'China', value: 'CN'}, 
  {label: 'Christmas Island', value: 'CX'}, 
  {label: 'Cocos (Keeling) Islands', value: 'CC'}, 
  {label: 'Colombia', value: 'CO'}, 
  {label: 'Comoros', value: 'KM'}, 
  {label: 'Congo', value: 'CG'}, 
  {label: 'Congo, The Democratic Republic of the', value: 'CD'}, 
  {label: 'Cook Islands', value: 'CK'}, 
  {label: 'Costa Rica', value: 'CR'}, 
  {label: 'Cote D\'Ivoire', value: 'CI'}, 
  {label: 'Croatia', value: 'HR'}, 
  {label: 'Cuba', value: 'CU'}, 
  {label: 'Cyprus', value: 'CY'}, 
  {label: 'Czech Republic', value: 'CZ'}, 
  {label: 'Denmark', value: 'DK'}, 
  {label: 'Djibouti', value: 'DJ'}, 
  {label: 'Dominica', value: 'DM'}, 
  {label: 'Dominican Republic', value: 'DO'}, 
  {label: 'Ecuador', value: 'EC'}, 
  {label: 'Egypt', value: 'EG'}, 
  {label: 'El Salvador', value: 'SV'}, 
  {label: 'Equatorial Guinea', value: 'GQ'}, 
  {label: 'Eritrea', value: 'ER'}, 
  {label: 'Estonia', value: 'EE'}, 
  {label: 'Ethiopia', value: 'ET'}, 
  {label: 'Falkland Islands (Malvinas)', value: 'FK'}, 
  {label: 'Faroe Islands', value: 'FO'}, 
  {label: 'Fiji', value: 'FJ'}, 
  {label: 'Finland', value: 'FI'}, 
  {label: 'France', value: 'FR'}, 
  {label: 'French Guiana', value: 'GF'}, 
  {label: 'French Polynesia', value: 'PF'}, 
  {label: 'French Southern Territories', value: 'TF'}, 
  {label: 'Gabon', value: 'GA'}, 
  {label: 'Gambia', value: 'GM'}, 
  {label: 'Georgia', value: 'GE'}, 
  {label: 'Germany', value: 'DE'}, 
  {label: 'Ghana', value: 'GH'}, 
  {label: 'Gibraltar', value: 'GI'}, 
  {label: 'Greece', value: 'GR'}, 
  {label: 'Greenland', value: 'GL'}, 
  {label: 'Grenada', value: 'GD'}, 
  {label: 'Guadeloupe', value: 'GP'}, 
  {label: 'Guam', value: 'GU'}, 
  {label: 'Guatemala', value: 'GT'}, 
  {label: 'Guernsey', value: 'GG'}, 
  {label: 'Guinea', value: 'GN'}, 
  {label: 'Guinea-Bissau', value: 'GW'}, 
  {label: 'Guyana', value: 'GY'}, 
  {label: 'Haiti', value: 'HT'}, 
  {label: 'Heard Island and Mcdonald Islands', value: 'HM'}, 
  {label: 'Holy See (Vatican City State)', value: 'VA'}, 
  {label: 'Honduras', value: 'HN'}, 
  {label: 'Hong Kong', value: 'HK'}, 
  {label: 'Hungary', value: 'HU'}, 
  {label: 'Iceland', value: 'IS'}, 
  {label: 'India', value: 'IN'}, 
  {label: 'Indonesia', value: 'ID'}, 
  {label: 'Iran, Islamic Republic Of', value: 'IR'}, 
  {label: 'Iraq', value: 'IQ'}, 
  {label: 'Ireland', value: 'IE'}, 
  {label: 'Isle of Man', value: 'IM'}, 
  {label: 'Israel', value: 'IL'}, 
  {label: 'Italy', value: 'IT'}, 
  {label: 'Jamaica', value: 'JM'}, 
  {label: 'Japan', value: 'JP'}, 
  {label: 'Jersey', value: 'JE'}, 
  {label: 'Jordan', value: 'JO'}, 
  {label: 'Kazakhstan', value: 'KZ'}, 
  {label: 'Kenya', value: 'KE'}, 
  {label: 'Kiribati', value: 'KI'}, 
  {label: 'Korea, Democratic People\'S Republic of', value: 'KP'}, 
  {label: 'Korea, Republic of', value: 'KR'}, 
  {label: 'Kuwait', value: 'KW'}, 
  {label: 'Kyrgyzstan', value: 'KG'}, 
  {label: 'Lao People\'S Democratic Republic', value: 'LA'}, 
  {label: 'Latvia', value: 'LV'}, 
  {label: 'Lebanon', value: 'LB'}, 
  {label: 'Lesotho', value: 'LS'}, 
  {label: 'Liberia', value: 'LR'}, 
  {label: 'Libyan Arab Jamahiriya', value: 'LY'}, 
  {label: 'Liechtenstein', value: 'LI'}, 
  {label: 'Lithuania', value: 'LT'}, 
  {label: 'Luxembourg', value: 'LU'}, 
  {label: 'Macao', value: 'MO'}, 
  {label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK'}, 
  {label: 'Madagascar', value: 'MG'}, 
  {label: 'Malawi', value: 'MW'}, 
  {label: 'Malaysia', value: 'MY'}, 
  {label: 'Maldives', value: 'MV'}, 
  {label: 'Mali', value: 'ML'}, 
  {label: 'Malta', value: 'MT'}, 
  {label: 'Marshall Islands', value: 'MH'}, 
  {label: 'Martinique', value: 'MQ'}, 
  {label: 'Mauritania', value: 'MR'}, 
  {label: 'Mauritius', value: 'MU'}, 
  {label: 'Mayotte', value: 'YT'}, 
  {label: 'Mexico', value: 'MX'}, 
  {label: 'Micronesia, Federated States of', value: 'FM'}, 
  {label: 'Moldova, Republic of', value: 'MD'}, 
  {label: 'Monaco', value: 'MC'}, 
  {label: 'Mongolia', value: 'MN'}, 
  {label: 'Montserrat', value: 'MS'}, 
  {label: 'Morocco', value: 'MA'}, 
  {label: 'Mozambique', value: 'MZ'}, 
  {label: 'Myanmar', value: 'MM'}, 
  {label: 'Namibia', value: 'NA'}, 
  {label: 'Nauru', value: 'NR'}, 
  {label: 'Nepal', value: 'NP'}, 
  {label: 'Netherlands', value: 'NL'}, 
  {label: 'Netherlands Antilles', value: 'AN'}, 
  {label: 'New Caledonia', value: 'NC'}, 
  {label: 'New Zealand', value: 'NZ'}, 
  {label: 'Nicaragua', value: 'NI'}, 
  {label: 'Niger', value: 'NE'}, 
  {label: 'Nigeria', value: 'NG'}, 
  {label: 'Niue', value: 'NU'}, 
  {label: 'Norfolk Island', value: 'NF'}, 
  {label: 'Northern Mariana Islands', value: 'MP'}, 
  {label: 'Norway', value: 'NO'}, 
  {label: 'Oman', value: 'OM'}, 
  {label: 'Pakistan', value: 'PK'}, 
  {label: 'Palau', value: 'PW'}, 
  {label: 'Palestinian Territory, Occupied', value: 'PS'}, 
  {label: 'Panama', value: 'PA'}, 
  {label: 'Papua New Guinea', value: 'PG'}, 
  {label: 'Paraguay', value: 'PY'}, 
  {label: 'Peru', value: 'PE'}, 
  {label: 'Philippines', value: 'PH'}, 
  {label: 'Pitcairn', value: 'PN'}, 
  {label: 'Poland', value: 'PL'}, 
  {label: 'Portugal', value: 'PT'}, 
  {label: 'Puerto Rico', value: 'PR'}, 
  {label: 'Qatar', value: 'QA'}, 
  {label: 'Reunion', value: 'RE'}, 
  {label: 'Romania', value: 'RO'}, 
  {label: 'Russian Federation', value: 'RU'}, 
  {label: 'RWANDA', value: 'RW'}, 
  {label: 'Saint Helena', value: 'SH'}, 
  {label: 'Saint Kitts and Nevis', value: 'KN'}, 
  {label: 'Saint Lucia', value: 'LC'}, 
  {label: 'Saint Pierre and Miquelon', value: 'PM'}, 
  {label: 'Saint Vincent and the Grenadines', value: 'VC'}, 
  {label: 'Samoa', value: 'WS'}, 
  {label: 'San Marino', value: 'SM'}, 
  {label: 'Sao Tome and Principe', value: 'ST'}, 
  {label: 'Saudi Arabia', value: 'SA'}, 
  {label: 'Senegal', value: 'SN'}, 
  {label: 'Serbia and Montenegro', value: 'CS'}, 
  {label: 'Seychelles', value: 'SC'}, 
  {label: 'Sierra Leone', value: 'SL'}, 
  {label: 'Singapore', value: 'SG'}, 
  {label: 'Slovakia', value: 'SK'}, 
  {label: 'Slovenia', value: 'SI'}, 
  {label: 'Solomon Islands', value: 'SB'}, 
  {label: 'Somalia', value: 'SO'}, 
  {label: 'South Africa', value: 'ZA'}, 
  {label: 'South Georgia and the South Sandwich Islands', value: 'GS'}, 
  {label: 'Spain', value: 'ES'}, 
  {label: 'Sri Lanka', value: 'LK'}, 
  {label: 'Sudan', value: 'SD'}, 
  {label: 'Suriname', value: 'SR'}, 
  {label: 'Svalbard and Jan Mayen', value: 'SJ'}, 
  {label: 'Swaziland', value: 'SZ'}, 
  {label: 'Sweden', value: 'SE'}, 
  {label: 'Switzerland', value: 'CH'}, 
  {label: 'Syrian Arab Republic', value: 'SY'}, 
  {label: 'Taiwan, Province of China', value: 'TW'}, 
  {label: 'Tajikistan', value: 'TJ'}, 
  {label: 'Tanzania, United Republic of', value: 'TZ'}, 
  {label: 'Thailand', value: 'TH'}, 
  {label: 'Timor-Leste', value: 'TL'}, 
  {label: 'Togo', value: 'TG'}, 
  {label: 'Tokelau', value: 'TK'}, 
  {label: 'Tonga', value: 'TO'}, 
  {label: 'Trinidad and Tobago', value: 'TT'}, 
  {label: 'Tunisia', value: 'TN'}, 
  {label: 'Turkey', value: 'TR'}, 
  {label: 'Turkmenistan', value: 'TM'}, 
  {label: 'Turks and Caicos Islands', value: 'TC'}, 
  {label: 'Tuvalu', value: 'TV'}, 
  {label: 'Uganda', value: 'UG'}, 
  {label: 'Ukraine', value: 'UA'}, 
  {label: 'United Arab Emirates', value: 'AE'}, 
  {label: 'United Kingdom', value: 'GB'}, 
  {label: 'United States', value: 'US'}, 
  {label: 'United States Minor Outlying Islands', value: 'UM'}, 
  {label: 'Uruguay', value: 'UY'}, 
  {label: 'Uzbekistan', value: 'UZ'}, 
  {label: 'Vanuatu', value: 'VU'}, 
  {label: 'Venezuela', value: 'VE'}, 
  {label: 'Viet Nam', value: 'VN'}, 
  {label: 'Virgin Islands, British', value: 'VG'}, 
  {label: 'Virgin Islands, U.S.', value: 'VI'}, 
  {label: 'Wallis and Futuna', value: 'WF'}, 
  {label: 'Western Sahara', value: 'EH'}, 
  {label: 'Yemen', value: 'YE'}, 
  {label: 'Zambia', value: 'ZM'}, 
  {label: 'Zimbabwe', value: 'ZW'}

];

export const Uni = [
  { id: 1, label: 'University of Andorra', value: 'University of Andorra' },
  { id: 2, label: 'Abu Dhabi University', value: 'Abu Dhabi University' },
  { id: 3, label: 'Ajman University of Science & Technology', value: 'Ajman University of Science & Technology' },
  { id: 4, label: 'Alain University of Science and Technology', value: 'Alain University of Science and Technology' },
  { id: 5, label: 'Al Ghurair University', value: 'Al Ghurair University' },
  { id: 6, label: 'Alhosn University', value: 'Alhosn University' },
  { id: 7, label: 'Al Khawarizmi International College', value: 'Al Khawarizmi International College' },
  { id: 8, label: 'American College Of Dubai', value: 'American College Of Dubai' },
  { id: 9, label: 'American University in Dubai', value: 'American University in Dubai' },
  { id: 10, label: 'American University in the Emirates', value: 'American University in the Emirates' },
  { id: 11, label: 'American University of Sharjah', value: 'American University of Sharjah' },
  { id: 12, label: 'British University in Dubai', value: 'British University in Dubai' },
  { id: 13, label: 'Dubai Medical College for Girls', value: 'Dubai Medical College for Girls' },
  { id: 14, label: 'Dubai Pharmacy College', value: 'Dubai Pharmacy College' },
  { id: 15, label: 'Etisalat University College', value: 'Etisalat University College' },
  { id: 16, label: 'Gulf Medical University', value: 'Gulf Medical University' },
  { id: 17, label: 'Hamdan Bin Mohammed e-University', value: 'Hamdan Bin Mohammed e-University' },
  { id: 18, label: 'Higher Colleges of Technology', value: 'Higher Colleges of Technology' },
  { id: 19, label: 'Ittihad University', value: 'Ittihad University' },
  { id: 20, label: 'Jumeira University', value: 'Jumeira University' },
  { id: 21, label: 'Khalifa University', value: 'Khalifa University' },
  { id: 22, label: 'Khalifa University of Science, Technology and Research', value: 'Khalifa University of Science, Technology and Research' },
  { id: 23, label: 'Masdar University Of Science And Technology', value: 'Masdar University Of Science And Technology' },
  { id: 24, label: 'New York University, Abu Dhabi', value: 'New York University, Abu Dhabi' },
  { id: 25, label: 'Paris-Sorbonne University Abu Dhabi', value: 'Paris-Sorbonne University Abu Dhabi' },
  { id: 26, label: 'Rak Medical & Health Sciences University', value: 'Rak Medical & Health Sciences University' },
  { id: 27, label: 'Rochester Institute of Technology, Dubai', value: 'Rochester Institute of Technology, Dubai' },
  { id: 28, label: 'Skyline University College, Sharjah', value: 'Skyline University College, Sharjah' },
  { id: 29, label: 'Synergy University, Dubai Campus', value: 'Synergy University, Dubai Campus' },
  { id: 30, label: 'The Emirates Academy of Hotel Managment', value: 'The Emirates Academy of Hotel Managment' },
  { id: 31, label: 'The Petroleum Institute', value: 'The Petroleum Institute' },
  { id: 32, label: 'United Arab Emirates University', value: 'United Arab Emirates University' },
  { id: 33, label: 'University Of Dubai', value: 'University Of Dubai' },
  { id: 34, label: 'University of Jazeera', value: 'University of Jazeera' },
  { id: 35, label: 'University of Sharjah', value: 'University of Sharjah' },
  { id: 36, label: 'University of Wollongong - Dubai Campus', value: 'University of Wollongong - Dubai Campus' },
  { id: 37, label: 'Zayed University', value: 'Zayed University' },
  { id: 38, label: 'Afghan University', value: 'Afghan University' },
  { id: 39, label: 'Al-Birony University', value: 'Al-Birony University' },
  { id: 40, label: 'American University of Afghanistan', value: 'American University of Afghanistan' },
  { id: 41, label: 'Aria Institute of Higher Education', value: 'Aria Institute of Higher Education' },
  { id: 42, label: 'Badakhshan University', value: 'Badakhshan University' },
  { id: 43, label: 'Baghlan University', value: 'Baghlan University' },
  { id: 44, label: 'Bakhtar University', value: 'Bakhtar University' },
  { id: 45, label: 'Balkh University', value: 'Balkh University' },
  { id: 46, label: 'Bamiyan University', value: 'Bamiyan University' },
  { id: 47, label: 'Bost University', value: 'Bost University' },
  { id: 48, label: 'Dawat University', value: 'Dawat University' },
  { id: 49, label: 'Dunya Institute of Higher Education', value: 'Dunya Institute of Higher Education' },
  { id: 50, label: 'Faryab Higher Education Institute', value: 'Faryab Higher Education Institute' },
  { id: 51, label: 'Ghazni University', value: 'Ghazni University' },
  { id: 52, label: 'Herat University', value: 'Herat University' },
  { id: 53, label: 'Ibn Sina University', value: 'Ibn Sina University' },
  { id: 54, label: 'Jawzjan University', value: 'Jawzjan University' },
  { id: 55, label: 'Kaboora Institute of Higher Education', value: 'Kaboora Institute of Higher Education' },
  { id: 56, label: 'Kabul Education University', value: 'Kabul Education University' },
  { id: 57, label: 'Kabul Health Sciences Institute', value: 'Kabul Health Sciences Institute' },
  { id: 58, label: 'Kabul Medical University', value: 'Kabul Medical University' },
  { id: 59, label: 'Kabul University', value: 'Kabul University' },
  { id: 60, label: 'Kandahar University', value: 'Kandahar University' },
  { id: 61, label: 'Kardan University', value: 'Kardan University' },
  { id: 62, label: 'Karwan Institute of Higher Education', value: 'Karwan Institute of Higher Education' },
  { id: 63, label: 'Kateb Institute of Higher Education', value: 'Kateb Institute of Higher Education' },
  { id: 64, label: 'Khana-e-Noor Institute of Higher Education', value: 'Khana-e-Noor Institute of Higher Education' },
  { id: 65, label: 'Khurasan University', value: 'Khurasan University' },
  { id: 66, label: 'Maryam Institute of Higher Education', value: 'Maryam Institute of Higher Education' },
  { id: 67, label: 'Nangarhar University', value: 'Nangarhar University' },
  { id: 68, label: 'National Military Academy of Afghanistan', value: 'National Military Academy of Afghanistan' },
  { id: 69, label: 'Paktia University', value: 'Paktia University' },
  { id: 70, label: 'Pamir University', value: 'Pamir University' },
  { id: 71, label: 'Parwan University', value: 'Parwan University' },
  { id: 72, label: 'Polytechnical University of Kabul', value: 'Polytechnical University of Kabul' },
  { id: 73, label: 'Rana Institute of Higher Education', value: 'Rana Institute of Higher Education' },
  { id: 74, label: 'Sadat Institute of Higher Education', value: 'Sadat Institute of Higher Education' },
  { id: 75, label: 'Salam University', value: 'Salam University' },
  { id: 76, label: 'Shaikh Zayed University', value: 'Shaikh Zayed University' },
  { id: 77, label: 'Taj Institute of Higher Education', value: 'Taj Institute of Higher Education' },
  { id: 78, label: 'Takhar University', value: 'Takhar University' },
  { id: 79, label: 'American University of Antigua', value: 'American University of Antigua' },
  { id: 80, label: 'University of Health Sciences Antigua', value: 'University of Health Sciences Antigua' },
  { id: 81, label: 'Academy of Arts', value: 'Academy of Arts' },
  { id: 82, label: 'Academy of Sports and Physical Training', value: 'Academy of Sports and Physical Training' },
  { id: 83, label: 'Agricultural University of Tirane', value: 'Agricultural University of Tirane' },
  { id: 84, label: 'American University of Tirana', value: 'American University of Tirana' },
  { id: 85, label: 'Beder University', value: 'Beder University' },
  { id: 86, label: 'Epoka University', value: 'Epoka University' },
  { id: 87, label: 'Polytechnic University of Tirane', value: 'Polytechnic University of Tirane' },
  { id: 88, label: 'University of Elbasan "Aleksander Xhuvani"', value: 'University of Elbasan "Aleksander Xhuvani"' },
  { id: 89, label: 'University of Gjirokstra "Eqerem Cabej"', value: 'University of Gjirokstra "Eqerem Cabej"' },
  { id: 90, label: 'University of Korca "Fan Noli"', value: 'University of Korca "Fan Noli"' },
  { id: 91, label: 'University of New York Tirana', value: 'University of New York Tirana' },
  { id: 92, label: 'University of Shkodra "Luigj Gurakuqi"', value: 'University of Shkodra "Luigj Gurakuqi"' },
  { id: 93, label: 'University of Tirana', value: 'University of Tirana' },
  { id: 94, label: 'University of Vlora "Ismail Qemali"', value: 'University of Vlora "Ismail Qemali"' },
  { id: 95, label: 'University â€œPavaresiaâ€ Vlore', value: 'University â€œPavaresiaâ€ Vlore' },
  { id: 96, label: 'American University of Armenia', value: 'American University of Armenia' },
  { id: 97, label: 'Armenian State Agrarian University', value: 'Armenian State Agrarian University' },
  { id: 98, label: 'Armenian State University of Economics', value: 'Armenian State University of Economics' },
  { id: 99, label: 'Eurasia International University', value: 'Eurasia International University' },
  { id: 100, label: 'European Regional Educational Academy of Armenia', value: 'European Regional Educational Academy of Armenia' },
];

export const English = [
  { id: 1, label: 'English', value: 'English' },
  { id: 2, label: 'Spanish', value: 'Spanish' },
  { id: 3, label: 'French', value: 'French' },
  { id: 4, label: 'German', value: 'German' },
  { id: 5, label: 'Italian', value: 'Italian' },
  { id: 6, label: 'Portuguese', value: 'Portuguese' },
  { id: 7, label: 'Dutch', value: 'Dutch' },
  { id: 8, label: 'Swedish', value: 'Swedish' },
  { id: 9, label: 'Norwegian', value: 'Norwegian' },
  { id: 10, label: 'Danish', value: 'Danish' },
  { id: 11, label: 'Finnish', value: 'Finnish' },
  { id: 12, label: 'Greek', value: 'Greek' },
  { id: 13, label: 'Russian', value: 'Russian' },
  { id: 14, label: 'Arabic', value: 'Arabic' },
  { id: 15, label: 'Chinese (Simplified)', value: 'ChineseSimplified' },
  { id: 16, label: 'Japanese', value: 'Japanese' },
  { id: 17, label: 'Korean', value: 'Korean' },
  { id: 18, label: 'Hindi', value: 'Hindi' },
  { id: 19, label: 'Urdu', value: 'Urdu' },
  { id: 20, label: 'Persian', value: 'Persian' },
  { id: 21, label: 'Turkish', value: 'Turkish' },
  { id: 22, label: 'Polish', value: 'Polish' },
  { id: 23, label: 'Hungarian', value: 'Hungarian' },
  { id: 24, label: 'Czech', value: 'Czech' },
  { id: 25, label: 'Slovak', value: 'Slovak' },
  { id: 26, label: 'Romanian', value: 'Romanian' },
  { id: 27, label: 'Bulgarian', value: 'Bulgarian' },
  { id: 28, label: 'Croatian', value: 'Croatian' },
  { id: 29, label: 'Serbian', value: 'Serbian' },
  { id: 30, label: 'Slovenian', value: 'Slovenian' },
  { id: 31, label: 'Macedonian', value: 'Macedonian' },
  { id: 32, label: 'Albanian', value: 'Albanian' },
  { id: 33, label: 'Bosnian', value: 'Bosnian' },
  { id: 34, label: 'Montenegrin', value: 'Montenegrin' },
  { id: 35, label: 'Georgian', value: 'Georgian' },
  { id: 36, label: 'Armenian', value: 'Armenian' },
  { id: 37, label: 'Azerbaijani', value: 'Azerbaijani' },
  { id: 38, label: 'Kazakh', value: 'Kazakh' },
  { id: 39, label: 'Uzbek', value: 'Uzbek' },
  { id: 40, label: 'Kyrgyz', value: 'Kyrgyz' },
  { id: 41, label: 'Turkmen', value: 'Turkmen' },
  { id: 42, label: 'Tajik', value: 'Tajik' },
  { id: 43, label: 'Uighur', value: 'Uighur' },
  { id: 44, label: 'Mongolian', value: 'Mongolian' },
  { id: 45, label: 'Tibetan', value: 'Tibetan' },
  { id: 46, label: 'Nepali', value: 'Nepali' },
  { id: 47, label: 'Bengali', value: 'Bengali' },
  { id: 48, label: 'Assamese', value: 'Assamese' },
  { id: 49, label: 'Oriya', value: 'Oriya' },
  { id: 50, label: 'Marathi', value: 'Marathi' },
  { id: 51, label: 'Gujarati', value: 'Gujarati' },
  { id: 52, label: 'Punjabi', value: 'Punjabi' },
  { id: 53, label: 'Sindhi', value: 'Sindhi' },
  { id: 54, label: 'Sinhala', value: 'Sinhala' },
  { id: 55, label: 'Dhivehi', value: 'Dhivehi' },
  { id: 56, label: 'Maldivian', value: 'Maldivian' },
  { id: 57, label: 'Burmese', value: 'Burmese' },
  { id: 58, label: 'Khmer', value: 'Khmer' },
  { id: 59, label: 'Laotian', value: 'Laotian' },
  { id: 60, label: 'Thai', value: 'Thai' },
  { id: 61, label: 'Malay', value: 'Malay' },
  { id: 62, label: 'Indonesian', value: 'Indonesian' },
  { id: 63, label: 'Filipino', value: 'Filipino' },
  { id: 64, label: 'Vietnamese', value: 'Vietnamese' },
  { id: 65, label: 'Burmese', value: 'Burmese' },
  { id: 66, label: 'Mongolian', value: 'Mongolian' },
  { id: 67, label: 'Tibetan', value: 'Tibetan' },
  { id: 68, label: 'Nepali', value: 'Nepali' },
  { id: 69, label: 'Bengali', value: 'Bengali' },
];

export const Qualification = [
  { id: 0, value: '', label: 'Select from List', disabled: true },
  { id: 1, label: 'Matric', value: 'Matric' },
  { id: 2, label: 'Graduate', value: 'Graduate' },
  { id: 3, label: 'Masters', value: 'Masters' },
];
export const Organization = [
  { id: 1, label: 'Microsoft', value: 'Microsoft' },
  { id: 2, label: 'Google', value: 'Google' },
  { id: 3, label: 'Netflix', value: 'Netflix' },
];

export const Proficiency = [
  { id: 0, value: '', label: 'Select test score', disabled: true },
  { id: 1, label: 'Good', value: 'Good' },
  { id: 2, label: 'Average', value: 'Average' },
  { id: 3, label: 'Excellent', value: 'Excellent' },
];

export const options = [
  { id: 1, label: 'Comsats', value: 'Comsats' },
  { id: 2, label: 'Oxford', value: 'Oxford' },
  { id: 3, label: 'Google', value: 'Google' },
  { id: 4, label: 'Windsor', value: 'Windsor' },
  { id: 5, label: 'Torronto', value: 'Torronto' },
];

export const Months = [
  { id: 0, value: '', label: 'Months', disabled: true },
  { id: 1, label: 'January', value: 'Jan' },
  { id: 2, label: 'February', value: 'Feb' },
  { id: 3, label: 'March', value: 'Mar' },
  { id: 4, label: 'April', value: 'Apr' },
  { id: 5, label: 'May', value: 'May' },
  { id: 6, label: 'June', value: 'Jun' },
  { id: 7, label: 'July', value: 'Jul' },
  { id: 8, label: 'August', value: 'Aug' },
  { id: 9, label: 'September', value: 'Sep' },
  { id: 10, label: 'October', value: 'Oct' },
  { id: 11, label: 'November', value: 'Nov' },
  { id: 12, label: 'December', value: 'Dec' },
];

export const Year = [
  { id: 0, value: '', label: 'Year', disabled: true },
  ...Array.from({ length: 11 }, (_, index) => {
    const year = new Date().getFullYear() + index;
    return { id: index + 1, label: year.toString(), value: year.toString() };
  }),
];

export const Budget = [
  { id: 0, value: '', label: 'Select Budget', disabled: true },
  { id: 1, label: '50$ - 100$', value: '50$ - 100$' },
  { id: 2, label: '150$ - 200$', value: '150$ - 200$' },
  { id: 3, label: '250$ - 500$', value: '250$ - 500$' },
];

export const optionsMulti = [
  'University and Program Information',
  'Travel, arrival and settling down',
  'Application process',
  'Student Housing',
  'Enrollment Support',
  'University Life',
  'Scholarships and Tuition Discounts',
  'Internships and Placements',
  'Visa information and guidance',
  'Application handling and submission',
];

export const Level = [
  { id: 0, value: '', label: 'Select Level', disabled: true },
  { id: 1, label: 'Bachelors', value: 'Bachelors' },
  { id: 2, label: 'Masters', value: 'Masters' },
  { id: 3, label: 'Doctorate', value: 'Doctorate' },
];

export const Area = [
  { id: 1, label: 'Data Science', value: 'Data Science' },
  { id: 2, label: 'Geography', value: 'Geography' },
  { id: 3, label: 'D-Pharmacy', value: 'D-Pharmacy' },
];

export const Level1 = [
  { id: 1, label: 'Bachelors', value: 'Bachelors' },
  { id: 2, label: 'Masters', value: 'Masters' },
  { id: 3, label: 'Doctorate', value: 'Doctorate' },
];

export const ContentHome = [
  {
    id: 0,
    label: 'Develop Better Habits and Routines',
    content:
      'The specific benefits of being mentored include: being encouraged and empowered in personal development. being helped to identify and achieve career goals.',
  },
  {
    id: 1,
    label: 'Enhance Communications Skills',
    content:
      'The specific benefits of being mentored include: being encouraged and empowered in personal development. being helped to identify and achieve career goals.',
  },

  {
    id: 2,
    label: 'Achieve Greater Work-life Balance',
    content:
      'The specific benefits of being mentored include: being encouraged and empowered in personal development. being helped to identify and achieve career goals.',
  },
];

export const Mentors = [
  {
    id: 1,
    image: require('../Assets/Images/profile.png'),
    name: 'Tom Walker',
    profession: 'Startup Mentor',
    rating: '4.5',
    reviews: '100',
    price: '200',
  },
  {
    id: 2,
    image: require('../Assets/Images/profile.png'),
    name: 'Tom Walker',
    profession: 'Startup Mentor',
    rating: '4.5',
    reviews: '100',
    price: '200',
  },

  {
    id: 3,
    image: require('../Assets/Images/profile.png'),
    name: 'Tom Walker',
    profession: 'Startup Mentor',
    rating: '4.5',
    reviews: '100',
    price: '200',
  },
  {
    id: 4,
    image: require('../Assets/Images/profile.png'),
    name: 'Tom Walker',
    profession: 'Startup Mentor',
    rating: '4.5',
    reviews: '100',
    price: '200',
  },
  {
    id: 5,
    image: require('../Assets/Images/profile.png'),
    name: 'Walker David',
    profession: 'Startup Mentor',
    rating: '4.5',
    reviews: '100',
    price: '200',
  },
  {
    id: 6,
    image: require('../Assets/Images/profile.png'),
    name: 'Tom ',
    profession: 'Startup Mentor',
    rating: '4.5',
    reviews: '100',
    price: '200',
  },

  {
    id: 7,
    image: require('../Assets/Images/profile.png'),
    name: 'Walker',
    profession: 'Startup Mentor',
    rating: '4.5',
    reviews: '100',
    price: '200',
  },
  {
    id: 8,
    image: require('../Assets/Images/profile.png'),
    name: 'Tom John',
    profession: 'Startup Mentor',
    rating: '4.5',
    reviews: '100',
    price: '200',
  },
];

export const Works = [
  {
    id: 0,
    image: One,
    label: 'Browse Mentors',
    sublabel:
      'Browse our list of mentors using the filters, or submit a help request to have mentors contact you.',
  },
  {
    id: 1,
    image: Two,
    label: 'Send Session Request',
    sublabel:
      'Have you found the ideal startup mentor for you? It’s time to request  a call.',
  },

  {
    id: 2,
    image: Three,
    label: 'Get 1:1 Call',
    sublabel:
      'It’s call time, yay! Make the most of it and make sure you arrive on time!',
  },
];

export const MembersData = [
  {
    id: 0,
    review:
      'Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.',
    name: 'Marcos Rashford',
    passion: 'Devops',
    image: require('../Assets/Images/profile.png'),
  },
  {
    id: 1,
    review:
      'Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.',
    name: 'Rashford',
    passion: 'Dev',
    image: require('../Assets/Images/profile.png'),
  },
  {
    id: 3,
    review:
      'Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.',
    name: 'Marcos',
    passion: 'Software',
    image: require('../Assets/Images/profile.png'),
  },
  {
    id: 4,
    review:
      'Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.',
    name: 'Marcos John',
    passion: 'Web Developer',
    image: require('../Assets/Images/profile.png'),
  },
];

export const AccordionData = [
  {
    id: 0,
    title: 'What is mentorship and how can it benefit me?',
    content:
      'The specific benefits of being mentored include: being encouraged and empowered in personal development. being helped to identify and achieve career goals.',
  },
  {
    id: 1,
    title: 'What is mentorship and how can it benefit me?',
    content:
      'The specific benefits of being mentored include: being encouraged and empowered in personal development. being helped to identify and achieve career goals.',
  },
  {
    id: 2,
    title: 'What is mentorship and how can it benefit me?',
    content:
      'The specific benefits of being mentored include: being encouraged and empowered in personal development. being helped to identify and achieve career goals.',
  },
  {
    id: 3,
    title: 'What is mentorship and how can it benefit me?',
    content:
      'The specific benefits of being mentored include: being encouraged and empowered in personal development. being helped to identify and achieve career goals.',
  },
  {
    id: 4,
    title: 'What is mentorship and how can it benefit me?',
    content:
      'The specific benefits of being mentored include: being encouraged and empowered in personal development. being helped to identify and achieve career goals.',
  },
];

export const AllMentors = [
  {
    id: 0,
    title: 'Tom Walker, Digital Marketer',
    img: profile,
    video: 1,
  },
  {
    id: 1,
    title: 'Tom Walker, Digital Marketer',
    img: profile,
    video: 0,
  },
  {
    id: 2,
    title: 'Tom Walker, Digital Marketer',
    img: profile,
    video: 0,
  },
  {
    id: 3,
    title: 'Tom Walker, Digital Marketer',
    img: profile,
    video: 0,
  },
  {
    id: 4,
    title: 'Tom Walker, Digital Marketer',
    img: profile,
    video: 0,
  },
  {
    id: 5,
    title: 'Tom Walker, Digital Marketer',
    img: profile,
    video: 1,
  },
];

export const AvailableDates = [
  {
    id: 1,
    date: '25 March',
  },
  {
    id: 2,
    date: '27 March',
  },
  {
    id: 3,
    date: '30 March',
  },
];
export const AvailableTime = [
  {
    id: 1,
    time: '11:15 pm',
  },
  {
    id: 2,
    time: '09:55 pm',
  },
  {
    id: 3,
    time: '8:15 pm',
  },
];

export const PaymentData = [
  {
    id: 1,
    title: 'Basic',
    time: 'Monthly',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    price: 10,
    buttonText:'Select Package',
    // features: [
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'no',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'no',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'no',
    //   },
    // ],
  },
  {
    id: 1,
    title: 'Advance',
    time: '6 Months',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    price: 48,
    buttonText:'Select Package',
    // features: [
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'no',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'no',
    //   },
    // ],
  },
  {
    id: 1,
    title: 'Premium',
    time: 'Yearly',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    price: 72,
    buttonText:'Select Package',
    // features: [
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    //   {
    //     name: 'Lorem ipsum',
    //     active: 'yes',
    //   },
    // ],
  },
];

export const userTypes = {
  student: 'Student',
  mentor: 'Mentor',
  admin: 'Admin',
  university: 'University',
};
