import React from 'react'
import "./ActiveMembers.css";
import Ellipse_19 from "../../Assets/Images/Ellipse 19.png";
import Ellipse_20 from "../../Assets/Images/Ellipse 20.png";
import Ellipse_21 from "../../Assets/Images/Ellipse 21.png";


export const ActiveMembers = () => {
    return (
        <>
            <div className="active-members">
                <div className="active-members-list">
                    <img src={Ellipse_19} alt="Ellipse 19" />
                    <img src={Ellipse_20} alt="Ellipse 19" />
                    <img src={Ellipse_21} alt="Ellipse 19" />
                    <div className="bg-red">+10</div>
                </div>
                <p className="active-members-text">14,000 Active members</p>
            </div>

        </>
    )
}


