import { Box } from "@mui/material";
import React from "react";
import Header from "../../components/Header/Header";
import HomeBanner from "../../components/HomeBanner";
import homeBannerImages from "../../Assets/Images/university.png";
import BecomeMentor from "../../components/BecomeAMentor/BecomeAMentor";
import MentorCanHelp from "../../components/MentorCanHelp/MentorCanHelp";
import FAQQuestion from "../../components/FaqSection";
import Footer from "../../components/Footer";
import image from "../../Assets/Images/unipage1.png";
import image1 from "../../Assets/Images/unipage2.png";
import image2 from "../../Assets/Images/unipage3.png";
import SchoolIcon from "@mui/icons-material/School";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PsychologyIcon from "@mui/icons-material/Psychology";
import styled from 'styled-components';

const StyledTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  min-height: 60px;
`;

const StyledContent = styled.p`
  font-size: 18px;
  font-weight: 300;
`;

const University = () => {
  const faqs = [
    {
      title: "What is MentorGrad?",
      description:
        "MentorGrad is a paid membership platform designed to connect aspiring international students with universities worldwide. We specialize in providing universities with high-quality student leads who are ready to apply.",
    },
    {
      title: "How does MentorGrad benefit universities?",
      description:
        "Universities benefit from increased visibility among a global pool of qualified students. We provide detailed profiles of students, including their budget, program preferences, and location interests, helping universities tailor their outreach and recruitment strategies.",
    },
    {
      title: "How are student leads generated on MentorGrad?",
      description:
        "Student leads are generated through our targeted matching process through which we match potential students with universities and study programs. Each student profile is vetted to ensure they meet the application-ready criteria.",
    },
    {
      title: "Can universities access student profiles directly?",
      description:
        "Yes, universities can access student profiles that match their program offerings. These profiles include comprehensive information to help universities understand student needs, preferences, and readiness.",
    },
    {
      title: "How can universities create student advisor profiles?",
      description:
        "Universities can create profiles for their student advisors on our platform. This allows student advisors to directly connect with interested students, provide program information, and guide them through the application process.",
    },
    {
      title:
        "What kind of information can student advisors share with students?",
      description:
        "Advisors can share detailed information about university programs, campus life, scholarship opportunities, and application procedures. They can also answer specific queries from students.",
    },
    {
      title:
        "How can universities make offers of enrolment through MentorGrad?",
      description:
        "After assessing student profiles and interacting through the platform, universities can make provisional offers of enrolment to eligible candidates. This process is streamlined to ensure compliance with admission standards.",
    },
    {
      title:
        "Is there any support provided to universities for profile setup and maintenance?",
      description:
        "MentorGrad offers comprehensive support to universities for setting up and maintaining their profiles. This includes technical support, profile optimization advice, and analytics to track engagement.",
    },
    {
      title: "How does MentorGrad ensure the quality of student leads?",
      description:
        "We use a combination of technology and human oversight to vet student profiles. This includes checking academic credentials, English proficiency, and financial readiness to ensure high-quality leads.",
    },
    {
      title:
        "What are the costs associated with joining the MentorGrad platform?",
      description:
        "The cost structure is tailored to the needs and goals of each university. We offer different membership packages based on the level of access and services required. Universities can contact us directly for a personalized quote.",
    },
  ];

  const BecomeMentorData = [
    {
      title: (
        <StyledTitle as="h3">Highly qualified student leads</StyledTitle>
      ),
      content: (
        <StyledContent as="p">MentorGrad’s unique focus on nurturing and guiding aspiring learners ensures that universities have access to a rich, continuously replenished source of well-prepared and eager international student applicants.</StyledContent>
      ),
      imageSrc: image,
    },
    {
      title: (
        <StyledTitle as="h3">Application-ready profiles</StyledTitle>
      ),
      content:
      (
        <StyledContent as="p">We ensure meticulous information gathering from our member students that lead to high-quality, application-ready profiles. This leads to an enhances conversion to enrolment for our partner universities.</StyledContent>
      ),
      imageSrc: image1,
    },
    {
      title: (
        <StyledTitle as="h3">Direct communication with students</StyledTitle>
      ),
      content:
      (
        <StyledContent as="p">MentorGrad gives you access to our system and tools so that you can communicate to our member students directly through email, chat and video meetings. This provides for transparency and ethical conduct at all stages of the process.</StyledContent>
      ),
      imageSrc: image2,
    },
  ];

  const MentorCanHelpData = [
    {
      heading: "High Quality Leads",
      content:
        "Our student profiles are paid members who are committed to enrolling in an international university program.",
      iconSrc: <SchoolIcon />,
    },
    {
      heading: "Student Advisors",
      content:
        "Create your own student advisors on the platform who can communicate with aspiring students.",
      iconSrc: <BusinessCenterIcon />,
    },
    {
      heading: "Courses",
      content: "Showcase your university and courses to attract students",
      iconSrc: <LibraryBooksIcon />,
    },
    {
      heading: "Scholarships and Discounts",
      content:
        "Offer compelling tuition fees options to attract students to your university.",
      iconSrc: <SchoolIcon />,
    },
    {
      heading: "Intelligent Matching",
      content:
        "We match your university and its programs to our student members for the best outcomes for our student members and university partners.",
      iconSrc: <PsychologyIcon />,
    },
    {
      heading: "Search and access student profiles",
      content:
        "Search for the best candidates for your programs and make direct offers for enrollment",
      iconSrc: <AssignmentIndIcon />,
    },
  ];
  return (
    <Box>
      <Header />
      <HomeBanner
        subtitle="Your Gateway to Global Education"
        title1="Welcome to"
        title2="MentorGrad!"
        detail="Empowering future leaders through guidance and wisdom. Our mentors offer personalized coaching to unlock your potential, navigate challenges, and achieve your aspirations. Join us on a transformative journey towards success and fulfillment."
        HomeBanner={homeBannerImages}
        link="#"
      />
      <BecomeMentor
        title1="Why Choose"
        title2="MentorGrad?"
        BecomeMentorData={BecomeMentorData}
      />
      <MentorCanHelp MentorCanHelpData={MentorCanHelpData} />
      <FAQQuestion FaqQuestion={faqs} />
      <Footer />
    </Box>
  );
};

export default University;
