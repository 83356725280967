import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GetCountryList, findMentors, getMentorForBooking } from "../../../api";
import { notifyError } from "../../Toastifycom";
import picture from "../../../Assets/Images/user.jpeg";
import Spinner from "../../Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jwtDecode } from "../../../helper-functions";
import BookingBanner from "./BookingBanner";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MentorDetail from "./MentorDetail";
import MentorReviews from "./MentorReviews";
import MentorAttributes from "./MentorAttributes";
import SimilarMentor from "./SimilarMentor";
import Header from "../../Header/Header";
import Footer from "../../Footer";
import { format } from "date-fns";
import Container from "../../../common/Input/useStyles";

type Mentor = {
  application: {
    userName: string;
    countryOfResidence: string;
    hourlyRate: number;
    languages: Array<{
      title: string;
      _id: string;
    }>;
    comment: string;
    userId: string;
  };
  next30Days: Array<{
    date: string;
    mentorAvailability: Array<{
      startTime: string;
      endTime: string;
      slotHours: string;
      _id: string;
    }>;
    weekDay: string;
  }>;
  user: Array<{
    attachments: Array<{
      attachmentPath: string;
      attachmentURL: string;
      name: string;
    }>;
  }>;
};

type day = {
  list: {
    date: string;
    mentorAvailability: Array<{
      startTime: string;
      endTime: string;
      slotHours: string;
      _id: string;
    }>;
    weekDay: string;
  };
  index: number;
};

const MentorAppointmentBooking = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const user: string = jwtDecode(localStorage.getItem("@storage_Key"))?.userId;

  const [loading, setLoading] = useState(false);
  const [mentor, setMentor] = useState<Mentor | null>(null);
  const [selectedDay, setSelectedDay] = useState<day | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<any | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  let [searchParams] = useSearchParams();

  useEffect(() => {
    const token = localStorage.getItem("@storage_Key");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      getMentorForBooking({
        mentorId: searchParams.get("id"),
      }).then((response) => {
        setMentor(response);
        setSelectedDay({ list: response?.next30Days[0], index: 0 });
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  };

  const handlePayment = async () => {
    if (selectedSlot) {
      let payload = {
        bookingSubject: mentor?.application?.userName,
        description: "Discuss project progress of I.T",
        bookingDate: new Date(selectedDay?.list.date || ""),
        time: selectedSlot?.startTime + " - " + selectedSlot?.endTime,
        studentId: user,
        mentorId: searchParams.get("id"),
        bookingStatus: "UPCOMING",
        duration: selectedSlot?.slotHours ? calculateDurationInMinutes(selectedSlot?.slotHours) : "",
        amount: mentor?.application?.hourlyRate,
      };
      await localStorage.setItem("booking", JSON.stringify(payload));
      navigate("/checkout");
    } else {
      notifyError("Please select slot to continue.");
    }
  };

  const calculateDurationInMinutes = (slotHours: string): number => {
    const [startTime, endTime] = slotHours.split(" - ");
    const startDate = new Date(`2000-01-01 ${startTime}`);
    const endDate = new Date(`2000-01-01 ${endTime}`);
    const durationMs = endDate.getTime() - startDate.getTime();
    const durationMinutes = durationMs / (1000 * 60);
    return durationMinutes;
  };

  function getDateString(dateString: string, component: string) {
    const date = new Date(dateString);
    switch (component) {
      case "day":
        return format(date, "d");
      case "month":
        return format(date, "MMM");
      case "year":
        return format(date, "y");
      case "dayName":
        return format(date, "E");
      default:
        return null;
    }
  }

  return (
    <>
      <Box sx={{ background: "#fff", flex: 1 }}>
        <Header isWhiteBackground={true} />
        {loading ? (
          <Box sx={{ mt: "140px" }}>
            <Spinner />
          </Box>
        ) : (
          <>
            <Box sx={{ mt: "140px" }}>
              <BookingBanner
                name={mentor?.application?.userName}
                location={mentor?.application?.countryOfResidence}
                languages={mentor?.application?.languages}
                comments={mentor?.application?.comment}
                id={mentor?.application?.userId}
                profileImg={mentor?.user}
                rate={mentor?.application?.hourlyRate}
                handlePayment={handlePayment}
              />
            </Box>
            <Container>
              <Box sx={{ mt: "40px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid #D1D2F4",
                        p: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <CalendarMonthIcon sx={{ color: "#A2D1CA" }} />
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "Gilroy",
                            fontWeight: "bold",
                          }}
                        >
                          Potential Availabilties (In you local time){" "}
                        </Typography>
                      </Box>
                      
                      {/* Time slots for the Selected day */}

                      <Box>
                        <Stack
                          flexDirection={"row"}
                          gap={2}
                          width={isMobile ? 350 : "100%"}
                          sx={{ overflowX: "scroll" }}
                        >
                          {mentor?.next30Days.every(
                            (day) => day.mentorAvailability.length === 0
                          ) ? (
                            <Typography fontSize="large">
                              No slots available
                            </Typography>
                          ) : (
                            mentor?.next30Days
                              .filter(
                                (day) => day.mentorAvailability.length > 0
                              )
                              .map((list, index) => (
                                <Stack
                                  key={index}
                                  flexDirection={"row"}
                                  alignItems={"center"}
                                  sx={
                                    selectedDay?.index !== index
                                      ? {
                                          border: "none",
                                          color: "#888888",
                                          background: "#ECECEC",
                                          borderRadius: "15px",
                                          px: 3,
                                          py: 2,
                                          cursor: "pointer",
                                          mt: "20px",
                                          mb: "10px",
                                        }
                                      : {
                                          borderRadius: "15px",
                                          px: 3,
                                          py: 2,
                                          cursor: "pointer",
                                          background: "#C9F6EF",
                                          mt: "20px",
                                          mb: "10px",
                                        }
                                  }
                                  onClick={() => {
                                    setSelectedDay({ list, index });
                                  }}
                                >
                                  <Typography textAlign={"left"} noWrap>
                                    {getDateString(list?.date, "dayName")}
                                  </Typography>
                                  <Typography textAlign={"left"} noWrap>
                                    {", "}
                                    {getDateString(list?.date, "day")}{" "}
                                    {getDateString(list?.date, "month")}
                                  </Typography>
                                  {/* <Typography
                                  textAlign={"left"}
                                  fontWeight={600}
                                  fontSize={"small"}
                                  noWrap
                                >
                                  {list?.mentorAvailability?.length} slots
                                </Typography> */}
                                </Stack>
                              ))
                          )}
                        </Stack>

                        <Stack
                          flexDirection={"row"}
                          gap={2}
                          sx={
                            selectedDay?.list?.mentorAvailability?.length && selectedDay.list.mentorAvailability.length > 3
                              ? { overflowX: "scroll" }
                              : isMobile
                              ? { overflowX: "scroll" }
                              : {}
                          }
                        >
                          {selectedDay?.list?.mentorAvailability?.map(
                            (slot: any, index: number) => (
                              <Stack
                                sx={
                                  selectedSlot?._id !== slot?._id
                                    ? {
                                        background: "#fff",
                                        color: "#888888",
                                        border: "1px solid #ECECEC",
                                        borderRadius: "15px",
                                        cursor: "pointer",
                                        mb: "10px",
                                        mt: "20px",
                                        px: 3,
                                        py: 2,
                                      }
                                    : {
                                        background: "#C9F6EF",
                                        borderRadius: "15px",
                                        cursor: "pointer",
                                        color: "#000",
                                        mt: "20px",
                                        mb: "10px",
                                        px: 3,
                                        py: 2,
                                      }
                                }
                                width={"fit-content"}
                                onClick={() => {
                                  setSelectedSlot({ ...slot, index });
                                }}
                              >
                                {slot?.startTime && slot?.endTime ? (
                                  <Typography noWrap fontSize={"small"}>
                                    {slot.startTime} - {slot.endTime}
                                  </Typography>
                                ) : (
                                  <Typography noWrap fontSize={"small"}>
                                    No slot available
                                  </Typography>
                                )}
                              </Stack>
                            )
                          )}
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid #DBDBDB",
                        p: "20px",
                        background: "#C9F6EF",
                        height: "fit-content",
                      }}
                    >
                      <MentorAttributes />
                    </Box>
                  </Grid>
                </Grid>
                <MentorDetail name={mentor?.application?.userName} comments={mentor?.application?.comment} />
                <SimilarMentor location={mentor?.application?.countryOfResidence} />
              </Box>              
            </Container>
          </>
        )}
        <Footer />
      </Box>
    </>
  );
};

export default MentorAppointmentBooking;
