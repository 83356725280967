import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn,
} from "mdb-react-ui-kit";
import useMediaQuery from "../hooks/MediaQuery";
import logoWhite from "../Assets/Images/branding/logo_white.png";

const Footer = () => {
  const isMobile = useMediaQuery("(min-width: 450px)");

  const isSubscribeButtonVisible = useMediaQuery("(min-width: 1200px)");

  return (
    <MDBFooter
      style={{
        backgroundColor: "#7476D1",
        paddingTop: "20px",
      }}
      className="text-white text-center text-lg-left"
    >
      {/* <MDBContainer className="py-5" style={{ width: "80%", margin: "auto" }}>
        <MDBRow style={{ marginTop: "2%" }}>
          <MDBCol md="2" lg="4" xl="5" className="mx-auto mb-4">
            <p style={{ color: "#C9F6EF", fontWeight: "500" }}>Newsletter</p>
            <h4 style={{ marginBottom: "20px" }}>Our Newsletter</h4>
            <p>
              Ready to take your skills to the next level? Subscribe to our
              newsletter today and receive exclusive insights, tips, and
              opportunities from our expert mentors. Join our community of
              learners and start your journey towards success now!
            </p>
            <div style={{ position: "relative" }}>
              <input
                type="text"
                style={{
                  width: "100%",
                  borderRadius: "15px",
                  paddingBlock: "15px",
                  paddingInline: "20px",
                  border: "none",
                  marginRight: "-20px",
                }}
                placeholder="Email Address"
              />
              {isSubscribeButtonVisible && (
                <button
                  style={{
                    position: "absolute",
                    top: 5.5,
                    right: -4,
                    height: "80%",
                    padding: "8px",
                    border: "none",
                    borderRadius: "15px",
                    backgroundColor: "#C9F6EF",
                    paddingInline: "20px",
                    fontWeight: "600",
                  }}
                >
                  Subscribe
                </button>
              )}
            </div>

            {!isSubscribeButtonVisible && (
              <button
                style={{
                  marginTop: "20px",
                  padding: "8px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor: "#C9F6EF",
                  paddingInline: "20px",
                  fontWeight: "600",
                  marginRight: "-20px",
                  width: "100%",
                  paddingBlock: "15px",
                }}
              >
                Subscribe
              </button>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer> */}

      <MDBContainer className="p-0" style={{ width: "80%", margin: "auto" }}>
        <MDBRow style={{ marginTop: "2%" }}>
          <MDBCol md="2" lg="4" xl="5" className="mx-auto mb-4">
            <div style={{ width: "250px" }}>
              <img
                src={logoWhite}
                alt="Logo"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </div>
            {/* <h5
              className="text-uppercase"
              style={{ textAlign: 'left', fontWeight: 800, fontSize: 30 }}
            >
              LOGO
            </h5> */}

            <p
              style={{
                textAlign: "left",
                fontWeight: "500",
                fontSize: 14,
                width: isMobile ? "80%" : "100%",
                flexWrap: "wrap",
              }}
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste
              atque ea quis molestias. Fugiat pariatur maxime quis culpa
              corporis vitae repudiandae aliquam voluptatem veniam, est atque
              cumque eum delectus sint!
            </p>
          </MDBCol>

          <MDBCol lg="3" md="6" sx="" className="mb-4 mb-md-0">
            <h5
              style={{
                textDecoration: "none",
                textAlign: "left",
                fontSize: 18,
                fontWeight: "700",
              }}
            >
              Resources & Support
            </h5>

            <ul
              className="list-unstyled"
              style={{ textDecoration: "none", textAlign: "left" }}
            >
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Newsletter
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Case Studies
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Career Paths
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none" }}
                  className="text-white"
                >
                  Contact
                </a>
              </li>
            </ul>
          </MDBCol>
          <MDBCol lg="2" md="6" className="mb-4 mb-md-0">
            <h5
              className=""
              style={{
                textDecoration: "none",
                textAlign: "left",
                fontSize: 18,
                fontWeight: "700",
              }}
            >
              Platform
            </h5>

            <ul
              className="list-unstyled"
              style={{ textDecoration: "none", textAlign: "left" }}
            >
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  Browse Mentors
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  Book a Session
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  Become a Mentor
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  Mentorship for Teams
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  Book a Session
                </a>
              </li>
            </ul>
          </MDBCol>
          <MDBCol lg="2" md="8" className="mb-6 mb-md-0">
            <h5
              className="mb-2"
              style={{
                textDecoration: "none",
                textAlign: "left",
                fontSize: 18,
                fontWeight: "700",
              }}
            >
              Company
            </h5>

            <ul
              className="list-unstyled"
              style={{ textDecoration: "none", textAlign: "left" }}
            >
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  Partner Program
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  Code of Conduct
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  style={{ textDecoration: "none", textAlign: "left" }}
                  className="text-white"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <hr
        style={{
          background: "#EFEFEF",
          color: "#EFEFEF",
          borderColor: "#EFEFEF",
          height: "2px",
        }}
      />
      <div className="text-center p-2" style={{ backgroundColor: "#7476D1" }}>
        {/* &copy; {new Date().getFullYear()} Copyright:{' '} */}
        <a
          style={{ textDecoration: "none" }}
          className="text-white"
          href="https://mentorgrad.com/"
        >
          © 2024 MentorGrad. All Rights Reserved.
        </a>
      </div>
    </MDBFooter>
  );
};
export default Footer;
