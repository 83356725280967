
import styled from 'styled-components';
const MentorMentoorWrapper = styled.section`
padding:40px 0px;
    h2{
        font-family: "Gilroy";
        font-size: 48px;
        color:#222222 
    }
    h3{
        font-family: "Gilroy";
        font-size: 24px;
        color:#222222 
    }
    p{
        font-size: 20px;
        font-family: 'DM Sans', sans-serif;  
        color:#222222
    }
    .inner-box{
        .mentor-image{
            border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        }
       
    }
`;
export default MentorMentoorWrapper;