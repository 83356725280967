import React from "react";
import { ButtonWrapper } from "./style";
import Button from "@mui/material/Button";

const Buttons = (props) => {
  const {
    text,
    icon,
    borderRadius,
    backgroundColor,
    width,
    varient,
    onClick,
    textUnderline,
    className,
    disabled,
    type = "button",
  } = props;

  return (
    <ButtonWrapper
      width={width}
      backgroundColor={backgroundColor}
      textUnderline={textUnderline}
      borderRadius={borderRadius}
    >
      <Button
        size="small"
        type={type}
        className={className}
        variant={varient}
        startIcon={icon}
        onClick={(e, text) => onClick()}
        disabled={disabled}
        sx={{
          "&:hover": {
            backgroundColor: backgroundColor,
          },
        }}
      >
        {text}
      </Button>
    </ButtonWrapper>
  );
};

export default Buttons;
