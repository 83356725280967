import React from "react";
import BirminghamUniversity from "../../Assets/Images/birminghamuniversity.png";
import CharlesSturtUniversity from "../../Assets/Images/charles-sturt-university.svg";
import Group157 from "../../Assets/Images/Group 157.svg";
import Group158 from "../../Assets/Images/Group 158.svg";
import PrimaryLogo from "../../Assets/Images/PrimaryLogotype-1024x219 1.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import ResponsiveSwiper from "../ResponsiveSlider/ResponsiveSwiper";

const UniversitiesLogo = () => {
  return (
    <ResponsiveSwiper slides={[
      <img
        src={PrimaryLogo}
        alt="university"
      />,
      <img
        src={BirminghamUniversity}
        alt="university"
      />,
      <img
        src={Group158}
        alt="university"
      />,
      <img
        src={Group157}
        alt="university"
      />,
      <img
        src={CharlesSturtUniversity}
        alt="university"
      />,
    ]} />
  );
};

export default UniversitiesLogo;
