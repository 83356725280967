import React from "react";
import Container from "../../common/Input/useStyles";
import { Box, Button, Grid } from "@mui/material";
import TransparentBlueBackground from "../../Assets/Images/transparent-blue-bg.png";
import Image6 from "../../Assets/Images/image 6.png";
import Image7 from "../../Assets/Images/image 7.png";
import Image8 from "../../Assets/Images/image 8.png";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundImage: `url(${TransparentBlueBackground})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        py: 5,
      }}
    >
      <Container>
        <Box>
          <Box
            sx={{
              width: "100%",
              m: "0 auto",
              textAlign: "left",
              color: "white",
            }}
          >
            <div style={{ width: "min(800px, 100%)" }}>
              <h2 style={{ marginBlock: "20px" }}>
                At your fingertips: a dedicated career coach.
              </h2>
              <p style={{ marginBottom: "60px" }}>
                Want to ace your next job interview? Successfully build your
                startup? Itching to learn high-demand skills? Work smart with an
                online mentor or coach by your side to offer expert advice and
                guidance to match your zeal. Become unstoppable using
                MentorCruise.
              </p>
            </div>
            <Grid container sx={{ marginBottom: "30px" }} spacing={4}>
              <Grid item xs={12} lg={3}>
                <img
                  src={Image6}
                  alt="no-mentor"
                  style={{ marginBottom: "15px" }}
                />
                <h3>Find Your Mentor</h3>
                <p>
                  Explore our growing catalogue of experienced mentors until you
                  find the perfect fit.
                </p>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#C9F6EF",
                    color: "black",
                    borderRadius: "15px",
                    px: "30px",
                    py: "12px",

                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#C9F6EF",
                    },
                    width: 200,
                  }}
                  onClick={() => {
                    navigate("/mentor/signup");
                  }}
                >
                  Become a Mentor
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <img
                  src={Image7}
                  alt="no-mentor"
                  style={{ marginBottom: "15px" }}
                />
                <h3>Student</h3>
                <p>
                  Fill in an application, and if you’re a match with your chosen
                  mentor you can start your free 7-day trial.
                </p>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#C9F6EF",
                    color: "black",
                    borderRadius: "15px",
                    px: "30px",
                    py: "12px",

                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#C9F6EF",
                    },
                    width: 200,
                  }}
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Start your Journey
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <img
                  src={Image8}
                  alt="no-mentor"
                  style={{ marginBottom: "15px" }}
                />
                <h3>School</h3>
                <p>
                  From personal chats to hands-on support, each mentor offers
                  different services to help you skyrocket your career.
                </p>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#C9F6EF",
                    color: "black",
                    borderRadius: "15px",
                    px: "30px",
                    py: "12px",

                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#C9F6EF",
                    },
                    width: 200,
                  }}
                  onClick={() => {
                    "";
                  }}
                >
                  Coming Soon
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default Index;
