import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import Container from "../../common/Input/useStyles";
import "./Testimonials.css";
import Ellipse from "../../Assets/Images/Ellipse 24.png";
import Ellipse1 from "../../Assets/Images/Ellipse 24 (1).png";
import Ellipse2 from "../../Assets/Images/Ellipse 24 (2).png";
import styled from "styled-components";
import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ResponsiveSwiper from "../ResponsiveSlider/ResponsiveSwiper";

const testimonialData = [
  {
    heading: "Always up to date",
    content:
      "Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.",
    author: "John Doe",
    role: "Graphic Designer",
    imageSrc: Ellipse,
  },
  {
    heading: "Always up to date",
    content:
      "Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.",
    author: "John Doe",
    role: "Graphic Designer",
    imageSrc: Ellipse1,
  },
  {
    heading: "Always up to date",
    content:
      "Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.",
    author: "John Doe",
    role: "Graphic Designer",
    imageSrc: Ellipse2,
  },
  {
    heading: "Always up to date",
    content:
      "Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.",
    author: "John Doe",
    role: "Graphic Designer",
    imageSrc: Ellipse,
  },
  {
    heading: "Always up to date",
    content:
      "Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.",
    author: "John Doe",
    role: "Graphic Designer",
    imageSrc: Ellipse1,
  },
  {
    heading: "Always up to date",
    content:
      "Thanks to my mentor, I was able to land my dream job! Their guidance and support throughout the application process were invaluable. I highly recommend this mentorship program.",
    author: "John Doe",
    role: "Graphic Designer",
    imageSrc: Ellipse2,
  },
];

const Testimonials = () => {
  const navigate = useNavigate();
  return (
    <TestimonialsSection>
      <Container>
        <div className="Testimonial_top">
          <div className="main_heading">
            <h2>What do members say about us!</h2>
          </div>
          <Button
            variant="contained"
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "DM Sans",
              backgroundColor: "#C9F6EF",
              color: "black",
              borderRadius: "15px",
              px: "40px",
              py: "10px",
              fontWeight: "500",
              textTransform: "capitalize",
              fontSize: "20px",
            }}
            onClick={() => {
              navigate("/findMentor");
            }}
          >
            Find Mentor
          </Button>
        </div>
      </Container>
      <ResponsiveSwiper swiperProps={{
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        }
      }} className="left-padding testimonials-swiper" slides={testimonialData.map((testimonial, index) => (
        <div className="Testimonial_bg" key={index}>
          <div className="Testimonial">
            <div className="Testimonial_heading">
              <h2>{testimonial.heading}</h2>
            </div>
            <div className="Testimonial_content">
              <p>{testimonial.content}</p>
            </div>
            <div className="Testimonial_bottom">
              <img src={testimonial.imageSrc} alt="" />
              <div>
                <h4>{testimonial.author}</h4>
                <p>{testimonial.role}</p>
              </div>
            </div>
          </div>
        </div>
      ))
      } />
      <Box sx={{ display: { md: "none" }, mt: "30px", px: "20px" }}>
        <Button
          variant="contained"
          fullWidth
          sx={{
            fontFamily: "DM Sans",
            backgroundColor: "#C9F6EF",
            color: "black",
            borderRadius: "15px",
            px: "30px",
            py: "15px",
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
          onClick={() => {
            navigate("/findMentor");
          }}
        >
          Find Mentor
        </Button>
      </Box>
    </TestimonialsSection>
  );
};

export default Testimonials;

const TestimonialsSection = styled.section`
  .Testimonial_bottom {
    display: flex;
    margin-top: 55px;
    gap: 20px;
  }
  .Testimonial_bottom img {
    width: 60px;
    height: 60px;
  }

  .Testimonial_bottom p, .Testimonial_bottom h4 {
    margin-bottom: 0px;
  }
  @media (max-width: 428px) {
    .find_mentor_btn {
      display: none;
    }
  }
`;
